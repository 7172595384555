import { Dialog, Popover, Transition } from '@headlessui/react';
import { ArrowRightCircleIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
import React, { Fragment, useEffect, useState } from 'react';
const FrameComponent7 = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const solutions = [
    { name: 'Email list', image: '/Mail.svg', description: 'Build, manage, and engage with your audience through targeted email campaigns to grow your subscriber base and drive conversions.' },
    { name: 'Storefront', image: '/Grocery.svg', description: 'Create a professional online store with customizable designs, secure payment options, and inventory management to enhance customer experience and boost sales.' },
    { name: 'Link-in-bio', image: '/link-04.svg', description: 'Consolidate all your important links into a single, clickable landing page to maximize your social media presence and drive audience engagement.' },
    { name: 'Analytics', image: '/data-usage2.svg', description: 'Gain comprehensive insights into your online performance with real-time data and intuitive dashboards to optimize your strategies and drive growth.' }
  ]

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Product' },
    { name: 'FAQ', href: '/faq' },
    { name: 'About', href: '/about' },
    { name: 'Pricing', href: '/pricing' }
  ]
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const company = [
    { name: 'About', description: 'Discover CreatorzHub: Empowering creators worldwide. Unleash your creativity today!', image: '/Questionmark.svg', href: '/about'},
    { name: 'Help & Support', description: "Need help? We've got you covered. Reach out for support anytime!" , image: '/Exclamation.svg', href: '/faq'}
  ]
  const handleButtonClick = () => {
    // Navigate to Gmail with a pre-filled email address
    const emailAddress = 'creatorzhubofficial@gmail.com'; // Change this to your desired email address
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(emailAddress)}`;
    window.location.href = gmailUrl;
  };
  return (
    <div className="fixed top-0 w-full flex bg-[#fff] z-10 flex-col">
      {showBanner === true && (
     <div class={`w-full relative hidden md:flex h-10 flex items-center justify-center text-[#fff] font-["Semi"] text-sm bg-slate-800`}>
     Welcome to Creatorzhub's beta product! ✨🎊

       <XMarkIcon onClick={() => setShowBanner(false)} class='text-[#fff] absolute right-3 w-5 h-5'/>

</div>
      )}
 
      <div className="2xl:hidden xl:hidden bg-[#fff] self-stretch flex flex-col">
        <header className="flex flex-row self-stretch py-3 2xl:px-10 px-6 md:px-7 xl:px-10 lg:px-7 xs:px-10 sm:px-10 ">
          <div className="w-full flex flex-row items-center justify-between gap-[20px]">
            <div className="flex w-full items-center gap-10">
              <a href='/' class='no-underline'>
                <img
                  className="relative h-8 overflow-hidden mt-1 shrink-0"
                  loading="lazy"
                  alt=""
                  src="/union5.svg"
                />
              </a>
              <nav className="m-0 hidden md:hidden lg:flex flex flex-col items-start justify-start px-0 pb-0 box-border max-w-full mq1125:hidden">
                <nav className="m-0 self-stretch text-sm flex flex-row items-start justify-between lg:gap-8 text-left text-base text-black-900 font-body-m-regular mq450:hidden">
                  <div className="flex flex-col items-start justify-start px-0 pb-0">
                    <a href='/' className="relative no-underline text-black font-['Bold'] inline-block">
                      Home
                    </a>
                  </div>
                  <Popover className="flex flex-row space-x-2">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`${open ? 'text-black' : 'text-black'} flex items-center bg-white outline-0 border-0`}
                        >
                          <div className="relative text-sm font-bold inline-block">
                            Product
                          </div>
                          <ChevronDownIcon
                            className={`${open ? 'text-black' : 'text-black'} ml-2 h-4 w-4 transition duration-150 ease-in-out`}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >

                          <Popover.Panel className="absolute z-10 mt-10  bg-white w- shadow rounded-xl ring-1 ring-black/5">
                            <div class='px-6 pb-2 pt-5'>
                              <div class='font-["Bold"] text-2xl border-0'>Products on Creatorzhub </div>
                            </div>
                            <div class='flex flex-row gap-10 p-8 px-10'>


                              <div className="gap-10 flex flex-col max-w-sm">

                                {solutions.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 flex rounded-lg items-start justify-start p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                  >
                                    <div className="flex h-12 bg-royalblue w-12 rounded-full shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                      <img src={item.image} class='w-6 h-6' aria-hidden="true" />
                                    </div>
                                    <div className="ml-4 flex flex-col gap-2">
                                      <div className="text-[14px] font-['Bold'] text-gray-900">
                                        {item.name}
                                      </div>
                                      <div className="text-sm font-['Normal'] text-gray-400">
                                        {item.description}
                                      </div>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div class='mx-auto max-w-sm'>
                                <div class='bg-royalblue p-5 self-stretch rounded-md'>
                                  <img src='/Head.svg' class='h-8' />
                                  <div class='my-2 mb-4'>
                                    <div class='text-2xl font-["Promo"] text-[#fff]'>Get more with pro, upgrade today</div>
                                  </div>
                                  <button class='px-5 py-3 bg-[#fff] text-royalblue font-["Bold"] rounded-full border-0'>Get pro</button>
                                </div>
                                <div class='py-4'>
                                  <div class='font-["Semi"] text-lg'>Ready to get started?</div>
                                  <div class='flex flex-col gap-2 mt-2'>
                                  <div onClick={openModal} class='py-3 cursor-pointer font-["Face"] border-b-[1px] border-gainsboro border-solid flex items-center gap-1 border-0'><PlayCircleIcon class='w-5 h-5' /> View trailer</div>
                                    <a href='/register' class='py-3 no-underline text-black font-["Face"] border-b-[1px] flex items-center gap-1 border-gainsboro border-solid border-0'><ArrowRightCircleIcon class='w-5 h-5' /> Get started here</a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>


                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <video class='w-full rounded-2xl' autoPlay >
                          <source src="/CH2.mp4" class='self-stretch' type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

                  <div className="flex flex-col items-start justify-start  px-0 pb-0">
                    <a href='/faq' className="relative no-underline text-black font-['Bold'] inline-block">
                      FAQ
                    </a>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-2 px-0 pb-0">
                    <div className="relative font-['Bold'] inline-block">
                      Company
                    </div>
                    <ChevronDownIcon class='w-4 h-4' />
                  </div>
                  <div className="flex flex-col items-start justify-start  px-0 pb-0">
                    <a href='/pricing' className="relative no-underline text-black font-['Bold'] inline-block">
                      Pricing
                    </a>
                  </div>
                </nav>
              </nav>
            </div>
            <div className="flex flex-row items-center gap-6">
              <div className="flex ">
                <a href='/login' className="relative  hidden md:flex sm:flex no-underline text-black font-['Bold'] text-sm inline-block min-w-[43px]">
                  Login
                </a>
              </div>

              <a href='/register' className="cursor-pointer [border:none] px-5 py-3 no-underline whitespace-nowrap rounded-full text-sm text-[#fff] font-['Semi'] bg-royalblue">
                Get started

              </a>
              <button onClick={() => setMobileMenuOpen(true)} class='border-[1px] border-solid w-9 h-9 items-center justify-center flex rounded-md bg-white border-gray-100'>
                <img src='/more.svg' />
              </button>
            </div>
          </div>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-2/3 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="/union5.svg"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 border-0 bg-[#fff] text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-300/50 divide-solid divide-x-0">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 no-underline block rounded-lg px-3 py-2 text-base font-['Semi'] text-sm leading-7 text-black hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>

                  <div className="py-6 gap-2 flex flex-col">
                    <a
                      href="/affiliate"
                      className="-mx-3 block rounded-full text-sm inline-block px-2.5 no-underline text-[#000] py-2.5 text-base font-['Semi'] leading-7"
                    >
                      Become an affiliate
                    </a>
                    <a
                      href="/login"
                      className="-mx-3 block text-sm inline-block px-2.5 no-underline text-[#000] py-2.5 text-base font-['Semi'] leading-7"
                    >
                      Login
                    </a>
                    <div>
                      <a
                        href="/register"
                        className="-mx-3 block rounded-full inline-block text-sm px-10 bg-royalblue no-underline text-[#fff] py-2.5 text-base font-['Semi'] leading-7"
                      >
                        Get started for free
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div class='bg-[#fff] bg-opacity-85 backdrop-blur-lg  w-full 2xl:flex xl:flex hidden'>
        <header className="w-[1650px] mx-auto flex flex-row px-10 py-3">

          <div className="w-full flex flex-row items-center justify-between gap-[20px] max-w-full">
            <div className="flex items-center gap-10">
              <a href='/' class='no-underline'>
                <img
                  className="relative h-8 overflow-hidden mt-1 shrink-0"
                  loading="lazy"
                  alt=""
                  src="/union5.svg"
                />
              </a>

              <nav className="m-0 flex-1 flex flex-col items-start justify-start px-0 pb-0 box-border max-w-full mq1125:hidden">
                <nav className="m-0 self-stretch text-sm flex flex-row items-start justify-between gap-8 text-left text-base text-black-900 font-body-m-regular mq450:hidden">
                  <div className="flex flex-col items-start justify-start px-0 pb-0">
                    <a href='/' className="relative no-underline text-black font-['Bold'] inline-block">
                      Home
                    </a>
                  </div>
                  <Popover className="flex flex-row space-x-2">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`${open ? 'text-black' : 'text-black'} flex items-center bg-white outline-0 border-0`}
                        >
                          <div className="relative text-sm font-bold inline-block">
                            Product
                          </div>
                          <ChevronDownIcon
                            className={`${open ? 'text-black' : 'text-black'} ml-2 h-4 w-4 transition duration-150 ease-in-out`}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >

                          <Popover.Panel className="absolute z-10 mt-10  bg-white w- shadow rounded-xl ring-1 ring-black/5">
                            <div class='px-6 pb-2 pt-5'>
                              <div class='font-["Bold"] text-2xl border-0'>Products on Creatorzhub </div>
                            </div>
                            <div class='flex flex-row gap-10 p-8 px-10'>


                              <div className="gap-10 flex flex-col max-w-sm">

                                {solutions.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 flex rounded-lg items-start justify-start p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                  >
                                    <div className="flex h-12 bg-royalblue w-12 rounded-full shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                      <img src={item.image} class='w-6 h-6' aria-hidden="true" />
                                    </div>
                                    <div className="ml-4 flex flex-col gap-2">
                                      <div className="text-[14px] font-['Bold'] text-gray-900">
                                        {item.name}
                                      </div>
                                      <div className="text-sm font-['Normal'] text-gray-400">
                                        {item.description}
                                      </div>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div class='mx-auto max-w-sm'>
                                <div class='bg-royalblue p-5 self-stretch rounded-md'>
                                  <img src='/Head.svg' class='h-8' />
                                  <div class='my-2 mb-4'>
                                    <div class='text-2xl font-["Promo"] text-[#fff]'>Get more with pro, upgrade today</div>
                                  </div>
                                  <button class='px-5 py-3 bg-[#fff] text-royalblue font-["Bold"] rounded-full border-0'>Get pro</button>
                                </div>
                                <div class='py-4'>
                                  <div class='font-["Semi"] text-lg'>Ready to get started?</div>
                                  <div class='flex flex-col gap-2 mt-2'>
                                    <div onClick={openModal} class='py-3 cursor-pointer font-["Face"] border-b-[1px] border-gainsboro border-solid flex items-center gap-1 border-0'><PlayCircleIcon class='w-5 h-5' /> View trailer</div>
                                    <a href='/register' class='py-3 no-underline text-black font-["Face"] border-b-[1px] flex items-center gap-1 border-gainsboro border-solid border-0'><ArrowRightCircleIcon class='w-5 h-5' /> Get started here</a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>

                        </Transition>
                      </>
                    )}
                  </Popover>


                  <div className="flex flex-col items-start justify-start  px-0 pb-0">
                    <a href='/faq' className="relative no-underline text-black font-['Bold'] inline-block">
                      FAQ
                    </a>
                  </div>
                  <Popover className="flex flex-row space-x-2">
                    {({ open }) => (
                      <>

                        <Popover.Button
                          className={`${open ? 'text-black' : 'text-black'} flex items-center bg-white outline-0 border-0`}
                        >
                          <div className="relative text-sm font-bold inline-block">
                            Company
                          </div>
                          <ChevronDownIcon
                            className={`${open ? 'text-black' : 'text-black'} ml-2 h-4 w-4 transition duration-150 ease-in-out`}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >

                          <Popover.Panel className="absolute z-10 mt-10  bg-white w- shadow rounded-xl ring-1 ring-black/5">
                            <div class='px-6 pb-2 pt-5'>
                              <div class='font-["Bold"] text-2xl border-0'>The Company Creatorzhub </div>
                            </div>
                            <div class='flex flex-row gap-10 p-8 px-10'>


                              <div className="gap-10 flex flex-col max-w-sm">

                                {company.map((item) => (
                                  <a
                                  
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 flex no-underline rounded-lg items-start justify-start p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                  >
                                    <div className="flex h-12 bg-royalblue w-12 rounded-full shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                      <img src={item.image} class='w-6 h-6' aria-hidden="true" />
                                    </div>
                                    <div className="ml-4 flex flex-col gap-2">
                                      <div className="text-[14px] font-['Bold'] text-gray-900">
                                        {item.name}
                                      </div>
                                      <div className="text-sm font-['Normal'] text-gray-400">
                                        {item.description}
                                      </div>
                                    </div>
                                  </a>
                                ))}
                              </div>
                            
                            </div>
                          </Popover.Panel>

                        </Transition>
                      </>
                    )}
                  </Popover>

                  <div className="flex flex-col items-start justify-start  px-0 pb-0">
                    <a href='/pricing' className="relative no-underline text-black font-['Bold'] inline-block">
                      Pricing
                    </a>
                  </div>
                </nav>
              </nav>

            </div>
            <div className="flex flex-row items-center gap-8">
              <a href='/login' className="flex no-underline text-black flex-col items-start justify-start px-0 pb-0">
                <div className="relative font-['Bold'] text-sm inline-block min-w-[43px]">
                  Login
                </div>
              </a>
              <div className="flex flex-col items-start justify-start px-0 pb-0">
                <a href='/affiliate' className="relative no-underline text-black font-['Bold'] text-sm inline-block min-w-[43px]">
                  Become an affiliate
                </a>
              </div>
              <a href='/register' className="cursor-pointer no-underline [border:none] px-5 py-3 rounded-full text-sm text-[#fff] font-['Semi'] bg-royalblue">
                Get started for free

              </a>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default FrameComponent7;
