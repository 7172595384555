import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ME_QUERY } from '../Data/Me';
import { useQuery } from '@apollo/client';
import user from '../Assets/user.png'
const SimpleChart = ({ data }) => {
  const { data: mData, loading: mLoading, error: mError } = useQuery(ME_QUERY)

  const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

  const totalPay = mData?.me?.Payouts ? sumPropertyValue(mData.me.Payouts, 'amount') : 0;
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'simple-chart',
      toolbar: {
        show: false,
      },
    },
    colors: ['#0560FD'], // Set your desired color here
  
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.8,
        opacityTo: 0.2,
        stops: [0, 100],
      },
    },
    grid: {
      show: false
    },
    stroke: {
      width: 3,
   
    },
    yaxis: {
      labels: {
        show: false,
      },
      tickAmount: 5
    },
   
    xaxis: {
      show: false, // Remove the x-axis
      labels: {
        show: false, // Set to false to hide X-axis labels
      },
      colors: ['#fff']
    },
    dataLabels: {
      enabled: false, // Set to false to hide data labels
    },
  });
  
  

  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Total Payments per Day',
      data: Object.values(data),
    },
  ]);

  useEffect(() => {
    setChartSeries([
      {
        name: 'Total Payments per Day',
        data: Object.values(data),
      },
    ]);
  }, [data]);

  return (

  
        <Chart options={chartOptions} series={chartSeries} type="area" height={200} />
 

  );
};

export default SimpleChart;
