import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Formik, Form } from 'formik'
import { ME_QUERY } from '../../Data/Me'
import { useNavigate } from 'react-router-dom'
import Loading from '../Loading'
const Addition = gql`
   mutation updateProfile($more: String, $website: String){
              updateProfile(more: $more, website: $website){
                id
              }
   }`
const Setup3 = () => {
    const navigate = useNavigate()
    const { data, error, loading} = useQuery(ME_QUERY)
    const [profile] = useMutation(Addition)
    const [website, setWebsite] = useState("")

    if (error) return <div>{error.message}</div>
    if(loading) return <Loading />
    return (
        <div>
            <div class='p-5'>

                <div class='items-center shadow-part border-black py-2 pr-4 pl-2 rounded-full border-2 hidden sm:inline-flex 2xl:inline-flex mr-2'>
                    <img src={require('../../Assets/C.png')} class='w-7 h-7' />
                    <div style={{ fontFamily: 'Bold' }} class='text-sm'>CreatorzShop</div>
                </div>
                <div class='px-10'>
                    <div class='flex flex-col max-w-2xl flex mx-auto px-10'>

                        <Formik
                            initialValues={{}}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true)
                                await profile({
                                    variables: { website: website },
                                })
                                navigate("/dash")
                                setSubmitting(false)

                            }}
                        >
                            <Form>

                                <div class='mx-auto py-10 mt-20 max-w-lg  '>

                                <div class='flex items-center justify-center flex-col'>
                            <div class='border-b-2 border-black py-1 text-2xl text-center mb-5 inline-flex justify-center items-center font-["Face"]'>Setup</div>
                            <div style={{ fontFamily: 'Bold' }} class='text-4xl text-center'>How are you planning to use CreatorzShop?</div>
                        </div>
                                    <div class='w-full grid grid-cols-2 gap-8 mt-7'>
                                        <div class='flex items-center w-full flex-col justify-center'>
                                            <div onClick={() => setWebsite('creatorz.link')} class='border-2 justify-center w-full flex items-center font-["Bold"] border-black shadow-part p-5 rounded-xl'>
                                                <div>Links</div>
                                            </div>
                                            <div class='font-["Bold"] mt-3'>Creatorz.link</div>

                                        </div>
                                        <div class='flex items-center w-full flex-col justify-center'>
                                            <div onClick={() => setWebsite('creatorz.shop')} class='border-2 justify-center w-full flex items-center font-["Bold"] border-black shadow-part p-5 rounded-xl'>
                                                <div>Shop</div>
                                            </div>
                                            <div class='font-["Bold"] mt-3'>Creatorz.shop</div>

                                        </div>
                                    </div>
                                    <div class={`text-center ${website === "" ? 'hidden' : 'flex justify-center items-center'} border-2 border-black shadow-part rounded-full py-3 max-w-xs mx-auto font-["Bold"] mt-8`}>{website}/{data.me.name}</div>
                                    <button
                                        style={{ fontFamily: 'Bold' }}
                                        type="submit"

                                        className="fixed bottom-5 right-5 gap-4 mt-5  justify-center rounded-full shadow-part  px-8 py-5 leading-6 border-2 border-black hover:bg-black hover:text-white hover:border-2"
                                    >
                                        Next
                                        <span aria-hidden="true">&rarr;</span>
                                    </button>
                                </div>
                            </Form>
                        </Formik>

                    </div>
                </div>
                
            </div>
            
          
        </div>
    )
}

export default Setup3