import React, { useEffect, useState } from 'react'
import { Banner, Bottom, FrameComponent, Head, Logout, User } from './DataProcessor';
import Add from './Add';
import ProductInfo from "../Components/ProductInfo";
import { Items } from './DataProcessor';
import SimpleChart from '../Components/Ana';
import { FaDonate, FaEllipsisH, FaEye, FaEyeSlash, FaMousePointer, FaRegHandPointer, FaRegHeart, FaShoppingBag, FaTag } from 'react-icons/fa';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline'
import { ME_QUERY } from '../Data/Me';
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@apollo/client'
import ProductRevenueChart from '../Components/ProductsChart';
import ProductRevenueChart2 from '../Components/ProductsChart2';
import { UisEllipsisH } from '\'';
import { BsTag } from 'react-icons/bs';


const Products = ({ allData }) => {
    const [selectedPost, setSelectedPost] = useState(null);

    const { error, loading, data } = useQuery(ME_QUERY)
    const openDialog = (item) => {
        setSelectedPost(item);

    };
    const [totalAmount, setTotalAmount] = useState(0);
    const [clicksAmount, setClickAmount] = useState(0);

    const [archiveCount, setArchiveCount] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    useEffect(() => {
        if (data) {
            const total = data.me.Payouts.reduce((acc, payout) => {
                return acc + payout.amount;
            }, 0);
            setTotalAmount(total);

            const clicks = data.me.Payouts.reduce((acc, payout) => {
                return acc + payout.Product.points;
            }, 0);
            setClickAmount(clicks);

            const archived = data.me.posts2.filter(post => post?.setDeleted === true);
            setArchiveCount(archived.length);

            const active = data.me.posts2.filter(post => post?.setDeleted === false);
            setActiveCount(active.length);
        }
    }, [data]);
    function getTimeAgoString(createdAt) {
        const currentDate = new Date();
        const createdAtDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdAtDate.getTime();

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (seconds < 60) {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        } else if (minutes < 60) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (hours < 24) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (days < 30) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (months < 12) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else {
            return `${years} year${years !== 1 ? 's' : ''} ago`;
        }
    }
    const totalAmount2 = data.me.Payouts.reduce((acc, payout) => acc + payout.amount, 0);

    const productRevenue = data.me.Payouts.reduce((acc, payout) => {
        const product = payout.Product.title;
        const amount = payout.amount;
        acc[product] = (acc[product] || 0) + amount;
        return acc;
    }, {});

    const productPercentage = Object.keys(productRevenue).reduce((acc, product) => {
        acc[product] = (productRevenue[product] / totalAmount2) * 100;
        return acc;
    }, {});
    const closeDialog = () => {
        setSelectedPost(null);
    };

    if (loading) return <div>loading...</div>
    if (error) return <div>{error.message}</div>

    return (
        <div>
            <Banner />
            <div className="w-full fixed top-10 rounded-xl bg-main-color-white flex flex-row items-start justify-start leading-[normal] tracking-[normal] mq1050:pl-5 mq1050:pr-5 mq1050:box-border">
                <div class='fixed bottom-5 right-5 flex items-center justify-center rounded-full h-14 w-14 bg-royalblue'>
                    <img src='/Comment.svg' class='w-7 h-7' />
                </div>
                <div className="relative w-[280px] border-r-[1px] border-0 border-solid border-gainsboro bg-main-color-white box-border flex flex-col items-start justify-between overflow-y-auto min-h-screen">
                    <Head />
                    <div className="self-stretch flex flex-col pt-6 items-start justify-start px-[23px] gap-[15px] shrink-0 [debug_commit:f6aba90] border-t-[1px] border-solid border-whitesmoke-200 mq750:pt-5 mq750:pb-[183px] mq1050:pt-[21px] mq1050:pb-[281px] border-0 mq450:gap-[18px] overflow-y-auto flex-grow">
                        <User data={allData} />
                        <Items isProducts={true} data={allData} />
                    </div>
                    <div className="self-stretch flex-shrink-0">
                        <Bottom allData={allData} />
                    </div>
                </div>

                <main className="flex-1 flex flex-col w-full h-screen items-start justify-start gap-[27px] max-w-[calc(100%_-_280px)] mq1050:max-w-full">
                    <FrameComponent data={allData} />
                    <section className="self-stretch flex flex-row items-start justify-start py-0 px-8  overflow-y-scroll  h-full box-border g-full max-w-full text-left text-11xl text-gray1-200 font-body-medium-medium">
                        <div className="w-full flex flex-col items-start justify-start gap-[24px] max-w-full">
                            <div className="w-full flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                                        <div className="flex flex-row w-full justify-between">
                                            <div>
                                                <h1 className="m-0 relative text-inherit leading-[48px] font-['Bold'] mq1050:text-5xl mq1050:leading-[38px] mq450:text-lg mq450:leading-[29px]">
                                                    Products
                                                </h1>
                                                <div className="relative text-sm mt-1 font-['Face'] text-darkgray-300">
                                                    Welcome back, username
                                                </div>
                                            </div>
                                            <div>
                                                <div className="h-10 w-10 rounded-[48px] bg-main-color-white box-border flex flex-row items-center justify-center py-2 px-1.5 border-[2px] border-dashed border-gainsboro">
                                                    <img
                                                        className="h-6 w-6 relative overflow-hidden shrink-0"
                                                        alt=""
                                                        src="/icon-3.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='mt-[24px] gap-10 self-stretch w-full flex flex-col'>
                                    <div class='w-full flex flex-col'>
                                        <div class='grid grid-cols-4 h-20 gap-5 mb-5'>
                                            <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                                <div class='flex items-center justify-between w-full'>
                                                    <div class='flex flex-row space-x-4'>
                                                        <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                            <FaShoppingBag class='text-[#fff] w-4 h-4' />
                                                        </div>
                                                        <div>
                                                            <div class='text-sm text-[#000] font-["Semi"]'>Products</div>
                                                            <div class='text-xs line-clamp-2 font-["Face"] text-gray-400 mt-1'>Your total of products</div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                            <ChevronRightIcon class='text-black w-4 h-4' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                                <div class='flex justify-between items-center w-full space-x-4'>
                                                    <div class='flex flex-row space-x-4'>
                                                        <div class='bg-royalblue shrink-0 w-9 h-9 rounded-full flex items-center justify-center'>
                                                            <FaEye class='text-[#fff] w-5 h-5' />
                                                        </div>
                                                        <div>
                                                            <div class='text-sm text-[#000] font-["Semi"]'>Active</div>
                                                            <div class='text-xs line-clamp-2 font-["Face"] text-gray-400 mt-1'>All current active products</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                            <ChevronRightIcon class='text-black w-4 h-4' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='border-gainsboro rounded-xl p-5 border-[1px] border-solid'>
                                                <div class='flex justify-between items-center w-full space-x-4'>

                                                    <div class='flex flex-row space-x-4'>
                                                        <div class='bg-royalblue shrink-0 w-9 h-9 rounded-full flex items-center justify-center'>
                                                            <FaEyeSlash class='text-[#fff] w-5 h-5' />
                                                        </div>
                                                        <div>
                                                            <div class='text-sm text-[#000] font-["Semi"]'>Inactive</div>
                                                            <div class='text-xs line-clamp-2 font-["Face"] text-gray-400 mt-1'>All current inactive products</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                            <ChevronRightIcon class='text-black w-4 h-4' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='border-gainsboro rounded-xl p-5 border-[1px] border-solid'>
                                                <div class='flex justify-between items-center w-full space-x-4'>

                                                    <div class='flex flex-row space-x-4'>
                                                        <div class='bg-royalblue shrink-0 w-9 h-9 rounded-full flex items-center justify-center'>
                                                            <FaMousePointer class='text-white w-5 h-5' />
                                                        </div>
                                                        <div>
                                                            <div class='text-sm text-[#000] font-["Semi"]'>Clicks</div>
                                                            <div class='text-xs line-clamp-2 font-["Face"] text-gray-400 mt-1'>Amount of clicks on products</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                            <ChevronRightIcon class='text-black w-4 h-4' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='w-full flex h-full 2xl:mt-0 md:mt-3 space-x-5'>
                                            <div class='h-full 2xl:grid 2xl:grid-cols-2 gap-5 w-3/4'>
                                                <div class='border-[1px] border-solid h-full lg:w-full rounded-xl border-gainsboro flex flex-col'>
                                                    <div class='flex flex-col items-start px-5 pt-3'>
                                                        <div class='w-full flex items-center justify-between'>
                                                            <div>
                                                                <div class='text-base text-black font-["Semi"]'>Total made</div>
                                                            </div>
                                                            <div>
                                                                <div class='border-solid text-sm px-2 py-1 font-["Semi"] flex items-center rounded-lg border-gainsboro border-[1px]'>
                                                                    ${totalAmount} made in revenue
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class='px-1 flex-grow'>
                                                        <ProductRevenueChart />
                                                    </div>
                                                </div>
                                                <div class='border-[1px] border-solid lg:hidden 2xl:flex h-full rounded-xl border-gainsboro flex flex-col'>
                                                    <div class='flex flex-col items-start px-5 pt-3'>

                                                        <div class='w-full flex items-center justify-between'>
                                                            <div>
                                                                <div class='text-base text-black font-["Semi"]'>Total clicks</div>
                                                            </div>
                                                            <div>
                                                                <div class='border-solid text-sm px-2 py-1 font-["Semi"] flex items-center rounded-lg border-gainsboro border-[1px]'>
                                                                    {clicksAmount} clicks
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class='px-1 flex-grow'>
                                                        <ProductRevenueChart2 />
                                                    </div>

                                                </div>

                                            </div>
                                            <div class='w-1/4 border-solid border-gainsboro rounded-xl border-[1px] flex'>
                                                <div class='p-3 px-5 w-full'>
                                                    <div class='text-black text-base font-["Semi"]'>Top products</div>
                                                    {data.me.topProducts.length === 0 ? (
                                                        <div class='flex items-center justify-center mt-24 w-full'>
                                                            <Add />
                                                        </div>
                                                    ) : (
                                                        <div class='mt-4 flex flex-col gap-2'>
                                                            {data.me.topProducts.map(item => (
                                                                <div class='flex items-center justify-between w-full space-x-2'>
                                                                    <div class='flex items-center space-x-2'>
                                                                        {item.thumbnail === null ? (
                                                                            <div class='w-10 h-10 rounded-full flex items-center justify-center bg-royalblue'>
                                                                                <FaShoppingBag class='w-4 h-4 text-[#fff]' />
                                                                            </div>
                                                                        ) : (
                                                                            <img src={item.thumbnail} class='h-10 w-10 rounded-full' />
                                                                        )}

                                                                        <div>
                                                                            <div class='text-black font-["Semi"] text-xs'>{item.title}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {item.setDeleted === true ? (
                                                                            <div class='flex items-center gap-1 text-xs font-["Semi"]'><div class='bg-orange-400 w-1 h-1 rounded-full' /> Inactive</div>
                                                                        ) : (
                                                                            <div class='flex items-center gap-1 text-xs font-["Semi"]'><div class='bg-green-400 w-1 h-1 rounded-full' /> Active</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {data.me.topProducts.length < 5 && (
                                                                <div class='flex items-center justify-center w-full'>
                                                                    <Add />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>

                                        <div class='flex flex-col text-[15px] rounded-xl'>
                                            <div class='text-[#000] font-["Semi"] text-sm rounded-[10px] justify-between flex items-center px-4 my-5'>
                                                <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Product</div>
                                                <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Status</div>
                                                <div class='text-center w-full md:hidden 2xl:flex flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Category</div>
                                                <div class='text-center w-full flex md:hidden 2xl:flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Clicks</div>
                                                <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Price</div>
                                                <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Sales</div>

                                                <div class='text-center w-full'>More</div>
                                            </div>
                                            {allData.posts.length === 0 ? (
                                                <div class='border-gainsboro rounded-xl border-[1px] h-full p-14 border-solid flex items-center justify-center'>
                                                    <Add />
                                                </div>
                                            ) : (

                                                <div class='border-gainsboro rounded-xl border-[1px] border-solid'>
                                                    {allData.posts.map(item => {
                                                        const productTitle = item.title;

                                                        const percentage = productPercentage[productTitle] || 0;

                                                        return (
                                                            <div class={`border-solid border-gainsboro ${allData?.posts?.length === 1 ? '' : 'border-b-[1px]'} border-[0px]`}>
                                                                <div className="flex flex-row p-4 items-center justify-between gap-[20px]">
                                                                    <div className="w-full flex flex-row items-center justify-start py-0 px-0 box-border gap-[16px]">
                                                                        {!item.thumbnail || item.thumbnail === "" ? (
                                                                            <div class='relative'>
                                                                                <div
                                                                                    className="h-10 w-10 rounded-lg relative overflow-hidden flex items-center justify-center bg-royalblue shrink-0"
                                                                                >
                                                                                    <FaShoppingBag className='text-[#fff] h-5 w-5' />
                                                                                </div>

                                                                            </div>
                                                                        ) : (
                                                                            <div class='relative'>
                                                                                <img
                                                                                    className="h-10 w-10 rounded-lg relative overflow-hidden shrink-0"
                                                                                    loading="lazy"
                                                                                    alt=""
                                                                                    src={item.thumbnail}
                                                                                />

                                                                            </div>
                                                                        )}
                                                                        <div className="flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px]">
                                                                            <div className="relative text-[#000] text-sm font-['Semi']  whitespace-nowrap">
                                                                                {item.title}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class='w-full justify-center items-center flex'>
                                                                        {item.setDeleted === true ? (
                                                                            <div class='flex items-center gap-2 text-[13px] font-["Semi"]'><div class='bg-orange-400 w-1.5 h-1.5 rounded-full' /> Inactive</div>
                                                                        ) : (
                                                                            <div class='flex items-center gap-2 text-[13px] font-["Semi"]'><div class='bg-green-400 w-1.5 h-1.5 rounded-full' /> Active</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex flex-col md:hidden 2xl:flex flex items-center justify-center w-full font-['Semi'] text-gray-400 text-[13px]">
                                                                        {item.isDonation === true && 'Donation'}
                                                                        {item.isEbook === true && 'Donation'}
                                                                        {item.isDigitalDownload === true && 'Donation'}
                                                                        {item.isAudio === true && 'Donation'}
                                                                        {item.isCourse === true && 'Donation'}
                                                                        {item.isPhysical === true && (item.isClothing === true ? 'Clothing' : 'Merchandise')}

                                                                    </div>
                                                                    <div class='flex w-full  md:hidden 2xl:flex items-center justify-center gap-2 flex-row'>
                                                                        <FaRegHandPointer class='text-gray-300' />
                                                                        <div className='text-sm text-[#000] font-["Semi"]'>
                                                                            {item.points}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-full flex flex-col text-sm font-['Semi'] items-center justify-center pt-0 px-0 pb-px box-border text-right text-lg">
                                                                        {item.isDonation === true ? (
                                                                            <div class='flex items-center gap-2'>
                                                                                <FaRegHeart class='text-gray-300' />
                                                                                Donation
                                                                            </div>
                                                                        ) : (
                                                                            <div class='flex items-center gap-2'>
                                                                                <BsTag class='text-gray-300' />
                                                                                ${item.price}.00
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div class='w-full flex items-center space-x-3'>
                                                                        <div class='text-xs text-black font-["Semi"]'>
                                                                            {percentage.toFixed(0)}%
                                                                        </div>
                                                                        <div className="w-full bg-gray-100 h-1 overflow-hidden rounded-full relative">
                                                                            <div
                                                                                className="bg-royalblue h-1"
                                                                                style={{ width: `${percentage}%` }}
                                                                            ></div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="cursor-pointer w-full flex items-center justify-center inline-block">

                                                                        <div onClick={() => openDialog(item)} className="relative text-xs font-['Bold'] text-[#000] py-2.5 px-[19px] border-solid border-[1px] border-gainsboro  rounded-full flex flex-row items-center justify-center gap-[12px]">
                                                                            Edit
                                                                        </div>
                                                                        <ProductInfo item={selectedPost} isOpen={!!selectedPost} onClose={closeDialog} />

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        )
                                                    })}
                                                </div>
                                            )}

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>

    )
}

export default Products