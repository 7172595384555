import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup"
import { BuildingStorefrontIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { LinkIcon } from '@heroicons/react/20/solid';
import { Popover, Tab, Transition } from '@headlessui/react';
import { Feedback } from '../../Routes/Landing';

const Register = gql`
mutation signup($email: String!, $password: String!, $name: String!, $stripe_id: String!, $stripe_email: String, $stripe_name: String) {
		signup(email: $email, password: $password, name: $name, stripe_id: $stripe_id, stripe_email: $stripe_email, stripe_name: $stripe_name ) {
			token
		}
	}
    `

const Signin = gql`
mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			token
		}
	}
    `

const Signup = () => {
    const navigate = useNavigate();
    const [register, { error }] = useMutation(Register);
    const Auth = [
        { name: 'Sign up' },
        { name: 'Sign in' },

    ]
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [login] = useMutation(Signin)
    const handleSignIn = async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
            const response = await login({
                variables: values,
            });

            const token = response.data.login.token;
            await localStorage.setItem("token", token);

            // Check verification status


            // Redirect to the unverified page
            navigate('/verification');

        } catch (error) {
            // Handle authentication failure (e.g., show an error message)
            console.error("Authentication failed", error);
        } finally {
            setSubmitting(false);
        }
    };
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email Required"),
        password: Yup.string().max(20, "Must be 20 characters or less").required("Password Required"),
        confirmPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
        name: Yup.string().max(15, "Must be 15 characters or less").required("Name Required")
    })
    return (
        <>
        <div class='w-full flex items-center bg-royalblue h-screen'>
        <div class='fixed top-0 w-full'>
                         <a href='/' class='self-stretch flex items-center justify-between 2xl:px-10 xs:m-5 m-5 2xl:m-0 2xl:py-5'>
                                <img src='/head.svg' class='h-9'/>
                         </a>
                  </div>
                  <Feedback blue={false} />

       
                <div class='xs:max-w-md m-5 p-10 xs:mx-auto bg-[#fff] shadow-lg border-[1px] border-gainsboro rounded-xl border-solid'>

                        <div className='flex items-center justify-center flex-col gap-5'>
                            <div class='flex flex-col gap-2 xs:mb-7 mb-0'>
                                <div className='xl:text-4xl lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl text-2xl font-["Bold"] text-center'>Join Creatorzhub!</div>
                                <div class='font-["Face"] text-center text-gray-200'>Welcome back, get started by entering details</div>
                            </div>
                       
                        </div>
                       
                

                            <Formik
                                initialValues={{
                                    name: '',
                                    email: '',
                                    password: '',
                                    stripe_id: '',
                                    stripe_email: '',
                                    stripe_name: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    try {
                                        setSubmitting(true);

                                        // Perform the registration mutation
                                        const response = await register({
                                            variables: values,
                                        });

                                        // Handle the successful registration
                                        await localStorage.setItem('token', response.data.signup.token);
                                        setSubmitting(false);
                                        navigate('/setup');
                                    } catch (error) {
                                        // Handle the error in your UI
                                        console.error('Registration Error:', error);
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                <Form>
                                    <div class='flex flex-col w-full gap-3 mt-3'>
                                        <div class='flex flex-col'>
                                        <label style={{ fontFamily: 'Semi' }} htmlFor="password" className="ml-2 mb-3 leading-6 text-black">
                                                Username
                                            </label>
                                      
                                                <Field
                                                    style={{ fontFamily: 'Normal' }}

                                                    name="name"
                                                    type="name"

                                                    required
                                                    className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                />
                                      
                                        </div>

                                        <div class='flex flex-col'>
                                        <label style={{ fontFamily: 'Semi' }} htmlFor="password" className="ml-2 mb-3 leading-6 text-black">
                                                    Email
                                                </label>

                                       
                                           
                                                <Field
                                                    style={{ fontFamily: 'Normal' }}

                                                    name="email"

                                                    required
                                                    className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                />
                                         
                                        </div>
                                    </div>
                                    <div class='flex-col flex mt-3 mb-3'>
                                    <label style={{ fontFamily: 'Semi' }} htmlFor="password" className="ml-2 mb-3 leading-6 text-black">
                                            Password
                                        </label>
                                            <Field
                                                style={{ fontFamily: 'Normal' }}

                                                name="password"
                                                type="password"

                                                required
                                                className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                />
                              
                                    </div>

                                    <div class='flex-col flex'>
                                    <label style={{ fontFamily: 'Semi' }} htmlFor="password" className="ml-2 mb-3 leading-6 text-black">
                                                Confirm Password
                                            </label>

                                
                               
                                            <Field
                                                style={{ fontFamily: 'Normal' }}

                                                name="confirmPassword"
                                                type="password"

                                                required
                                                className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                />
                                 
                                        <ErrorMessage name="confirmPassword" component={"div"} />
                                        {error && <div style={{ fontFamily: 'Face' }} className="bg-red-500 text-white p-3 mt-3 rounded-xl">
                                            {error.message}
                                        </div>}

                                    </div>

                            
                                        <button type='submit' className='w-full text-center mt-5 block py-5 outline-0 border-0 rounded-full bg-royalblue text-white' style={{ fontFamily: 'Bold' }}>Create Account <span aria-hidden="true">&rarr;</span></button>


                               
                                </Form>
                            </Formik>
                                    <div className='font-["Face"] text-center mt-4 text-sm'>Already have an account? <a href='/login' className='text-royalblue font-["Semi"] no-underline'>Login</a></div>


                     </div>

        </div>





    </>
    )
}

export default Signup