import React from 'react'
import { SparklesIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import FrameComponent7 from './Landing/FrameComponent7'
import { Tab } from '@headlessui/react'
import { Feedback } from './Landing'
const plans = [
    {
        name: 'Creatorzhub Lite',
        iconColor: 'text-red-500',
        bgColor: 'bg-red-200',
        description:
            'Perfect for creators or brands starting out and looking for a compact yet powerful selling platform.',
        icon: "l",
        price: 'Free',
        features: ['Sell merch, ebooks, downloads, etc..', 'Keep all your links together', 'Customizable storefront', 'Email list creation', 'Donations']
    },
    {
        name: 'Creatorzhub Plus',
        iconColor: 'text-purple-500',
        bgColor: 'bg-purple-200',
        description:
            'Tailored for influencers or brands with a burgeoning following seeking advanced features and solutions.',
        icon: "ll",
        price: 6,
        features: ['Everyting in free', '0 transaction fees', 'No CreatorzShop watermark', 'Embedded links', 'Partial template access']
    },
    {
        name: 'Creatorzhub Elite',
        iconColor: 'text-yellow-500',
        bgColor: 'bg-yellow-200',
        description:
            'Designed for top-tier influencers or renowned brands looking for effortless product selling solutions.',
        icon: "lll",
        price: 15,
        features: ['Everyting in plus', 'Custom checkout', 'Access to all Templates', 'Email subs']
    },

]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const List = [
    'Monthly',
    '6 Months',
    'Yearly'
]
const Onlinemarketplace = () => {
    return (
        <div>
            <Feedback />
            <FrameComponent7 />
            <div class='2xl:mx-auto mx-7 2xl:max-w-screen-2xl 2xl:py-32 xl:py-28 lg:py-36 py-36'>
                <div class='2xl:mx-10 xl:mx-7'>
                    <div class='flex-col flex items-center justify-center'>
                        <div class='mb-4 rounded-full bg-royalblue inline-flex gap-2 px-4 py-2'>
                            <SparklesIcon class='w-4 text-white' />
                            <div class='text-white font-["Face"]'>Pay for single features</div>
                        </div>
                        <div className="font-['Bold'] text-center mt-4 text-black 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-2xl text-2xl">
                            A single tool for creators and brands. <br class='hidden 2xl:flex xl:flex md:flex'/>
                            To elevate there digital presence.

                        </div>
                        <div class='text-gray-400 font-["Face"] my-2'>
                            Providing affordable solutions for creators
                        </div>
                        <div class='flex items-center justify-center w-full flex-row gap-5 mt-4'>
                                <div class='flex flex-row items-center gap-2'>
                                <div class='w-6 h-6 rounded-full bg-royalblue flex items-center justify-center'>
                                          <CheckIcon class='w-3 h-3 text-[#fff]'/>
                                         
                                    </div>
                                    <div class='font-["Semi"] text-sm'>Effectient</div>

                                </div>
                                <div class='flex flex-row items-center gap-2'>
                                <div class='w-6 h-6 rounded-full bg-royalblue flex items-center justify-center'>
                                          <CheckIcon class='w-3 h-3 text-[#fff]'/>
                                         
                                    </div>
                                    <div class='font-["Semi"] text-sm'>Versatile</div>

                                </div>
                                <div class='flex items-center gap-2'>
                                    <div class='w-6 h-6 rounded-full bg-royalblue flex items-center justify-center'>
                                          <CheckIcon class='w-3 h-3 text-[#fff]'/>
                                         
                                    </div>
                                    <div class='font-["Semi"] text-sm'>Affrodable</div>
                                </div>
                        </div>
                    </div>
                    <div class='mt-10 '>
                        <Tab.Group>
                            <div class='flex justify-center items-center'>
                            <Tab.List class='items-center bg-royalblue rounded-full inline-flex  px-2 space-x-3 py-2 flex-nowrap '>
                                {List.map(item => (
                                    <Tab className={({ selected }) =>
                                        classNames(
                                            'p-3 px-4 outline-0 text-[#fff]  rounded-full border-0',
                                            selected
                                                ? 'text-royalblue bg-[#fff] border-0'
                                                : 'bg-royalblue'
                                        )
                                    } style={{ fontFamily: 'Semi' }}>
                                        {item}
                                    </Tab>
                                ))}
                            </Tab.List>
                            </div>
                            <Tab.Panel>
                                <div class='grid 2xl:grid-cols-3 xl:grid-cols-3 w-full md:grid-cols-2 lg:grid-cols-3 2xl:mx-[0px] mt-5 2xl:gap-5 gap-10'>
                                    {plans.map(item => (
                                        <div class={`p-10 flex items-center justify-center flex-col border-gainsboro border-[1px] border-solid rounded-xl`}>

                                            <div class='text-xl font-["Bold"]'>{item.name}</div>
                                            <div class='font-["Normal"] text-center text-sm mt-2'>{item.description}</div>
                                            <div class='mt-3 text-3xl font-["Bold"]'>{item.price === 'Free' ? 'Free' : `$${item.price}`}</div>
                                            <a href='/register' class='w-full flex no-underline justify-center items-center py-3 my-3 rounded-lg bg-royalblue text-white font-["Semi"]'>Subscribe</a>
                                            <div class='space-y-3 mt-3'>
                                                {item.features.map(item => (
                                                    <div class='flex items-center text-sm font-["Face"] gap-3'><CheckIcon class='w-4 text-black h-4' />{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                                <div class='grid 2xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 2xl:mx-[0px] mt-5 2xl:gap-5 gap-10'>
                                    {plans.map(item => (
                                        <div class={`p-10 flex items-center justify-center flex-col border-gainsboro border-[1px] border-solid rounded-xl`}>

                                            <div class='text-xl font-["Bold"]'>{item.name}</div>
                                            <div class='font-["Normal"] text-center text-sm mt-2'>{item.description}</div>
                                            <div class='mt-3 text-3xl font-["Bold"]'>{item.price === 'Free' ? 'Free' : `$${item.price * 6 - 10}`}</div>
                                            <a href='/register' class='w-full flex no-underline justify-center items-center py-3 my-3 rounded-lg bg-royalblue text-white font-["Semi"]'>Subscribe</a>
                                            <div class='space-y-3 mt-3'>
                                                {item.features.map(item => (
                                                    <div class='flex items-center text-sm font-["Face"] gap-3'><CheckIcon class='w-4 text-black h-4' />{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Panel>

                           <Tab.Panel>
                                <div class='grid 2xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 2xl:mx-[0px] mt-5 2xl:gap-5 gap-10'>
                                    {plans.map(item => (
                                        <div class={`p-10 flex items-center justify-center flex-col border-gainsboro border-[1px] border-solid rounded-xl`}>

                                            <div class='text-xl font-["Bold"]'>{item.name}</div>
                                            <div class='font-["Normal"] text-center text-sm mt-2'>{item.description}</div>
                                            <div class='mt-3 text-3xl font-["Bold"]'>{item.price === 'Free' ? 'Free' : `$${item.price * 12 - 20}`}</div>
                                            <a href='/register' class='w-full flex no-underline justify-center items-center py-3 my-3 rounded-lg bg-royalblue text-white font-["Semi"]'>Subscribe</a>
                                            <div class='space-y-3 mt-3'>
                                                {item.features.map(item => (
                                                    <div class='flex items-center text-sm font-["Face"] gap-3'><CheckIcon class='w-4 text-black h-4' />{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Panel>
                        </Tab.Group>

                        <div class='w-full py-4 border-b-[1px] border-0 border-solid border-gainsboro'>

                        </div>

                        <div class='mt-4 self-stretch flex sm:flex-row flex-col sm:items-center bg-royalblue sm:p-10 sm:py-14 p-8 rounded-xl'>
                            <div class='sm:w-1/2 w-3/4 flex flex-col gap-3'>
                                <div class='text-3xl font-["Bold"] text-[#fff]'>Want to pay for single features?</div>
                                <div class='text-[#fff] font-["Face"]'>Get started with custom pricing today, and only pay for what you need.</div>
                            </div>
                            <div class='sm:w-1/2 mt-5 sm:mt-0 inline-flex sm:justify-end sm:items-end'>
                                     <div class='sm:w-auto px-5 py-4 bg-[#fff] font-["Semi"] text-sm rounded-full text-royalblue'>
                                        Get started
                                     </div>
                            </div>
                        </div>
                        <div class='py-10 text-center text-gray-500 font-["Face"] text-sm'>
                               Choose the right plan for you. Get started today. Sign up <a href='/register' class='text-royalblue no-underline font-["Semi"]'>here</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onlinemarketplace