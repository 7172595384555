import { gql } from '@apollo/client'
export const ME_QUERY = gql`
query me{
  me{
    id
    name
    verified
    points
    createdAt
    type
    secondary
    avatar
    website
    subscription
    audience{
      id 
      email
      throughEmail
    }
    featuredPost{
      id 
      post{
      title 
      thumbnail 
      description 
      isDonation 
      }

    }
    onePost{
      id
      thumbnail 
      title
      description
      price 
      author{
        id 
        avatar
      }
    }
    anaLinks{
      id 
      link
      linkText
      image
      clicks
    }
    Links{
      id 
      link
      linkText
      image
      clicks
    }
    Storefront{
      id 
      subdomain
      backgroundColor
      newsletterImage
      productsSection
    newsletterSection
    description
    componentColor
    headingColor
    subTextColor
    embeddedLink
    featuredSection
    socialsSection
    actionButton
    actionButtonText
    layoutVertical
    newsletterImage
    instagramShown
    tiktokShown
    facebookShown
    twitterShown
    instagram
    facebook
    tiktok
    twitter
    subscribeText
    subscribeSubText
    headerImage
    secondaryImage
    subText
    basic
    simple
    image
    template
    log
    log2
    user{
      id
      avatar
      Links{
        id 
        linkText 
        link 
        image
      }
      featuredPost{
        id 
        post{
        thumbnail 
        description 
        price
        author{
          id 
          avatar 
          name
        }
        }
      }
      storefrontPosts{
        id 
        title 
        price 
        description 
        thumbnail     
        author{
          id
          avatar
        }    
      }
    }
    }
    topProducts{
      id
      title
      thumbnail
      price
      createdAt
      description
      isEbook
      isDigitalDownload
      isClothing 
      isPhysical
      points
      isAudio
      setDeleted
      isCourse
    }
    anaProducts{
      id
      title
      thumbnail
      price
      createdAt
      description
      isEbook
      isDigitalDownload
      isClothing 
      isPhysical
      points
      isAudio
      isCourse
      
    }
    posts2{
      id
      setDeleted
    }
    recentActivity{
      type
      title
      image 
      email
      amount
    }
    userTags{
      id
      tag
      suggested{
        id 
        author{
          id 
          name
          avatar
        }
        thumbnail
        isEbook
        isDigitalDownload
        isClothing
        isMerchandise
        isCourse
        isAudio
      }
    }
  
    email
    Kyro
    bio
    Payouts{
      id
      amount
      createdAt
      name
      email
      fulfilled
      User{
        id 
        name 
        email
      }
      Shipping{
          id 
          address
          state
          country
          city
          zipCode
         }
      Product{
         id
         thumbnail
         description
         isDonation
         setDeleted
         price
         title
         points
         isPhysical
      }
      Seller{
        id
        name
      }
    }
    tags{
      id
      tag
      suggested{
        id
        thumbnail
        isPhysical
        isClothing
        isMerchandise
        isCourse
        isAudio
        isDigitalDownload
        isEbook
        author{
          id
          name
          avatar
        }
      }
    }
    Website{
      id
      backgroundColor
    headerText
    headingColor
    heroImage
    font
    heroSection
    featuredSection
    socialsSection
    newsletterSection
    productsSection
    linkSection
    headerImage
    mediaColor
    textColor
    background
    isBackground
    iconColor
    heroImageText
    outline
    heroImageButton
    bannerText
    text1Color
    instagram
    facebook 
    tiktok
    twitter
    text2Color
    text3Color
    aboutColorHeading
    borderRadius
    aboutColorDescription
    heroImageText                   
  newsletter              
  newsletterText         
  newsletterButton        
  newsletterDescription   
  youtubeName             
  twitterName             
  facebookName            
  tiktokName               
    authorColorDescription
    aboutColorName
    featuredColorHeading
    featuredColorDescription
    productsColorHeading
    productsColorDescription
    heading1
    text1
    text2
    text3
    backdrop
    backdropColor
    backdropAvatar
    aboutHeading
    aboutDescription
    aboutName
    authorDescription
    instagramShown
    facebookShown
    twitterShown
    tiktokShown
    featuredName
    featuredDescription
    productsName
    productsDescription

    }
    Payment{
      id
      amount
      Product{
        id
        thumbnail
        description
        title
      }
    }
    userLiked{
      id
      PicPost{
        id
      }
    }
    DashPayouts{
      id
      amount
      createdAt
      name
      email
      fulfilled
      User{
        id 
        name 
        email
      }
      Shipping{
          id 
          address
          state
          country
          city
          zipCode
         }
      Product{
         id
         thumbnail
         description
         isDonation
         price
         title
         isPhysical
        
      }
      Seller{
        id
        name
      }
    }
    storefrontPosts{
      id
      title
      thumbnail
      price
      author{
        id 
        avatar
      }
      createdAt
      description
      isEbook
      isDonation
      isDigitalDownload
      isClothing 
      isPhysical
      points
      isAudio
      isCourse
    }
    posts{
      id
      title
      thumbnail
      price
      author{
        id 
        avatar
      }
      createdAt
      description
      isEbook
      isDonation
      isDigitalDownload
      isClothing 
      isPhysical
      points
      isAudio
      isCourse
    }
    followers{
      id
      name
      avatar
      bio
      type
      email
    }
    following{
      id
      name
      type 
      avatar
      verified
      posts{
      id
      title
      author{
        name
        id
        avatar
      }
      thumbnail
      price
      createdAt
      description
      isPhysical
      isMerchandise
      isClothing
      isEbook
      isDigitalDownload
      isAudio
      isCourse
    }
   
    }

  
  }
}`