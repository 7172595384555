import './App.css';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "apollo-link-context"
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Analytics } from '@vercel/analytics/react';
import { AppRouter } from './AppRouter';

const httpLink = new HttpLink({ uri: "https://serverkyro.herokuapp.com/graphql" })
const authLink = setContext(async (req, { headers }) => {
  const token = localStorage.getItem("token")

  return {
    ...headers,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    }
  }
})


const PUBLIC_KEY = "pk_live_51IiQZPD7NsuORBccKCxY8rBHOrERJ6HlXpOozicuK57nKXrLVP9ljqsk1ijaOF39ylNvaw2mX09JsVzwnbp7JN7600p7dQ6ZCl"

const stripeTestPromise = loadStripe(PUBLIC_KEY)


const link = authLink.concat(httpLink)

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
})



function MainApp() {
  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripeTestPromise}>
        <Router>
          <AppRouter />
          <Analytics />

        </Router>
      </Elements>
    </ApolloProvider>
  );
}

export default MainApp;
