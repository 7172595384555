import React from 'react'
import Products from './Products'
import { useQuery } from '@apollo/client'
import { ME_QUERY } from '../Data/Me'

const Productpage = () => {

    const { data, error, loading } = useQuery(ME_QUERY)

    if(error) return <div>{error.message}</div>
    if(loading) return <div>Loading</div>
  return (
    <Products allData={data.me}/>
  )
}

export default Productpage