import React, { useEffect, useState } from 'react'
import { ME_QUERY } from '../Data/Me'
import { gql, useMutation, useQuery } from '@apollo/client'
import { BoltIcon, ChartBarIcon, CheckIcon, HomeIcon, ShoppingCartIcon, TagIcon } from '@heroicons/react/20/solid'
import logo from '../Assets/C.png'
import { All } from './Search'
import Fulfillment from '../Templates/Fulfillment'
import EditStore from '../Templates/EditTemplate'
import { BanknotesIcon, LinkIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import { Dialog, Tab, Transition } from '@headlessui/react'
import { useFormik } from 'formik'
import LinkUpdate from '../Components/LinkUpdate'
import SimpleChart from '../Components/Ana'
import ProductInfo from '../Components/ProductInfo'
import Add from './Add'
import Loading from '../Components/Loading'
import Edit from '../Templates/Template1'
import DataProcessor from './DataProcessor'


export const CREATE_PRODUCT_MUTATION = gql`
mutation CreateProduct($productData: ProductCreateInput!) {
  CreateProduct(productData: $productData) {
 id
  }
}
`;

export const CREATE = gql`
  mutation createPost(
    $title: String
    $description: String
    $link: String
    $thumbnail: String
    $file: String
    $price: Int
    $filename: String
    $isEbook: Boolean!
    $isDigitalDownload: Boolean!
    $isDonation: Boolean!
    $isAudio: Boolean!
    $isPhysical: Boolean!
    $isClothing: Boolean!
    $isMerchandise: Boolean!
    $isCourse: Boolean!
    $youtubeWebinar: Boolean!
    $zoomWebinar: Boolean!
    $twitchWebinar: Boolean!
    $isWebinar: Boolean!
    $isMembership: Boolean!
    $tagIds: [Int]!
  ) {
    createPost(
      title: $title
      description: $description
      isDonation: $isDonation
      isPhysical: $isPhysical
      thumbnail: $thumbnail
      link: $link
      file: $file
      isClothing: $isClothing
      isMerchandise: $isMerchandise
      filename: $filename
      price: $price
      isEbook: $isEbook
      isDigitalDownload: $isDigitalDownload
      isCourse: $isCourse
      isAudio: $isAudio
      isWebinar: $isWebinar
      youtubeWebinar: $youtubeWebinar
      zoomWebinar: $zoomWebinar
      twitchWebinar: $twitchWebinar
      isMembership: $isMembership
      tagIds: $tagIds
    ) {
      id
      price
      tags{
        id
        tag
      }
    }
  }
`;


const CREATE_LINKS = gql`
  mutation CreateLinks($links: [LinkInput!]!) {
    createLinks(links: $links) {
      id
      linkText
      link
      image
    }
  }
`;
export function ProductForm({ closeModal }) {
    const [image, setImage] = useState();
    const [imageLoading, setImageLoading] = useState(false);
    const inputFile = React.useRef()
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [images, setImages] = useState([]);
    const [imagesLoading, setImagesLoading] = useState(false);
    const defaultSizes = ['Large', 'Medium', 'Small', 'XL', 'XXL'];

    const handleImageChange = async (e) => {
        try {
            const files = e.target.files;

            if (!files || files.length === 0) {
                return;
            }

            const data = new FormData();
            data.append('file', files[0]); // Assuming you only want to upload one image at a time
            data.append('upload_preset', 'creatorsmarket');

            setImagesLoading(true);

            const res = await fetch('https://api.cloudinary.com/v1_1/db5pewtrp/image/upload', {
                method: 'POST',
                body: data,
            });

            if (!res.ok) {
                console.error('Cloudinary API Error:', res.status, res.statusText);
                const errorResponse = await res.json();
                console.error('Cloudinary Error Response:', errorResponse);
                throw new Error('Failed to upload image');
            }

            const responseData = await res.json();

            if (!responseData.secure_url) {
                console.error('Invalid Cloudinary response:', responseData);
                throw new Error('Invalid Cloudinary response');
            }

            setImages((prevImages) => [...prevImages, responseData.secure_url]);
            setImagesLoading(false);
        } catch (error) {
            console.error('Error in handleImageChange:', error);
            setImagesLoading(false);
            // Handle the error as needed, e.g., show an error message to the user
        }
    };



    const uploadImage = async (e) => {
        const files = e.target.files;
        const data = new FormData();
        data.append('file', files[0]);
        data.append('upload_preset', 'creatorsmarket');
        setImageLoading(true);
        const res = await fetch('https://api.cloudinary.com/v1_1/db5pewtrp/image/upload', {
            method: 'POST',
            body: data,
        });
        const file = await res.json();

        setImage(file.secure_url);
        setImageLoading(false);
    };
    const [create] = useMutation(CREATE, { refetchQueries: [ME_QUERY] })

    const formik = useFormik({
        initialValues: {
            form: [],
        },
        onSubmit: (values) => {

            alert(JSON.stringify(values));

            console.log(values);
            create({
                variables: {
                    tagIds: [],
                    thumbnail: image,
                    price: actualPrice,
                    title: formData.title,
                    description: formData.description,
                    isEbook: false,
                    isDigitalDownload: false,
                    isDonation: false,
                    isAudio: false,
                    isCourse: false,
                    isWebinar: false,
                    isMembership: false,
                    youtubeWebinar: false,
                    isMerchandise: false,
                    isClothing: false,
                    zoomWebinar: true,
                    twitchWebinar: false,
                    isPhysical: true,
                },
            });
        },
    })
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        price: '',
        thumbnail: '',
        clothingType: '',
        clothingSizes: [], // Use an array to store sizes
    });
    const actualPrice = parseInt(formData.price, 10);

    const [createProduct] = useMutation(CREATE_PRODUCT_MUTATION, { refetchQueries: [ME_QUERY, All] })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const toggleSize = (size) => {
        const updatedSizes = [...formData.clothingSizes];

        if (updatedSizes.includes(size)) {
            // If size is already in the array, remove it
            updatedSizes.splice(updatedSizes.indexOf(size), 1);
        } else {
            // If size is not in the array, add it
            updatedSizes.push(size);
        }

        setFormData({ ...formData, clothingSizes: updatedSizes });
    };
    const Physical = [
        { name: 'Product' },
        { name: 'Clothing' }
    ]



    const handleSubmit = (e) => {
        e.preventDefault();

        createProduct({
            variables: {
                productData: {
                    title: formData.title,
                    description: formData.description,
                    price: actualPrice,
                    thumbnail: image,
                    clothingType: formData.clothingType,
                    images: images,
                    Clothing: {
                        sizes: formData.clothingSizes,
                    },
                },
            },
        });

        // Clear the form
        setFormData({
            title: '',
            description: '',
            price: 0,
            thumbnail: '',
            clothingSizes: [],
        });
    };

    return (

        <div class='p-5'>
            <div>
                <Tab.Group>
                    <div class='flex items-center justify-center flex-col'>

                        <Tab.List class='inline-flex items-center bg-royalblue px-3 space-x-3 py-2 rounded-full'>
                            {Physical.map(item => (
                                <Tab className={({ selected }) =>
                                    classNames(
                                        'py-2 px-3 text-sm rounded-full border-0 outline-0',
                                        selected
                                            ? 'bg-[#fff]  text-royalblue'
                                            : 'text-[#fff] bg-inherit'
                                    )
                                } style={{ fontFamily: 'Semi' }}>
                                    {item.name}
                                </Tab>
                            ))}

                        </Tab.List>
                    </div>
                    <Tab.Panel>
                        <form onSubmit={formik.handleSubmit}>
                            <div class='px-5 py-2 flex gap-10'>
                                <div class='flex w-1/3 h-full'>
                                    <div class='w-full h-full border-[1px] rounded-2xl p-4 border-solid border-gainsboro'>
                                        <div class='text-lg font-["Semi"]'>Thumbnail</div>
                                        <div class='w-full border-dashed border-gainsboro border-[1px] h-32 mt-4 rounded-xl'>


                                        </div>
                                        <div class='text-gray-400 text-xs mt-3 px-2 text-center'>Set the product image thumbnail. Only *.png, *.jpeg, and *.jpg image files are accepted.</div>
                                    </div>
                                </div>
                                <div class='w-full'>
                                    <div class='flex flex-col w-full'>


                                        <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Product name</div>
                                        <input value={formData.title}
                                            name='title' onChange={handleChange} style={{ fontFamily: 'Face' }} className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4" />
                                        <div class='text-xs mt-2 text-gray-500  font-["Face"]'>A product name is required & recommended to be unique</div>



                                    </div>
                                    <div class='flex items-center w-full gap-x-5 mt-5'>
                                        <div class='w-1/2'>
                                            <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Thumbnail</div>
                                            {!image ? (
                                                <div>
                                                    <label
                                                        style={{ fontFamily: 'Semi' }}
                                                        className='w-full flex text-sm py-4 items-center justify-center text-[#fff] gap-2 rounded-full bg-royalblue'
                                                    >
                                                        <BoltIcon className='w-4 h-4' />

                                                        Upload Thumbnail
                                                        <input
                                                            id='inputFile'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            type='file'
                                                            onChange={uploadImage}

                                                        />
                                                    </label>
                                                </div>
                                            ) : (
                                                <div class='w-full rounded-xl shadow border p-5'>
                                                    <img src={image} class='w-full rounded-xl' />
                                                </div>
                                            )}

                                        </div>
                                        <div class='flex-1 flex flex-col w-full'>

                                            <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Base price</div>
                                            <input
                                                value={formData.price}  // Set value dynamically
                                                placeholder='$0.00' // Use a placeholder

                                                style={{ fontFamily: 'Face' }}
                                                className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                type='number'
                                                name="price"
                                                onChange={handleChange} // Assuming you have an onChange handler
                                            />
                                            <div class='text-xs mt-2 text-gray-500  font-["Face"]'>Set your price</div>



                                        </div>
                                    </div>
                                    <div class='flex flex-col w-full  mt-5'>

                                        <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Description</div>
                                        <input name='description' value={formData.description}
                                            onChange={handleChange} style={{ fontFamily: 'Face' }} className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4" />
                                        <div class='text-xs mt-2 text-gray-500  font-["Face"]'>Set a brief description</div>



                                    </div>
                                    <div>
                                        <div class='text-sm mb-2 mt-4' style={{ fontFamily: 'Semi' }}>Upload Images</div>


                                        <div>
                                            <label
                                                style={{ fontFamily: 'Semi' }}
                                                className='w-full flex text-sm py-4 items-center justify-center text-[#fff] gap-2 rounded-full bg-royalblue'
                                            >
                                                <BoltIcon className='w-4 h-4' />
                                                Upload Images
                                                <input
                                                    id='inputFile'
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                    type='file'
                                                    onChange={handleImageChange}
                                                    multiple  // Allow multiple file selection
                                                />
                                            </label>
                                        </div>

                                        {images?.map((item, index) => (
                                            <img key={index} src={item} class='w-40 h-40 rounded-xl' />
                                        ))}



                                        <div class='mt-4 flex space-x-3'>
                                            <button type='submit' onClick={formik.handleSubmit} class='py-6 border-0 bg-royalblue text-[#fff] w-full rounded-xl ' style={{ fontFamily: 'Semi' }}>Create</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Tab.Panel>

                    <Tab.Panel>
                        <form onSubmit={handleSubmit}>
                            <div class='flex items-center gap-x-5 mt-5'>
                                <div class='w-full'>
                                    <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Title</div>
                                    <input value={formData.title}
                                        name='title' onChange={handleChange} style={{ fontFamily: 'Normal' }} class='w-full border-2 border-black shadow-part rounded-xl px-3 py-2' placeholder='Audio title' />
                                </div>
                                <div class='w-full'>
                                    <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Clothing</div>
                                    <input value={formData.clothingType}
                                        name='clothingType' onChange={handleChange} style={{ fontFamily: 'Normal' }} class='w-full border-2 border-black shadow-part rounded-xl px-3 py-2' placeholder='Shirt, Pants, etc' />
                                </div>
                            </div>
                            <div class='flex items-center w-full gap-x-5 mt-5'>
                                <div class='w-1/3'>
                                    <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Thumbnail</div>
                                    {!image ? (
                                        <div>
                                            <label
                                                style={{ fontFamily: 'Semi' }}
                                                className='w-full flex text-sm py-3 items-center justify-center gap-2 rounded-xl border-2 border-black shadow-part'
                                            >
                                                <BoltIcon className='w-4 h-4' />

                                                Upload Thumbnail
                                                <input
                                                    id='inputFile'
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                    type='file'
                                                    onChange={uploadImage}

                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        <div class='w-full rounded-xl shadow border p-5'>
                                            <img src={image} class='w-full rounded-xl' />
                                        </div>
                                    )}

                                </div>
                                <div class='flex-1'>
                                    <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Description</div>
                                    <input name='description' value={formData.description}
                                        onChange={handleChange} style={{ fontFamily: 'Normal' }} class='w-full border-2 border-black shadow-part rounded-xl px-3 py-2' placeholder='Zoom, youtube, skype' />

                                </div>
                            </div>
                            <div class='flex w-full gap-x-5 mt-5'>
                                <div class='w-1/3'>
                                    <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Price</div>
                                    <input
                                        value={formData.price}  // Set value dynamically
                                        placeholder='$0.00' // Use a placeholder
                                        style={{ fontFamily: 'Normal' }}
                                        className='w-full outline-0 border-2 border-black shadow-part rounded-xl px-3 py-2'

                                        type='number'
                                        name="price"
                                        onChange={handleChange} // Assuming you have an onChange handler
                                    />
                                </div>
                                <div class='flex-1'>
                                    <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Sizes</div>
                                    <div className="gap-2 flex items-center flex-wrap">
                                        {defaultSizes.map((size) => (
                                            <div onClick={() => toggleSize(size)} style={{ fontFamily: 'Semi' }} key={size} className="flex text-sm active:bg-black active:text-white rounded-xl border-2 border-black shadow-part px-3 py-1 items-center">

                                                {size}
                                            </div>
                                        ))}
                                    </div>
                                    <div class='flex mt-2 items-center'>
                                        {formData.clothingSizes.map(item => (<div class='font-["Face"]'>{item} -</div>))}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class='text-sm my-2 mt-4' style={{ fontFamily: 'Semi' }}>Upload Images</div>


                                <div>
                                    <label
                                        style={{ fontFamily: 'Semi' }}
                                        className='w-full flex text-sm py-3 items-center justify-center gap-2 rounded-xl border-2 border-black shadow-part'
                                    >
                                        <BoltIcon className='w-4 h-4' />
                                        Upload Images
                                        <input
                                            id='inputFile'
                                            style={{
                                                display: 'none',
                                            }}
                                            type='file'
                                            onChange={handleImageChange}
                                            multiple  // Allow multiple file selection
                                        />
                                    </label>
                                </div>

                                {images?.map((item, index) => (
                                    <img key={index} src={item} class='w-40 h-40 rounded-xl' />
                                ))}

                                <div class='mt-4 flex space-x-3'>
                                    <button type='submit' class='py-3 w-1/2 rounded-xl border-2 border-black shadow-part' style={{ fontFamily: 'Semi' }}>Create Audio</button>
                                    <button type='button' class='py-3 w-1/2 rounded-xl bg-black text-white font-["Semi"]' style={{ fontFamily: 'Semi' }}>Cancel</button>

                                </div>
                            </div>
                        </form>
                    </Tab.Panel>
                </Tab.Group>
            </div>

        </div>

    )
}
export const HomeDash = () => {

   
    const Orders = [
        { name: 'All' },
        { name: 'Unfulfilled' },
        { name: 'Donations' }
    ]


    const { data, error, loading } = useQuery(ME_QUERY)

    const plans = [
        {
            name: 'CreatorzShop Lite',
            iconColor: 'text-red-500',
            bgColor: 'bg-red-200',
            description:
                'Perfect for creators or brands starting out and looking for a compact yet powerful selling platform.',
            icon: "l",
            price: 'Free',
            status: data?.me?.cmFree === true ? 'Subscribed' : 'Subscribe',
            features: ['Sell merch, ebooks, downloads, etc..', 'Keep all your links together', 'Customizable storefront', 'Email list creation', 'Donations']
        },
        {
            name: 'CreatorzShop Plus',
            iconColor: 'text-purple-500',
            bgColor: 'bg-purple-200',
            description:
                'Tailored for influencers or brands with a burgeoning following seeking advanced features and solutions.',
            icon: "ll",
            price: '$4',
            status: data?.me?.cmPro === true ? 'Subscribed' : 'Subscribe',
            features: ['Everyting in free', '0 transaction fees', 'No CreatorzShop watermark', 'Embedded links', 'Partial template access']
        },
        {
            name: 'CreatorzShop Elite',
            iconColor: 'text-yellow-500',
            bgColor: 'bg-yellow-200',
            description:
                'Designed for top-tier influencers or renowned brands looking for effortless product selling solutions.',
            icon: "lll",
            price: '$15',
            status: data?.me?.cmBlack === true ? 'Subscribed' : 'Subscribe',
            features: ['Everyting in plus', 'Custom checkout', 'Access to all Templates', 'Email subs']
        },
        {
            name: 'Paid features',
            iconColor: 'text-green-500',
            bgColor: 'bg-green-200',
            description: 'Select this to pay for specific features instead of opting for a CreatorzShop subscription package.',
            icon: "$",
            price: 'Custom',
            status: 'Purchase Features',
            features: ['No watermark', 'Embedded links', 'Email subs', 'Access to all templates', 'Custom checkout']
        }
    ]




    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        });
    };





    const [selectedLink, setSelectedLink] = useState(null);


    const openLink = (item) => {
        setSelectedLink(item);

    };

    const closeLink = () => {
        setSelectedLink(null);
    };

    const [selectedPost, setSelectedPost] = useState(null);

    const openDialog = (item) => {
        setSelectedPost(item);

    };

    const closeDialog = () => {
        setSelectedPost(null);
    };





    const [cumulativePayouts, setCumulativePayouts] = useState({});

    useEffect(() => {

        if (!loading && !error && data && data.me && data.me.createdAt && data.me.Payouts) {
            const accountCreationDate = new Date(data.me.createdAt);
            const payouts = data.me.Payouts;

            let cumulativeData = {};

            // Initialize with $0 on the account creation date
            cumulativeData[accountCreationDate.toISOString().split('T')[0]] = 0;

            // Calculate cumulative payouts
            payouts.forEach((payout) => {
                const payoutDate = new Date(payout.createdAt);

                // Include payouts that occurred on or after the account creation date
                if (payoutDate >= accountCreationDate) {
                    const dateKey = payoutDate.toISOString().split('T')[0];

                    if (!cumulativeData[dateKey]) {
                        cumulativeData[dateKey] = 0;
                    }

                    cumulativeData[dateKey] += payout.amount;
                }
            });

            setCumulativePayouts(cumulativeData);
        }
    }, [loading, error, data]);
    const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

    const totalPay = data?.me?.Payouts ? sumPropertyValue(data.me.Payouts, 'amount') : 0;





    const Products = [
        { name: 'All' },
        { name: 'Active' },
        { name: 'Archive' }
    ]

    const Analytics = [
        { name: 'Overview' },
        { name: 'Products' },
        { name: 'Links' }
    ]

    const Extra = [
        { name: 'Overview' },
        { name: 'Products' },
        { name: 'Orders' },
    ]



    const Tabs = [
        { name: 'Overview', icon: HomeIcon },
        { name: 'Products', icon: ShoppingBagIcon },
        { name: 'Links' },
        { name: 'Analytics', icon: ChartBarIcon },
        { name: 'Orders', icon: ShoppingCartIcon },
        { name: 'Audience' }
    ]

    const fulfilledItemsCount = data?.me?.Payouts?.filter(item => item.fulfilled === true).length;
    const unfulfilledItemsCount = data?.me?.Payouts?.filter(item => item.fulfilled === false).length;
    const nonDonationOrders = data?.me?.Payouts.filter(item => item.donation === false).length



    const Mains = [
        { name: 'Dashboard' },
        { name: 'Storefront' },
        { name: 'Pro' },
        { name: 'Settings' }
    ]
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    if (error) return <div>{error.message}</div>
    if (loading) return <Loading />
    return (
        <DataProcessor payouts={totalPay} allData={data.me} data={cumulativePayouts} />
    )
}