import React from 'react'
import { Banner, Bottom, FrameComponent, Head, Logout, User } from './DataProcessor'
import { useQuery } from '@apollo/client'
import { ME_QUERY } from '../Data/Me'
import Edit from '../Templates/Template1'
import EditStore from '../Templates/EditTemplate'
import { Items } from './DataProcessor'

const Linkinbio = () => {

    const { data, error, loading } = useQuery(ME_QUERY)

    if (error) return <div>{error.message}</div>
    if (loading) return <div>Loading</div>
    return (
        <div>
            <Banner />

            <div className="w-full fixed top-10 rounded-xl bg-main-color-white flex flex-row items-start justify-start leading-[normal] tracking-[normal] mq1050:pl-5 mq1050:pr-5 mq1050:box-border">
                <div class='fixed bottom-5 right-5 flex items-center justify-center rounded-full h-14 w-14 bg-royalblue'>
                    <img src='/Comment.svg' class='w-7 h-7' />
                </div>

                <div className="relative w-[280px] border-r-[1px] border-0 border-solid border-gainsboro bg-main-color-white box-border flex flex-col items-start justify-between overflow-y-auto min-h-screen">
                    <Head />
                    <div className="self-stretch flex flex-col pt-6 items-start justify-start px-[23px] gap-[15px] shrink-0 [debug_commit:f6aba90] border-t-[1px] border-solid border-whitesmoke-200 mq750:pt-5 mq750:pb-[183px] mq1050:pt-[21px] mq1050:pb-[281px] border-0 mq450:gap-[18px] overflow-y-auto flex-grow">
                        <User data={data.me} />
                        <Items isLinkinBio={true} data={data.me} />
                    </div>
                    <div className="self-stretch flex-shrink-0">
                        <Bottom allData={data.me} />
                    </div>
                </div>

                <main className="flex-1 flex flex-col items-start justify-start gap-[27px] max-w-[calc(100%_-_280px)] mq1050:max-w-full">
                    <FrameComponent data={data.me} />
                    <section className="self-stretch flex flex-row items-start justify-start py-0 px-8 box-border max-w-full text-left text-11xl text-gray1-200 font-body-medium-medium">
                        <div className="w-full flex flex-col items-start justify-start gap-[24px] max-w-full">
                            <div className="w-full flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col gap-[24px]">
                                        <div className="flex flex-row w-full justify-between">
                                            <div>
                                                <h1 className="m-0 relative text-inherit leading-[48px] font-['Bold'] mq1050:text-5xl mq1050:leading-[38px] mq450:text-lg mq450:leading-[29px]">
                                                    Build Link-in-bio
                                                </h1>
                                                <div className="relative text-sm mt-1 font-['Face'] text-darkgray-300">
                                                    Welcome back, username
                                                </div>
                                            </div>
                                            <div>
                                                <div className="h-10 w-10 rounded-[48px] bg-main-color-white box-border flex flex-row items-center justify-center py-2 px-1.5 border-[2px] border-dashed border-gainsboro">
                                                    <img
                                                        className="h-6 w-6 relative overflow-hidden shrink-0"
                                                        alt=""
                                                        src="/icon-3.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {!data.me.Website ? (
                                            <Edit />
                                        ) : (
                                            <EditStore />
                                        )}
                                    </div>

                                </div>

                            </div>

                        </div>
                    </section>
                </main>
            </div>
        </div>

    )
}

export default Linkinbio