import SimpleChart from "../Components/Ana";
import { ArrowRightEndOnRectangleIcon, BellIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, BoltIcon, ChevronLeftIcon, UserIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from "react";
import { Feedback } from "./Landing";
import { Dialog, Transition } from "@headlessui/react";
import Add from "./Add";
import { FaBars, FaDollarSign, FaEnvelope, FaHandPointer, FaImage, FaInbox, FaLink, FaRegHandPointer, FaSearch, FaShoppingBag, FaShoppingCart, FaStore, FaUser, FaUserAlt } from "react-icons/fa";
import { ArrowsUpDownIcon, EllipsisHorizontalIcon, ArchiveBoxIcon, ArchiveBoxXMarkIcon, ArchiveBoxArrowDownIcon, PlusIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import Subscribe from "../Components/Subscribe";

export const Head = () => {
  return (
    <div className="cursor-pointer border-0 py-[20px] px-[27px] bg-[transparent] overflow-x-auto flex flex-row items-center justify-between gap-[20px] shrink-0 [debug_commit:f6aba90] border-r-[1px]">
      <a href='/dashboard' className="flex flex-col items-start justify-start py-0 pr-2.5 pl-0">
        <img className="relative h-9" alt="" src="/union5.svg" />
      </a>


    </div>
  );
};

export const User = ({ data }) => {
  return (
    <div className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-6 gap-[10.5px] text-left text-gray1-200 border-b-[1px] border-t-0 border-l-0 border-r-0 border-solid border-whitesmoke-200">
      <div class='border-[4px] rounded-full flex items-center justify-center border-royalblue p-1 border-solid'>
        <img
          className="h-12 w-12 relative rounded-31xl object-cover"
          loading="lazy"
          alt=""
          src={data.avatar}
        />
      </div>
      <div className="w-[137px] flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[4px]">
        <div className="relative text-sm font-['Bold']">
          {data.name}
        </div>
        <div className="relative font-['Face'] text-sm text-gray-200">
          {data.type}
        </div>
      </div>
      <img
        className="h-6 w-6 relative overflow-hidden shrink-0"
        loading="lazy"
        alt=""
        src="/unfold-more.svg"
      />
    </div>
  );
};


export const Items = ({ data, isDashboard, isStoredront, isLinkinBio, isProducts, isOrders, isAnalytics }) => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start text-left text-sm text-gray1-200 font-['Semi']">

      <a href='/dashboard' className={`self-stretch no-underline flex rounded-full ${isDashboard === true ? 'bg-royalblue' : 'bg-[#fff]'} py-3 px-3 flex-row items-center justify-between gap-[20px]`}>
        <div className="flex flex-row items-center justify-start gap-[16px]">
          {isDashboard === true ? (
            <FaInbox class='w-5 h-5 text-[#fff]' />
          ) : (
            <FaInbox class='w-5 h-5 text-royalblue' />

          )}

          <div className={`relative leading-[20px] font-['Bold'] ${isDashboard === true ? 'text-[#fff]' : 'text-[#000]'} inline-block min-w-[74px]`}>
            Dashboard
          </div>
        </div>
        <div className={`rounded-2xl ${isDashboard === true ? 'bg-[#fff]' : 'bg-royalblue'} flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-royalblue`}>
          <div className={`w-[7px] relative font-["Bold"] leading-[16px] ${isDashboard === true ? 'text-royalblue' : 'text-[#fff]'} inline-block`}>
            4
          </div>
        </div>
      </a>
      <a href='/products' className={`self-stretch no-underline flex rounded-full ${isProducts === true ? 'bg-royalblue' : 'bg-[#fff]'} py-3 px-3 flex-row items-center justify-between gap-[20px]`}>
        <div className="flex flex-row items-center justify-start gap-[16px]">
          {isProducts === true ? (
            <FaShoppingBag class='w-5 h-5 text-[#fff]' />
          ) : (
            <FaShoppingBag class='w-5 h-5 text-royalblue' />

          )}
          <div className={`relative leading-[20px] font-['Bold'] ${isProducts === true ? 'text-[#fff]' : 'text-[#000]'} inline-block min-w-[74px]`}>
            Products
          </div>
        </div>
        <div className={`rounded-2xl ${isProducts === true ? 'bg-[#fff]' : 'bg-royalblue'} flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-royalblue`}>
          <div className={`w-[7px] relative font-["Bold"] leading-[16px] ${isProducts === true ? 'text-royalblue' : 'text-[#fff]'}  inline-block `}>
            {data.posts.length}
          </div>
        </div>
      </a>
      <a href='/orders' className={`self-stretch no-underline flex rounded-full ${isOrders === true ? 'bg-royalblue' : 'bg-[#fff]'} py-3 px-3 flex-row items-center justify-between gap-[20px]`}>
        <div className="flex flex-row items-center justify-start gap-[16px]">
          {isOrders === true ? (
            <FaShoppingCart class='w-5 h-5 text-[#fff]' />

          ) : (
            <FaShoppingCart class='w-5 h-5 text-royalblue' />

          )}
          <div className={`relative leading-[20px] font-['Bold'] ${isOrders === true ? 'text-[#fff]' : 'text-[#000]'} inline-block min-w-[74px]`}>
            Orders
          </div>
        </div>
        <div className={`rounded-2xl ${isOrders === true ? 'bg-[#fff]' : 'bg-royalblue'} flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-royalblue`}>
          <div className={`relative leading-[16px] font-["Bold"] ${isOrders === true ? 'text-royalblue' : 'text-[#fff]'} inline-block`}>
            {data.Payouts.length}
          </div>
        </div>
      </a>
      <a href='/analytics' className={`self-stretch no-underline flex rounded-full ${isAnalytics === true ? 'bg-royalblue' : 'bg-[#fff]'} py-3 px-3 flex-row items-center justify-between gap-[20px]`}>
        <div className="flex flex-row items-center justify-start gap-[16px]">
          {isAnalytics === true ? (
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/data-usage2.svg"
            />
          ) : (
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/data-usage.svg"
            />
          )}
          <div className={`relative leading-[20px] font-['Bold'] ${isAnalytics === true ? 'text-[#fff]' : 'text-[#000]'} inline-block min-w-[74px]`}>
            Analytics
          </div>
        </div>
        <div className={`rounded-2xl ${isAnalytics === true ? 'bg-[#fff]' : 'bg-royalblue'} flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-royalblue`}>
          <div className={`w-[7px] relative font-["Bold"] leading-[16px] ${isAnalytics === true ? 'text-royalblue' : 'text-[#fff]'} inline-block`}>
            2
          </div>
        </div>
      </a>
      <a href='/linkinbio' className={`self-stretch no-underline flex rounded-full ${isLinkinBio === true ? 'bg-royalblue' : 'bg-[#fff]'} py-3 px-3 flex-row items-center justify-between gap-[20px]`}>
        <div className="flex flex-row items-center justify-start gap-[16px]">
          {isLinkinBio === true ? (
            <FaLink class='w-5 h-5 text-[#fff]' />
          ) : (
            <FaLink class='w-5 h-5 text-royalblue' />

          )}
          <div className={`relative leading-[20px] font-['Bold'] ${isLinkinBio === true ? 'text-[#fff]' : 'text-[#000]'} inline-block min-w-[74px]`}>
            Link in bio
          </div>
        </div>
        <div className={`rounded-2xl ${isLinkinBio === true ? 'bg-[#fff]' : 'bg-royalblue'} flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-royalblue`}>
          <div className={`w-[7px] relative leading-[16px] font-["Bold"] ${isLinkinBio === true ? 'text-royalblue' : 'text-[#fff]'} inline-block `}>
            2
          </div>
        </div>
      </a>
      <a href='/storefront' className={`self-stretch no-underline flex rounded-full ${isStoredront === true ? 'bg-royalblue' : 'bg-[#fff]'} py-3 px-3 flex-row items-center justify-between gap-[20px]`}>
        <div className="flex flex-row items-center justify-start gap-[16px]">
          {isStoredront === true ? (
            <FaStore class='w-5 h-5 text-[#fff]' />

          ) : (
            <FaStore class='w-5 h-5 text-royalblue' />

          )}
          <div className={`relative leading-[20px] font-['Bold'] ${isStoredront === true ? 'text-[#fff]' : 'text-[#000]'} inline-block min-w-[74px]`}>
            Storefront
          </div>

        </div>
        <div className={`rounded-2xl ${isStoredront === true ? 'bg-[#fff]' : 'bg-royalblue'} flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-royalblue`}>
          <div className={`relative leading-[16px] font-["Bold"] ${isStoredront === true ? 'text-royalblue' : 'text-[#fff]'} `}>
            Beta
          </div>
        </div>
      </a>

      <a href='/settings' className="self-stretch no-underline flex py-3 px-3 hover:bg-gray-50 rounded-full flex-row items-center justify-between gap-[20px]">
        <div className="flex flex-row items-center justify-start gap-[16px]">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/tune.svg"
          />
          <div className="relative leading-[20px] font-['Bold'] text-[#000] inline-block min-w-[74px]">
            Settings
          </div>
        </div>
        <div className="rounded-2xl bg-main-color-primary flex flex-row items-center justify-center py-1 px-[8.5px] text-center text-3xs text-main-color-white">
          <div className="w-[7px] relative leading-[16px] font-extrabold inline-block min-w-[7px]">
            2
          </div>
        </div>
      </a>
    </div>
  )
}
export const FrameComponent = ({ data }) => {

  const [subscriptionOpen, setSubscriptionOpen] = useState(false)

  function openModal() {
    setSubscriptionOpen(true)
  }

  function closeModal() {
    setSubscriptionOpen(false)
  }
  return (
    <header className="self-stretch h-[76px] 2xs:hidden xs:hidden md:flex max-w-full justify-between flex text-xs border-b-[1px] border-0 border-b-[1px] border-solid border-whitesmoke-200 text-gray-500 ">
      <div className=" box-border w-full flex flex-row items-center justify-start py-7 px-8 max-w-full h-full  mq450:gap-[70px]">
        <div className="w-[264px] rounded-xl flex flex-row items-center justify-start mq450:gap-[16px]">
          <div className="flex-1 rounded-11xl flex flex-col items-start justify-start gap-[12px]">
            <div className="w-60 hidden flex-row items-start justify-start gap-[8px]">
              <b className="relative leading-[20px] whitespace-nowrap">Label</b>
              <div className="h-5 flex-1 relative" />
              <b className="relative text-3xs leading-[16px] text-gray-200">
                Optional
              </b>
            </div>
            <div className="self-stretch rounded-2xl bg-main-color-white flex flex-row items-center justify-start py-0 px-[15px] border-[1px] border-solid border-[#e7e7e7]">
              <div className="w-[76px] flex flex-row items-center justify-start gap-[10px]">
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0"
                  alt=""
                  src="/icon.svg"
                />
                <input
                  className="[border:none] placeholder:text-[#c7ced9] [outline:none] inline-block font-['Semi'] text-xs bg-[transparent]  text-left min-w-[25px] whitespace-nowrap p-0"
                  placeholder="Search keywords..."
                  type="text"
                />
              </div>
              <div className="h-12 flex-1" />
            </div>
            <b className="w-[57px] relative text-3xs leading-[16px] hidden text-gray-200 whitespace-nowrap">
              Helper Text
            </b>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end py-[0.2px] px-9 gap-[10px] z-[1] text-sm text-main-color-black">
        <Add />

        <div onClick={openModal} class='px-4 py-3 rounded-[10px] flex gap-2 items-center bg-royalblue'>
          <BoltIcon className="text-[#fff] w-3 h-3" />
          <div class='text-[#fff] font-["Semi"] flex whitespace-nowrap text-xs'>Upgrade account</div>


        </div>

      </div>
      <Transition appear show={subscriptionOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform h-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Subscribe />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </header>
  );
};

export const Banner = () => (
  <div class={`w-full relative hidden md:flex h-10 flex items-center justify-center text-[#fff] font-["Semi"] text-sm bg-slate-800`}>
    Welcome to Creatorzhub's beta product! ✨🎊


  </div>
)
export const Logout = () => {
  const [logout, setLogout] = useState(false);
  const closeModal = () => {
    setLogout(false);
  };

  const openModal = () => {
    setLogout(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    closeModal(); // Close the modal after logout
  };

  return (
    <div class='w-full z-10'>
      <div class='flex items-center gap-2 pt-4 pointer-cursor justify-between'>
        <button onClick={openModal} class='flex bg-[#fff] border-0 items-center gap-2'>
          <ArrowRightEndOnRectangleIcon class='w-6 h-6 text-royalblue' />
          <div class='ml-2'>
            <div class='text-sm font-["Semi"]'>Logout</div>
          </div>
        </button>
      </div>

      {logout === true && (
        <div className="fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center">
          {/* Background overlay */}
          <div className="absolute inset-0 bg-slate-800 opacity-30"></div>
          {/* Content */}
          <div className="relative max-w-sm px-10 py-8 rounded-xl bg-white">
            <div class='flex items-center justify-center '>
              <img src='/union5.svg' className='xl:h-14 lg:h-12 md:h-10 h-9' />
            </div>
            <div className='text-2xl font-["Bold"] text-center mt-3'>Are you logging out?</div>
            <div className='text-center text-gray-400 font-["Face"] mt-3'>Sad to see you go, you can always log back in anytime. Or was this a mistake?</div>
            <div className='flex items-center gap-3 mt-6'>
              <div onClick={() => setLogout(false)} className='p-4 w-full cursor-pointer border-2 rounded-full text-royalblue border-solid border-royalblue font-["Semi"] text-center'>Cancel</div>
              <a href='/' onClick={handleLogout} className='p-4 w-full no-underline cursor-pointer bg-royalblue rounded-full text-center text-white font-["Semi"] '>Logout</a>
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

export const Bottom = ({ allData }) => {
  return (
    <div className="px-7 self-stretch">
      <div className="flex items-center flex-col justify-center">
        <div className="border-[1px] border-gainsboro border-solid p-5 rounded-xl self-stretch text-center text-[#000]">
          <div class='text-black font-["Semi"]'>Need guidance?</div>
          <div class='mt-2 text-sm md:hidden 2xl:flex font-["Face"]'>Watch video tutorials to recieve help with this app.</div>
          <div className="px-3 py-2 inline-block rounded-lg mt-3 font-['Semi'] text-xs bg-royalblue text-[#fff]">Watch tutorials</div>

        </div>
      </div>
      <div class='py-1 border-0 border-gainsboro' />

      <div class='flex items-center justify-between px-2 pt-2'>
        <div class='flex items-center space-x-3'>
          <img src={allData.avatar} class='w-10 h-10 rounded-full' />
          <div>
            <div class='text-[#000] font-["Semi"] text-[14px]'>{allData.name}</div>
            <div class='text-xs font-["Face"] text-gray-300'>{allData.type}</div>
          </div>
        </div>
        <EllipsisHorizontalIcon class='w-5 h-5 text-[#000]' />
      </div>



    </div>
  )
}


const DataProcessor = ({ data, allData, payouts }) => {
  const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);
  const [fulfilledCount, setFulfilledCount] = useState(0);
  const [unfulfilledCount, setUnfulfilledCount] = useState(0);
  const [open, setOpen] = useState(false)
  const [openPayout, setOpenPayout] = useState(false)

  useEffect(() => {
    if (allData) {
      const fulfilledPayouts = allData.Payouts.filter(payout => payout.fulfilled);
      setFulfilledCount(fulfilledPayouts.length);

      const totalAmount2 = allData.Payouts.reduce((acc, payout) => acc + payout.amount, 0);

      const productRevenue = allData.Payouts.reduce((acc, payout) => {
        const product = payout.Product.title;
        const amount = payout.amount;
        acc[product] = (acc[product] || 0) + amount;
        return acc;
      }, {});

      const productPercentage = Object.keys(productRevenue).reduce((acc, product) => {
        acc[product] = (productRevenue[product] / totalAmount2) * 100;
        return acc;
      }, {});

      setPercentage(productPercentage)



      const unfulfilledPayouts = allData.Payouts.filter(payout => !payout.fulfilled);
      setUnfulfilledCount(unfulfilledPayouts.length);
    }
  }, [allData]);



  const totalPay = allData?.Payouts ? sumPropertyValue(allData?.Payouts, 'amount') : 0;
  const [selectedPost, setSelectedPost] = useState(null);

  const openDialog = (item) => {
    setSelectedPost(item);

  };
  function openTingz() {
    setOpenPayout(true)
  }

  function closeTingz() {
    setOpenPayout(false)
  }
  const [percentageWidth, setPercentage] = useState(0)

  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const handlePayout = async (e) => {
    e.preventDefault();
    setLoading(true);

    const chubFee = parseFloat((1 * 0.079 + 0.30).toFixed(2));
    const actualPay = parseFloat((1 - chubFee).toFixed(2));
    const actualPayInCents = Math.round(actualPay * 100); // Convert to cents and round

    try {
      const response = await fetch('https://server-kyt.herokuapp.com/payout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: actualPayInCents,
        }),
      });

      const responseData = await response.json();

      if (responseData.success) {
        setMessage('Payout successful');
      } else {
        setMessage(`Payout failed: ${responseData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Payout failed');
    }

    setLoading(false);
  };

  const [subscriptionOpen, setSubscriptionOpen] = useState(false)

  function openModal() {
    setSubscriptionOpen(true)
  }

  function closeModal() {
    setSubscriptionOpen(false)
  }

  const chubFee = (1 * 0.079 + 0.30).toFixed(2);
  const actualPay = Math.floor(1 - chubFee); // or Math.ceil or Math.round depending on your needs
  const actualPayInCents = Math.round((1 - chubFee) * 100);

  function getTimeAgoString(createdAt) {
    const currentDate = new Date();
    const createdAtDate = new Date(createdAt);
    const timeDifference = currentDate.getTime() - createdAtDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
      return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
  }
  const closeDialog = () => {
    setSelectedPost(null);
  };
  return (
    <div class=''>
      <Banner />
      <Feedback blue={true} />

      <div className="w-full 2xs:flex-col md:mt-[0px] md:flex-row fixed rounded-xl bg-main-color-white flex flex-row items-start justify-start leading-[normal] tracking-[normal] mq1050:box-border">
        <div class='h-14 md:hidden self-stretch px-4 py-2 gap-4 border-b border-0 border-gainsboro border-solid flex items-center'>
          <div class='w-10'>
            <div class='h-9 w-9 rounded-lg border-[1px] border-gainsboro border-solid items-center justify-center flex border-[1px]'>
              <FaBars class='h-4.5 w-4.5' />
            </div>
          </div>
          <div class='w-full self-stretch flex items-center justify-center'>
            <div class='relative w-full border-solid p-3 items-center gap-2 flex border-[1px] rounded-full border-gainsboro'>
              <MagnifyingGlassIcon class='w-5 h-5 text-gray-400' />
              <input placeholder='Search' class='border-[0px] text-[14px] placeholder:text-gray-400 outline-0 font-["Face"]' />
            </div>
          </div>
          <div class='w-12'>
            <div class='relative'>
              <div class='text-xs absolute bottom-[-3px] right-0 border-[2px] border-solid border-white font-["Semi"] bg-royalblue inline-block w-5 flex items-center justify-center h-5 rounded-full text-white'>
                {allData.recentActivity.length}
              </div>
              <BellIcon class='w-6 h-6' />
            </div>
          </div>
        </div>
        <div className="relative xs:hidden 2xs:hidden md:flex w-[280px] border-r-[1px] border-0 border-solid border-gainsboro bg-main-color-white box-border flex flex-col items-start justify-between overflow-y-auto min-h-screen">
          <Head />
          <div className="self-stretch flex flex-col pt-6 items-start justify-start px-[23px] gap-[15px] shrink-0 [debug_commit:f6aba90] border-t-[1px] border-solid border-whitesmoke-200 mq750:pt-5 mq750:pb-[183px] mq1050:pt-[21px] mq1050:pb-[281px] border-0 mq450:gap-[18px] overflow-y-auto flex-grow">
            <User data={allData} />
            <Items isDashboard={true} data={allData} />
          </div>
          <div className="self-stretch flex-shrink-0">
            <Bottom allData={allData} />
          </div>
        </div>


        <main className="flex-1 flex flex-col w-full h-screen items-start justify-start gap-[27px] md:max-w-[calc(100%_-_280px)] mq1050:max-w-full">
          <FrameComponent data={allData} />
          <section className="self-stretch flex flex-row items-start justify-start py-0 md:px-8 2xs:px-4  overflow-y-scroll  h-full box-border g-full max-w-full text-left text-11xl text-gray1-200 font-body-medium-medium">

            <div className="flex-1 flex flex-col items-start justify-start gap-[24px] max-w-full">

              <div className="self-stretch flex md:hidden flex-col items-start justify-start">


                <div class='md:hidden 2xs:flex self-stretch p-5 justify-between items-center text-base font-["Bold"] text-[#fff] rounded-xl mt-5 bg-royalblue'>
                  <div class='w-2/3'>  Grow with pro, subscribe today and get more!</div>
                  <img class='h-10' src='/cube.svg' />
                </div>
                <div class='flex items-center self-stretch 2xs:mt-5 justify-between'>
                  <div class='flex flex-col'>
                    <div className="m-0 2xs:text-2xl sm:text-2xl md:text-3xl  md:mt-[0px] relative leading-[48px] font-['Bold']  mq1050:leading-[38px]  mq450:leading-[29px]">
                      Dashboard
                    </div>





                    <div className="relative text-sm mt-2 tracking-[-0.01em] leading-[20px] font-['Face'] text-darkgray-300">
                      Welcome back, {allData.name}
                    </div>
                  </div>
                  <div>
                    <img src={allData.avatar} class='w-10 h-10 rounded-full' />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex 2xs:hidden md:flex flex-col items-start justify-start">

                <div className="self-stretch flex flex-col items-start justify-start">

                  <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                    <div class='md:hidden 2xs:flex w-full p-5 justify-between items-center text-base font-["Bold"] text-[#fff] rounded-xl mt-5 bg-royalblue'>
                      <div class='w-2/3'>  Grow with pro, subscribe today and get more!</div>
                      <img class='h-10' src='/cube.svg' />
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[24px]">
                      <div className="m-0 2xs:text-2xl sm:text-2xl md:text-3xl  md:mt-[0px] relative leading-[48px] font-['Bold']  mq1050:leading-[38px]  mq450:leading-[29px]">
                        Dashboard
                      </div>

                    </div>

                  </div>
                </div>
                <div className="relative text-sm mt-2 tracking-[-0.01em] leading-[20px] font-['Face'] text-darkgray-300">
                  Welcome back, {allData.name}
                </div>
              </div>
              <div className="self-stretch flex flex-col text-center text-sm text-main-color-white">
                <div className="self-stretch flex flex-row w-full max-w-full mq1050:flex-wrap">
                  <div className="flex flex-col items-start justify-start w-full max-w-full ">
                    <div class='w-full'>
                      <div class='2xs:flex 2xs:mb-6 md:mb-[0px] 2xs:overflow-x-scroll grid grid-cols-4 gap-5 lg:flex 2xl:hidden h-20 2xs:h-auto w-full'>
                        <div class='border-gainsboro p-5 2xs:w-52 self-stretch w-full rounded-xl border-[1px] border-solid'>
                          <div class='flex items-center justify-between w-full 2xs:w-52 self-stretch'>
                            <div class='flex flex-row justify-start items-center space-x-4'>
                              <div class='bg-royalblue w-9 h-9 rounded-full shrink-0 flex items-center justify-center'>
                                <FaHandPointer class='text-[#fff] w-4 h-4' />
                              </div>
                              <div>
                                <div class='text-sm text-[#000] justify-start items-start flex font-["Semi"]'>Clicks</div>
                                <div class='text-xs font-["Face"] justify-start items-start flex text-gray-400 mt-1'>Overall click data</div>
                              </div>
                            </div>

                            <div>
                              <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                <ChevronRightIcon class='text-black w-4 h-4' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='border-gainsboro p-5 2xs:w-52 self-stretch w-full rounded-xl border-[1px] border-solid'>
                          <div class='flex items-center justify-between w-full 2xs:w-52 self-stretch'>
                            <div class='flex flex-row justify-start items-center space-x-4'>
                              <div class='bg-royalblue w-9 h-9 rounded-full shrink-0 flex items-center justify-center'>
                                <FaUser class='text-[#fff] w-4 h-4' />
                              </div>
                              <div>
                                <div class='text-sm text-[#000] justify-start items-start font-["Semi"]'>Audience</div>
                                <div class='text-xs font-["Face"] justify-start  items-start text-gray-400 mt-1'>Audience list</div>
                              </div>
                            </div>

                            <div>
                              <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                <ChevronRightIcon class='text-black w-4 h-4' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='border-gainsboro p-5 2xs:w-52 self-stretch w-full rounded-xl border-[1px] border-solid'>
                          <div class='flex items-center justify-between w-full 2xs:w-52 self-stretch'>
                            <div class='flex flex-row justify-start items-center space-x-4'>
                              <div class='bg-royalblue w-9 h-9 rounded-full shrink-0 flex items-center justify-center'>
                                <FaDollarSign class='text-[#fff] w-4 h-4' />
                              </div>
                              <div>
                                <div class='text-sm text-[#000] font-["Semi"]'>Revenue</div>
                                <div class='text-xs font-["Face"] text-gray-400 mt-1'>Made money</div>
                              </div>
                            </div>

                            <div>
                              <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                <ChevronRightIcon class='text-black w-4 h-4' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='border-gainsboro p-5 2xs:w-52 self-stretch w-full rounded-xl border-[1px] border-solid'>
                          <div class='flex items-center justify-between w-full 2xs:w-52 self-stretch'>
                            <div class='flex flex-row justify-start items-center space-x-4'>
                              <div class='bg-royalblue w-9 h-9 rounded-full shrink-0 flex items-center justify-center'>
                                <FaShoppingCart class='text-[#fff] w-4 h-4' />
                              </div>
                              <div>
                                <div class='text-sm text-[#000] font-["Semi"]'>Orders</div>
                                <div class='text-xs font-["Face"] text-gray-400 mt-1'>Number of orders</div>
                              </div>
                            </div>

                            <div>
                              <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                <ChevronRightIcon class='text-black w-4 h-4' />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class='2xl:grid 2xl:grid-cols-3 2xs:hidden gap-5 mb-3 w-full md:hidden'>
                      <div className="rounded-2xl p-7 bg-main-color-primary">
                        <div className="flex-1 flex flex-row items-start justify-start gap-[16px]">

                          <div className="h-6 w-6 relative">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]" />
                            <div className="absolute h-[41.67%] w-[16.67%] top-[50%] right-[75%] bottom-[8.33%] left-[8.33%] rounded-sm bg-main-color-white z-[1]" />
                            <div className="absolute h-[60.42%] w-[16.67%] top-[31.25%] right-[41.67%] bottom-[8.33%] left-[41.67%] rounded-sm bg-main-color-white z-[1]" />
                            <div className="absolute h-[83.33%] w-[16.67%] top-[8.33%] right-[8.33%] bottom-[8.33%] left-[75%] rounded-sm bg-main-color-white z-[1]" />
                          </div>
                          <div className="flex-1 flex flex-col items-start justify-start py-0 pr-5 pl-0 text-left text-base">
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                              <div className="relative tracking-[-0.02em] leading-[26px] font-['Face'] inline-block min-w-[65px]">
                                Revenue
                              </div>

                            </div>
                            <div className="relative tracking-[-0.01em] font-['Semi'] whitespace-nowrap text-13xl mq450:text-lgi">
                              <span className="leading-[130%]">${totalPay}.00</span>

                            </div>
                          </div>
                        </div>

                      </div>

                      <div class='h-full rounded-xl border-solid px-5 justify-start items-start flex flex-col border-[1px] border-gainsboro'>
                        <div class='pt-3 pb-2 w-full flex flex-col justify-start items-start'>
                          <div class='flex items-center justify-between w-full'>
                            <div class='text-[16px] font-["Semi"] text-[#000]'>Audience({allData.audience.length} Users)</div>
                            <div class='flex items-center gap-2'>
                              <ChevronLeftIcon class='text-black h-3 w-3' />
                              <ChevronRightIcon class='text-black h-3 w-3' />
                            </div>
                          </div>
                          <div class='mt-2 w-full'>
                            {allData.audience.length === 0 ? (
                              <div class='flex w-full space-x-4'>
                                <div class='flex flex-col justify-center'>
                                  <div class='h-12 w-14 rounded-[10px] border-solid flex justify-center items-center overflow-hidden border-gainsboro border-[1px]'>
                                    <PlusIcon class='w-4 h-4 text-[#000]' />
                                  </div>
                                  <div class='text-black text-2xs mt-2 font-["Semi"]'>Add</div>
                                </div>
                                <div class='w-full'>
                                  <div class='w-full flex h-12 rounded-[10px] items-center justify-center border-solid border-[1px] border-gainsboro'>
                                    <div class='flex items-center space-x-[-10px]'>
                                      <div class='w-8 border-[2px] border-solid border-[#fff] flex items-center justify-center h-8 rounded-full bg-royalblue' >
                                        <UserIcon class='text-white w-5 h-5' />
                                      </div>
                                      <div class='w-8 border-[2px] border-solid border-[#fff] flex items-center justify-center h-8 rounded-full bg-royalblue' >
                                        <UserIcon class='text-white w-5 h-5' />
                                      </div>
                                      <div class='w-8 border-[2px] border-solid border-[#fff] flex items-center justify-center h-8 rounded-full bg-royalblue' >
                                        <UserIcon class='text-white w-5 h-5' />
                                      </div>

                                    </div>
                                  </div>
                                  <div class='text-xs font-["Semi"] text-black mt-2'>Your audience should show here</div>
                                </div>
                              </div>
                            ) : (
                              <div class='flex w-full space-x-4'>
                                <div class='flex flex-col justify-center'>
                                  <div class='h-12 w-14 rounded-[10px] border-solid flex justify-center items-center border-gainsboro border-[1px]'>
                                    <PlusIcon class='w-4 h-4 text-[#000]' />
                                  </div>
                                  <div class='text-black text-2xs mt-2 font-["Semi"]'>Add</div>
                                </div>
                                {allData.audience.map(item => (
                                  <div class='flex items-center justify-center flex-col'>
                                    <div class='w-12 h-12 rounded-full flex items-center justify-center bg-royalblue'>
                                      {item.throughEmail === true ? (
                                        <FaEnvelope class='w-5 h-5' />
                                      ) : (
                                        <FaEnvelope class='w-5 h-5' />
                                      )}
                                    </div>
                                    <div class='text-black font-["Semi"] text-xs mt-2 w-12 line-clamp-1'>{item.email}</div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>

                      </div>
                      <div class='h-full rounded-xl flex  border-solid border-[1px] overflow-hidden border-gainsboro'>
                        <div class='p-3 px-5 justify-start w-full flex-col flex items-start'>
                          <div class='flex items-center justify-between w-full'>
                            <div class='text-[16px] font-["Semi"] text-[#000]'>Latest Activity</div>
                            <EllipsisHorizontalIcon class='w-3 h-3 text-black pr-1.5' />
                          </div>
                          <div class={`flex w-full 2xl:space-x-4 md:space-x-2 mt-2`}>
                            <div class='flex flex-col justify-center'>
                              <div class='h-12 w-14 rounded-[10px] border-solid flex justify-center items-center border-gainsboro border-[1px]'>
                                <PlusIcon class='w-4 h-4 text-[#000]' />
                              </div>
                              <div class='text-black text-2xs mt-2 font-["Semi"]'>Add</div>
                            </div>
                            {allData.recentActivity.map(item => (
                              <div>
                                {item.type === 'Post' && (
                                  <div class='flex justify-center flex-col items-center'>
                                    {!item.image ? (
                                      <div class='h-12 w-12 rounded-full bg-royalblue flex items-center justify-center'>
                                        <FaShoppingBag class='w-5 h-5' />
                                      </div>
                                    ) : (
                                      <img src={item.image} class='h-12 w-12 rounded-full' />

                                    )}
                                    <div class='text-xs font-["Semi"] w-12 line-clamp-1 whitespace-nowrap text-[#000] mt-2'>Created product</div>
                                  </div>
                                )}
                                {item.type === 'Audience' && (
                                  <div class='flex justify-center flex-col items-center'>
                                    <div class='h-12 w-12 rounded-full bg-royalblue flex items-center justify-center'>
                                      <FaUserAlt class='w-5 h-5' />
                                    </div>
                                    <div class='text-xs font-["Semi"] w-12 line-clamp-1 whitespace-nowrap text-[#000] mt-2'>{item.email}</div>
                                  </div>
                                )}
                                {item.type === 'Payout' && (
                                  <div class='flex justify-center flex-col items-center'>
                                    <div class='h-12 w-12 rounded-full bg-royalblue flex items-center justify-center'>
                                      <FaDollarSign class='w-6 h-6' />
                                    </div>
                                    <div class='text-xs font-["Semi"] line-clamp-1 whitespace-nowrap text-[#000] mt-2'>${item.amount} sale</div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>


                        </div>
                      </div>
                    </div>
                    <Transition appear show={openPayout} as={React.Fragment}>
                      <Dialog as="div" className="relative z-10" onClose={closeTingz}>
                        <Transition.Child
                          as={React.Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black/25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={React.Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-6xl transform  overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div>Payout: ${totalPay}</div>
                                <div>What we take: {chubFee}</div>
                                <div>What you get: {actualPay}</div>
                                <div>Breakdown</div>
                                <div>Creatorzhub takes: %5</div>
                                <div>Stripe proccessing fee: %2.9 + $0.30</div>
                                <button class='' onClick={handlePayout}>Payout funds</button>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>

                    <div className="h-[300px] w-full flex sm:my-3 items-center gap-5">
                      <div class='border-[1px] 2xs:mt-3 md:mt-[0px] 2xl:w-3/4 2xs:w-full lg:w-full border-solid h-[300px] rounded-xl border-gainsboro'>
                        <div class='flex flex-col items-start px-5 pt-5'>
                          <div class='w-full flex items-center justify-between mb-2'>
                            <div class='text-lg font-["Face"] text-[#000]'>Revenue</div>
                            <button onClick={openTingz} class='border-gainsboro border-solid flex items-center gap-3 border-[1px] bg-white text-[#000] text-sm px-4 font-["Semi"] py-2 rounded-xl'>
                              Deposit
                              <BanknotesIcon class='text-[#000] w-3 h-3' />
                            </button>

                          </div>
                          <div class='text-2xl text-[#000] font-["Semi"]'>${totalPay}.00 USD</div>
                        </div>

                        <SimpleChart data={data} />
                      </div>
                      <div className="w-1/4 2xs:hidden xl:flex lg:hidden 2xl:flex flex-col h-full items-start justify-start pt-px px-0 pb-0 box-border  max-w-full text-left text-xl text-gray1-100 mq750:min-w-full mq1050:flex-1">
                        <div className="self-stretch flex flex-row h-full items-start justify-start relative max-w-full">

                          <div className="flex-1 relative 2xs:hidden xl:flex rounded-2xl bg-royalblue  overflow-hidden h-full justify-between box-border w-full flex flex-col items-start justify-start py-6 px-[23px] gap-[24px] max-w-full z-[1] border-[1px] border-solid border-greyscale-grey-200 mq750:pt-5 mq750:pb-5 mq750:box-border">
                            <div class='w-full items-center justify-center flex-col flex'>

                              <img class='2xl:h-10 md:h-7' src='/head.svg' />

                              <div class='text-2xl  md:text-[25px] 2xl:text-3xl font-["Promo"] p-3 text-center text-[#fff]'>
                                Grow with pro, subscribe today and get more!
                              </div>

                              <div class='absolute bottom-5 w-full'>
                                <div class='px-4'>
                                  <div onClick={openModal} class='self-stretch max-w-full flex rounded-xl text-royalblue 2xl:text-xl md:text-sm font-["Promo"] items-center justify-center flex-col  bg-[#fff] py-3 px-7'>
                                    Subscribe today!
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Transition appear show={subscriptionOpen} as={React.Fragment}>
                              <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                <Transition.Child
                                  as={React.Fragment}
                                  enter="ease-out duration-300"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="ease-in duration-200"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <div className="fixed inset-0 bg-black/25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                      as={React.Fragment}
                                      enter="ease-out duration-300"
                                      enterFrom="opacity-0 scale-95"
                                      enterTo="opacity-100 scale-100"
                                      leave="ease-in duration-200"
                                      leaveFrom="opacity-100 scale-100"
                                      leaveTo="opacity-0 scale-95"
                                    >
                                      <Dialog.Panel className="transform h-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Subscribe />
                                      </Dialog.Panel>
                                    </Transition.Child>
                                  </div>
                                </div>
                              </Dialog>
                            </Transition>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class='w-full flex 2xs:flex-col md:flex-row gap-5 sm: mt-4 2xs:mt-7'>
                  <div class='2xl:w-1/2 lg:w-2/3 rounded-xl'>


                    <div class=' mb-2 text-[#000] font-["Semi"] flex items-center px-4 pb-3 rounded-[10px] text-sm justify-between'>
                      <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Product</div>
                      <div class='text-center 2xs:hidden w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Clicks</div>
                      <div class='text-center 2xs:hidden w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Status</div>
                      <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Sales</div>
                      <div class='text-center w-full flex items-center justify-center gap-2'>More</div>
                    </div>
                    {allData.anaProducts.length === 0 ? (
                      <div class='border-[1px] border-solid flex items-center justify-center h-full border-gainsboro p-4 rounded-xl'>
                        <div class='w-40 flex justify-center items-center flex-col'>
                          <div class='text-[#000] font-["Semi"] mb-3'>Start selling by creating a product</div>
                          <Add addSign={true} />
                        </div>
                      </div>
                    ) : (
                      <div class='flex border-[1px] border-solid border-gainsboro rounded-xl flex-col'>
                        {allData.anaProducts.map(item => {
                          const productTitle = item.title;

                          const percentage = percentageWidth[productTitle] || 0;
                          return (
                            <div class={`border-solid border-gainsboro border-[0px]`}>

                              <div class='justify-between px-4 py-2.5 flex items-center'>

                                <div class='flex w-full items-center flex-row gap-3'>

                                  <div>
                                    {!item.thumbnail ? (
                                      <div class='relative'>

                                        <div class='w-10 h-10 bg-royalblue flex items-center justify-center rounded-[12px]' >
                                          <FaShoppingBag class='text-[#fff] w-4 h-4' />
                                        </div>

                                      </div>
                                    ) : (
                                      <div class='relative'>
                                        <img src={item.thumbnail} class='w-10 h-10 rounded-[12px]' />

                                      </div>
                                    )}

                                  </div>
                                  <div>
                                    <div class='text-[13px] text-[#000] text-start line-clamp-1 font-["Bold"]'>{item.title}</div>
                                  </div>
                                </div>
                                <div class='flex w-full 2xs:hidden justify-center items-center justify-center gap-2 flex-row'>
                                  <FaRegHandPointer class='text-gray-300 w-4 h-4' />
                                  <div className='text-sm text-[#000] font-["Semi"]'>
                                    {item.points}
                                  </div>
                                </div>
                                <div class='w-full 2xs:hidden flex items-center justify-center'>
                                  <div class='bg-green-100 px-4 py-2  inline-block text-green-500 rounded-lg text-xs font-["Bold"]'>
                                    Active
                                  </div>
                                </div>
                                <div class='w-full flex items-center space-x-3'>
                                  <div class='text-xs text-black font-["Semi"]'>
                                    {percentage.toFixed(0)}%
                                  </div>
                                  <div className="w-full bg-gray-100 h-1.5 overflow-hidden rounded-full relative">
                                    <div
                                      className="bg-royalblue h-1.5"
                                      style={{ width: `${percentage}%` }}
                                    ></div>
                                  </div>

                                </div>
                                <div class='w-full flex justify-center'>
                                  <div class='text-xs inline-block rounded-full px-3 font-["Semi"] py-2 border-[1px] text-[#000] border-solid border-gainsboro'>Edit</div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}


                  </div>


                  <div class='2xl:grid 2xl:w-1/2 lg:1/3 2xl:grid-cols-2 2xl:gap-5'>
                    <div class='2xl:flex lg:hidden flex-col p-5 rounded-xl border-[1px] border-solid border-gainsboro'>
                      <div class='flex items-center justify-between'>
                        <div class='text-[#000] text-xl text-start font-["Bold"]'>Orders({allData.Payouts.length})</div>
                        <EllipsisHorizontalIcon class='w-4 h-4 text-black' />
                      </div>
                      {allData.DashPayouts.length === 0 ? (
                        <div class='h-full flex items-center justify-center'>
                          <div class='text-[#000] text-lg font-["Bold"]'>You have no orders yet</div>
                        </div>
                      ) : (
                        <div class='flex flex-col mt-3 space-y-3'>
                          {allData.DashPayouts.map(item => (
                            <div class='flex items-center justify-between'>
                              <div class='flex items-center space-x-3'>
                                {!item?.Product?.thumbnail || item?.Product?.thumbnail === "" ? (
                                  <div
                                    className="h-10 w-10 rounded-full relative flex items-center justify-center overflow-hidden bg-royalblue shrink-0"


                                  >
                                    <FaShoppingBag class='text-[#fff]' />
                                  </div>
                                ) : (
                                  <img
                                    className="h-10 w-10 rounded-full relative overflow-hidden shrink-0"
                                    loading="lazy"
                                    alt=""
                                    src={item?.Product?.thumbnail}
                                  />
                                )}
                                <div>
                                  <div class='font-["Semi"] md:line-clamp-1 2xl:text-sm md:text-[13px] text-[#000] text-start'>{item?.Product?.title}</div>
                                </div>
                              </div>

                              <div class='px-3 py-2 text-xs font-["Semi"] text-black border-gainsboro border-solid border-[1px] rounded-full'>
                                Fulfill
                              </div>

                            </div>
                          ))}
                        </div>
                      )
                      }

                    </div>
                    <div class='2xl:grid flex flex-col grid-rows-3 w-full gap-3'>
                      <div class='h-14 rounded-xl w-full border-[1px] flex items-center border-gainsboro border-solid'>
                        <div class='px-4 flex items-center w-full justify-between'>
                          <div class='flex items-center space-x-2'>
                            <div class='bg-royalblue text-xs w-8 flex items-center justify-center rounded-full h-8 text-[#fff] font-["Semi"]'>
                              <ArchiveBoxIcon class='w-4 h-4 text-[#fff]' />
                            </div>
                            <div class='text-start'>
                              <div class='text-black text-sm font-["Semi"]'>Orders</div>
                              <div class='text-gray-400 text-xs mt-[2px] font-["Face"]'>{allData.Payouts.length} orders</div>
                            </div>
                          </div>
                          <div >
                            <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                              <ChevronRightIcon class='text-black w-4 h-4' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='h-14 rounded-xl w-full border-[1px] flex items-center border-gainsboro border-solid'>
                        <div class='px-4 flex items-center w-full justify-between'>   <div class='flex items-center space-x-2'>
                          <div class='bg-royalblue text-xs w-8 flex items-center justify-center rounded-full h-8 text-[#fff] font-["Semi"]'>
                            <ArchiveBoxArrowDownIcon class='w-4 h-4 text-[#fff]' />
                          </div>
                          <div class='text-start'>
                            <div class='text-black text-sm font-["Semi"]'>Fulfilled orders</div>
                            <div class='text-gray-400 text-xs mt-[2px] font-["Face"]'>{fulfilledCount} fulfilled orders</div>
                          </div>
                        </div>
                          <div >
                            <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                              <ChevronRightIcon class='text-black w-4 h-4' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='h-14 rounded-xl w-full border-[1px] flex items-center border-gainsboro border-solid'>
                        <div class='px-4 flex items-center w-full justify-between'>
                          <div class='flex items-center space-x-2'>
                            <div class='bg-royalblue text-xs w-8 flex items-center justify-center rounded-full h-8 text-[#fff] font-["Semi"]'>
                              <ArchiveBoxXMarkIcon class='w-4 h-4 text-[#fff]' />
                            </div>
                            <div class='text-start'>
                              <div class='text-black text-sm font-["Semi"]'>Unfulfilled orders</div>
                              <div class='text-gray-400 text-xs mt-[2px] font-["Face"]'>{unfulfilledCount} unfulfilled orders</div>
                            </div>
                          </div>
                          <div >
                            <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                              <ChevronRightIcon class='text-black w-4 h-4' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </section>

        </main>
      </div>
    </div >
  );
};

export default DataProcessor;
