import React, { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { FaLock } from 'react-icons/fa'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { gql, useMutation } from '@apollo/client'
import { Formik } from 'formik'


export const SUBSCRIBE = gql`
   mutation updatePlan($subscription: String!, $payRate: String!){
        updatePlan(subscription: $subscription, payRate: $payRate){
            id
        }
    }
`

const Free = () => {
    const planpayments = [
        {
            name: 'Monthly',
            price: 6,
            checked: false,
            ref: 'MONTHLY',
            plan_id: 'price_1Pe1lJD7NsuORBccLZxO5SI8'
        },
        {
            name: '6 Months',
            price: 6 * 6 - 10,
            save: 'Save $10',
            checked: false,
            ref: '6MONTHS',
            plan_id: 'price_1Pe1o6D7NsuORBccM0vF79o5'
        },
        {
            name: 'Yearly',
            price: 6 * 12 - 20,
            save: 'Save $20',
            checked: false,
            ref: 'YEARLY',
            plan_id: 'price_1Pe1p7D7NsuORBccUQPUR1dy'
        }
    ]

    const handleSubscription = async () => {
        await subscribing({
            variables: { subscription: "PLUS", payRate: selected.ref },
        })
    }

    const [success, setSuccess] = React.useState(false)
    const stripe = useStripe()
    const elements = useElements()
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
            console.log("Stripe.js has not loaded yet.");
            return;
        }
    
        const cardElement = elements.getElement(CardElement);
    
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
    
            if (error) {
                console.log("Stripe error:", error.message);
                return;
            }
    
            const { id } = paymentMethod;
            console.log("Payment Method ID:", id);
    
            const response = await fetch("https://server-kyt.herokuapp.com/subscribe", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: (selected.price * 100).toString(), // Ensure amount is in cents
                    email: email,
                    plan_id: selected.plan_id,
                    id,
                }),
            });
    
            if (!response.ok) {
                const errorMessage = `HTTP error! Status: ${response.status}`;
                console.log(errorMessage);
                throw new Error(errorMessage);
            }
    
            const responseData = await response.json();
            console.log("Server Response Data:", responseData);
    
            if (responseData.subscription) {
                console.log("Successfully Subscribed");
                setSuccess(true);
                handleSubscription(); // Call handleSubscription after successful subscription
            } else {
                console.log("Subscription failed:", responseData.message);
            }
    
        } catch (error) {
            console.log("Error:", error);
        }
    };
    const [selected, setSelected] = useState(planpayments[0])
    const [subscribing] = useMutation(SUBSCRIBE)
    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#000",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "#fce883" },
                "::placeholder": { color: "#87bbfd" }
            },
            invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee"
            }
        }
    }

    const [email, setEmail] = useState("");

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const checkedPlan = planpayments.find(plan => plan.checked)
    return (
        <div className="flex gap-10 text-black h-screen justify-center items-center bg-royalblue mx-auto w-full">
            <form>

                <div className="max-w-screen-sm mx-auto bg-white rounded-xl p-10 px-16 rounded-xl w-full">
                    <div className="text-black font-['Bold'] text-3xl">Plus Plan</div>
                    <RadioGroup value={selected} onChange={setSelected} aria-label="Payment plan" className="space-y-2 mt-5 w-full">
                        {planpayments.map((plan, idx) => (
                            <RadioGroup.Option key={idx} value={plan} className="self-stretch">
                                {({ checked }) => (
                                    <div
                                        className={`p-5 rounded-xl  flex items-center justify-between self-stretch border-gainsboro  ${selected.name === plan.name ? 'bg-royalblue/[.10] border-solid border-royalblue border-[1px]' : 'border-solid border border-[1px]'
                                            }`}
                                    >
                                        <div className="flex items-center gap-4">
                                            <div className={`w-4 h-4 rounded-full  ${selected.name === plan.name ? 'bg-royalblue flex items-center justify-center' : 'border-solid border-[1px] border-gainsboro'}`}>
                                                {selected.name === plan.name && (<div class='w-1 h-1 border-gainsboro border-[1px] border-solid bg-[#fff] rounded-full'></div>)}
                                            </div>
                                            <div>
                                                <div className="font-['Semi'] mb-[2px] text-sm">{plan.name}</div>
                                                <div className="text-xs font-['Face'] text-gray-500">${plan.price} / Member</div>
                                            </div>
                                        </div>
                                        <div>
                                            {plan.save && (
                                                <div className="bg-royalblue px-3 py-2 text-xs text-white font-['Semi'] rounded-lg">
                                                    {plan.save}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>

                    <div >
                        <div class='grid grid-cols-2 gap-4 mt-4'>
                            <div class='flex flex-col mt-3'>
                                <div class='text-sm mb-2.5 font-["Semi"]'>Billed to</div>
                                <input
                                    className="self-stretch font-['Face'] focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                    placeholder="Account name"
                                />
                            </div>
                            <div class='flex flex-col mt-3'>
                                <div class='text-sm mb-2.5 font-["Semi"]'>Email</div>
                                <input
                                    id='email'
                                    value={email}
                                    onChange={handleEmailChange}
                                    className="self-stretch font-['Face'] focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                    placeholder="Email Address"
                                />
                            </div>
                        </div>
                        <div class='w-full flex flex-col mt-3 mb-8'>
                            <div class='text-sm mb-2.5 font-["Semi"]'>Card info</div>
                            <div class="self-stretch font-['Face'] focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4">
                                <CardElement options={CARD_OPTIONS} />
                            </div>
                        </div>
                    </div>

                    <div class='my-4 rounded-full w-full bg-gainsboro h-[1px]' />
                    <div class='self-stretch justify-between px-4 flex items-center'>
                        <div class='text-3xl font-["Bold"] text-black'>Total</div>
                        <div class='text-3xl text-black font-["Bold"]'>${selected.price} / {selected.name}</div>
                    </div>
                    <div class='mt-2 flex w-full mt-3 px-5 gap-3'>
                        <FaLock class='w-5 text-gray-300 h-5' />
                        <div class='text-gray-300 text-sm font-["Face"]'>Guaranteed to be safe & secure, ensuring that all transactions are protected with the highest level of security.</div>
                    </div>
                    <div class='flex items-center gap-5 mt-5'>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            class='bg-royalblue text-white font-["Semi"] w-full outline-0 py-5 justify-center items-center rounded-full text-sm border-0'>Subscribe</button>
                        <a href='/dashboard' class='py-5 bg-gray-100 no-underline text-black text-sm px-7 rounded-full font-["Semi"]'>Cancel</a>
                    </div>

                </div>

            </form>
        </div>
    )
}

export default Free
