import React from 'react'
import { SparklesIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Tab } from '@headlessui/react'
import { ME_QUERY } from '../Data/Me'
import { useQuery } from '@apollo/client'

export const Subscribe = ({ header, tab }) => {
    const plans = [
        {
            name: 'Creatorzhub Lite',
            iconColor: 'text-red-500',
            bgColor: 'bg-red-200',
            description:
                'Perfect for creators or brands starting out and looking for a compact yet powerful selling platform.',
            icon: "l",
            price: 'Free',
            ref: 'FREE',
            features: ['Sell merch, ebooks, downloads, etc..', 'Keep all your links together', 'Customizable storefront', 'Email list creation', 'Donations']
        },
        {
            name: 'Creatorzhub Plus',
            iconColor: 'text-purple-500',
            bgColor: 'bg-purple-200',
            description:
                'Tailored for influencers or brands with a burgeoning following seeking advanced features and solutions.',
            icon: "ll",
            price: 6,
            href: '/subscribe/toPlus',
            ref: 'PLUS',
            features: ['Everyting in free', '0 transaction fees', 'No CreatorzShop watermark', 'Embedded links', 'Partial template access']
        },
        {
            name: 'Creatorzhub Elite',
            iconColor: 'text-yellow-500',
            bgColor: 'bg-yellow-200',
            description:
                'Designed for top-tier influencers or renowned brands looking for effortless product selling solutions.',
            icon: "lll",
            price: 15,
            ref: 'ELITE',
            href: '/subscribe/toElite',
            features: ['Everyting in plus', 'Custom checkout', 'Access to all Templates', 'Email subs']
        },
        {
            name: 'Creatorzhub Blue',
            iconColor: 'text-yellow-500',
            bgColor: 'bg-yellow-200',
            description:
                'Designed for top-tier influencers or renowned brands looking for effortless product selling solutions.',
            icon: "lll1",
            ref: 'BLUE',
            price: 30,
            href: '/subscribe/toBlue',
            features: ['Everyting in plus', 'Custom checkout', 'Access to all Templates', 'Email subs']
        },

    ]

    const { data, error, loading } = useQuery(ME_QUERY)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const List = [
        'Monthly',
        '6 Months',
        'Yearly'
    ]

    if (error) return <div>{error.message}</div>
    if (loading) return <div>Loading...</div>


    return (
        <div>



            <div class='2xl:mx-auto mx-7 2xl:max-w-screen-xl h-5/6 overflow-y-hidden'>
                <div class='2xl:mx-10 xl:mx-7'>
                    <div class='flex-col flex items-center justify-center'>
                        <div class='mb-2 rounded-full inline-flex gap-2 px-4 py-2'>
                            <SparklesIcon class='w-4 text-royalblue' />
                            <div class='text-royalblue font-["Semi"]'>Pay for single features</div>
                        </div>
                        <div className="font-['Bold'] text-center mt-2 text-black 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-xl text-xl">
                            A single tool for creators and brands. <br class='hidden 2xl:flex xl:flex md:flex' />
                            To elevate there digital presence.

                        </div>
                  
                        <div class='flex items-center justify-center w-full flex-row gap-5 mt-4'>
                            <div class='flex flex-row items-center gap-2'>
                                <div class='w-6 h-6 rounded-full bg-royalblue flex items-center justify-center'>
                                    <CheckIcon class='w-3 h-3 text-[#fff]' />

                                </div>
                                <div class='font-["Semi"] text-sm'>Effectient</div>

                            </div>
                            <div class='flex flex-row items-center gap-2'>
                                <div class='w-6 h-6 rounded-full bg-royalblue flex items-center justify-center'>
                                    <CheckIcon class='w-3 h-3 text-[#fff]' />

                                </div>
                                <div class='font-["Semi"] text-sm'>Versatile</div>

                            </div>
                            <div class='flex items-center gap-2'>
                                <div class='w-6 h-6 rounded-full bg-royalblue flex items-center justify-center'>
                                    <CheckIcon class='w-3 h-3 text-[#fff]' />

                                </div>
                                <div class='font-["Semi"] text-sm'>Affrodable</div>
                            </div>
                        </div>
                    </div>
                    <div class='mt-7 text-black'>
                        <Tab.Group>
                            <div class='flex justify-center items-center'>
                                <Tab.List class='items-center bg-royalblue rounded-full inline-flex  px-2 space-x-3 py-2 flex-nowrap '>
                                    {List.map(item => (
                                        <Tab className={({ selected }) =>
                                            classNames(
                                                'p-3 px-4 outline-0 text-[#fff]  rounded-full border-0',
                                                selected
                                                    ? 'text-royalblue bg-[#fff] border-0'
                                                    : 'bg-royalblue'
                                            )
                                        } style={{ fontFamily: 'Semi' }}>
                                            {item}
                                        </Tab>
                                    ))}
                                </Tab.List>
                            </div>
                            <Tab.Panel>
                                <div class='grid 2xl:grid-cols-4 xl:grid-cols-4 w-full md:grid-cols-2 lg:grid-cols-3 2xl:mx-[0px] mt-5 2xl:gap-5 gap-10'>
                                    {plans.map(item => (
                                        <div class={`p-10 flex items-center justify-center flex-col border-gainsboro border-[1px] border-solid rounded-xl`}>

                                            <div class='text-xl font-["Bold"]'>{item.name}</div>
                                            <div class='font-["Normal"] text-center text-sm mt-2'>{item.description}</div>
                                            <div class='mt-3 text-3xl font-["Bold"]'>{item.price === 'Free' ? 'Free' : `$${item.price}`}</div>
                                            {item.ref === data?.me?.subscription ? (
                                                <div href={item.href} class={`w-full text-sm flex no-underline justify-center items-center py-3 my-3 rounded-lg border-solid border-gainsboro border-[1px] text-black font-["Semi"]`}>Subscribed</div>

                                            ) : (
                                                <a href={item.href} class={`w-full text-sm flex no-underline justify-center items-center py-3 my-3 rounded-lg bg-royalblue text-white font-["Semi"]`}>Subscribe</a>

                                            )}
                                            <div class='space-y-3 mt-3'>
                                                {item.features.map(item => (
                                                    <div class='flex items-center text-sm font-["Face"] gap-3'><CheckIcon class='w-4 text-black h-4' />{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                            <div class='grid 2xl:grid-cols-4 xl:grid-cols-4 w-full md:grid-cols-2 lg:grid-cols-3 2xl:mx-[0px] mt-5 2xl:gap-5 gap-10'>
                                    {plans.map(item => (
                                        <div class={`p-10 flex items-center justify-center flex-col border-gainsboro border-[1px] border-solid rounded-xl`}>

                                            <div class='text-xl font-["Bold"]'>{item.name}</div>
                                            <div class='font-["Normal"] text-center text-sm mt-2'>{item.description}</div>
                                            <div class='mt-3 text-3xl font-["Bold"]'>{item.price === 'Free' ? 'Free' : `$${item.price * 6 - 10}`}</div>
                                            {item.ref === data?.me?.subscription ? (
                                                <div href={item.href} class={`w-full flex text-sm no-underline justify-center items-center py-3 my-3 rounded-lg border-solid border-gainsboro border-[1px] text-black font-["Semi"]`}>Subscribed</div>

                                            ) : (
                                                <a href={item.href} class={`w-full flex text-sm no-underline justify-center items-center py-3 my-3 rounded-lg bg-royalblue text-white font-["Semi"]`}>Subscribe</a>

                                            )}                                            <div class='space-y-3 mt-3'>
                                                {item.features.map(item => (
                                                    <div class='flex items-center text-sm font-["Face"] gap-3'><CheckIcon class='w-4 text-black h-4' />{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Panel>

                            <Tab.Panel>
                            <div class='grid 2xl:grid-cols-4 xl:grid-cols-4 w-full md:grid-cols-2 lg:grid-cols-3 2xl:mx-[0px] mt-5 2xl:gap-5 gap-10'>
                                    {plans.map(item => (
                                        <div class={`p-10 flex items-center justify-center flex-col border-gainsboro border-[1px] border-solid rounded-xl`}>

                                            <div class='text-xl font-["Bold"]'>{item.name}</div>
                                            <div class='font-["Normal"] text-center text-sm mt-2'>{item.description}</div>
                                            <div class='mt-3 text-3xl font-["Bold"]'>{item.price === 'Free' ? 'Free' : `$${item.price * 12 - 20}`}</div>
                                            {item.ref === data?.me?.subscription ? (
                                                <div href={item.href} class={`w-full flex no-underline text-sm justify-center items-center py-3 my-3 rounded-lg border-solid border-gainsboro border-[1px] text-black font-["Semi"]`}>Subscribed</div>

                                            ) : (
                                                <a href={item.href} class={`w-full flex no-underline text-sm justify-center items-center py-3 my-3 rounded-lg bg-royalblue text-white font-["Semi"]`}>Subscribe</a>

                                            )}                                            <div class='space-y-3 mt-3'>
                                                {item.features.map(item => (
                                                    <div class='flex items-center text-sm font-["Face"] gap-3'><CheckIcon class='w-4 text-black h-4' />{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Panel>
                        </Tab.Group>

                    </div>
                </div>
            </div>




        </div>
    )
}

export default Subscribe