import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ME_QUERY } from "../Data/Me";

const FULFILL = gql`
  mutation updateLink($id: Int!, $link: String!, $linkText: String) {
    updateLink(id: $id, link: $link, linkText: $linkText) {
      id
    }
  }
`;

const DELETE = gql`
  mutation deleteLink($id: Int!) {
    deleteLink(id: $id) {
      id
    }
  }
`;

const LinkUpdate = ({ item, isOpen, onClose }) => {
  const [fulfill] = useMutation(FULFILL, { refetchQueries: [ME_QUERY] });
  const [deleteLink] = useMutation(DELETE, { refetchQueries: [ME_QUERY] });

  const [formData, setFormData] = useState({
    link: item?.link || '',
    linkText: item?.linkText || ''
  });

  useEffect(() => {
    setFormData({
      link: item?.link || '',
      linkText: item?.linkText || ''
    });
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDelete = async () => {
    try {
      const { data } = await deleteLink({
        variables: { id: item?.id },
      });
      console.log(data);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFulfillment = async () => {
    try {
      const { data } = await fulfill({
        variables: {
          id: item?.id,
          link: formData.link,
          linkText: formData.linkText,
        },
      });
      console.log(data);
      onClose(); // Close the modal after successful update
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl shadow-part transform overflow-hidden rounded-2xl border-black border-2 bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isOpen && item && (
                  <div>
                    <div onClick={onClose} className="absolute cursor-pointer top-6 right-5">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    </div>
                    <div className="flex space-x-5">
                      <div className="w-1/4">
                        <img className="rounded-2xl w-full" src={item.image} alt="" />
                      </div>
                      <div className="px-5 w-3/4">
                        <div className="text-xl font-bold mb-3">Edit Link</div>
                        <div className="flex space-x-5 w-full">
                          <div className="w-1/2">
                            <div className="text-sm mb-2 ml-4 font-semibold">Link</div>
                            <input
                              name="link"
                              value={formData.link}
                              onChange={handleChange}
                              className="w-full outline-0 font-normal text-sm py-3 px-5 rounded-full border-2 border-black shadow-part"
                            />
                          </div>
                          <div className="w-1/2">
                            <div className="text-sm mb-2 ml-4 font-semibold">Link Text</div>
                            <textarea
                              onChange={handleChange}
                              name="linkText"
                              value={formData.linkText}
                              className="w-full outline-0 font-normal text-sm py-2 px-5 rounded-2xl border-2 border-black shadow-part"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4 mt-10">
                      <button
                        style={{ boxShadow: '5px 5px 0px #ef4444' }}
                        onClick={handleDelete}
                        className="w-1/2 py-4 rounded-xl border-2 border-red-500 text-red-500 font-semibold"
                      >
                        Delete Link
                      </button>
                      <button
                        onClick={handleFulfillment}
                        className="w-1/2 py-4 rounded-xl shadow-part border-2 border-black font-semibold"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LinkUpdate;
