import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useMutation } from '@apollo/client'
import { gql } from 'graphql-tag'
import mail from '../Assets/mail-03.png'
import tags from '../Assets/tag-03black.png'
const FULFILL = gql`
  mutation fulfillProduct($id: Int) {
    fulfillProduct(id: $id) {
      id
    }
  }
`;
const Fulfillment = ({ item, isOpen, onClose, }) => {

    const [fulfill] = useMutation(FULFILL)

    const handleFulfillment = async (e) => {

        try {
            const { data } = await fulfill({
                variables: {
                    id: item.id
                },
            });

            // Handle the response data here if needed
            console.log(data);
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={`w-full ${item?.Product?.isPhysical === true ? 'max-w-6xl' : 'max-w-3xl'} transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                                {isOpen && item !== null && (
                                    <div>
                                        <div onClick={onClose} class='absolute cursor-pointer top-6 right-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>

                                        </div>
                                        <div class='text-xl font-["Bold"] mb-5'>Order: {item.Product.title}</div>
                                        <div class='flex space-x-5'>
                                            <div class='w-1/3'>
                                                <img class='rounded-2xl h-full' src={item.Product.thumbnail} />
                                            </div>
                                            <div class='px-5 w-1/3'>
                                                <div class='flex space-x-10'>
                                                    <div class='flex flex-col'>
                                                        <label class='font-["Bold"] text-sm mb-3'>Status</label>
                                                        <div class={`font-["Semi"] text-sm px-4 py-1 max-w-40 w-full rounded-full ${item.fulfilled === true ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'}`}>


                                                            {item.fulfilled === true ? (
                                                                'Fulfilled'
                                                            ) : (
                                                                'Unfulfilled'
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class='text-sm mb-3 font-["Bold"]'>
                                                            Paid for
                                                        </div>
                                                        <div class='text-sm font-["Face"]'>
                                                            Amount Paid: ${item.amount}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class='flex flex-col mt-3'>
                                                    <label class='font-["Bold"] text-sm mb-2'>Customer</label>
                                                    <div class='font-["Semi"] text-sm flex items-center gap-2 mb-2'> <img src={tags} class='w-4 h-4' /> {!item.User ? item.name : item.User.name}</div>
                                                    <div class='font-["Semi"] flex items-center gap-2 text-sm'><img src={mail} class='w-4 h-4' />  {!item.User ? item.email : item.User.email}</div>

                                                </div>

                                                <div class='flex flex-col mt-3'>
                                                    <label class='font-["Bold"] text-sm mb-2 space-y-2'>Product</label>
                                                    <div class='flex items-center rounded-3xl overflow-hidden border'>
                                                        <img class='w-20' src={item.Product.thumbnail} />
                                                        <div class='px-5'>
                                                            <div class='text-md' style={{ fontFamily: 'Bold' }}>{item.Product.title}</div>
                                                            <div class='text-sm line-clamp-1 text-gray-300'>{item.Product.description}</div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            {item.Product.isPhysical === true && (
                                                <div class='w-1/3 px-5'>
                                                    <div class='text-xl font-["Bold"]'>Shipping Details</div>
                                                    <div class='mt-4'>
                                                        <div class='flex items-center justify-between'>
                                                            <div>
                                                                <label class='font-["Bold"] text-sm mb-2'>Shipping address</label>
                                                                <div class='text-sm gap-2 font-["Face"] mb-2'>
                                                                    {item.Shipping.address}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label class='font-["Bold"] text-sm mb-2'>Zip code</label>
                                                                <div class='text-sm gap-2 font-["Face"] mb-2'>
                                                                    {item.Shipping.zipCode}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='flex items-center justify-between'>
                                                            <div>
                                                                <label class='font-["Bold"] text-sm mb-2'>Country</label>
                                                                <div class='text-sm gap-2 font-["Face"]'>
                                                                    {item.Shipping.country}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label class='font-["Bold"] text-sm mb-2'>State</label>
                                                                <div class='text-sm gap-2 font-["Face"]'>
                                                                    {item.Shipping.state}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label class='font-["Bold"] text-sm mb-2'>City</label>
                                                                <div class='text-sm gap-2 font-["Face"]'>
                                                                    {item.Shipping.city}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div class='flex items-center space-x-4 mt-10'>
                                            {item.fulfilled === true ? (
                                                <button class='w-1/2 py-4 rounded-xl bg-black text-white font-["Semi"]'>You've Fulfilled</button>
                                            ) : (
                                                <button onClick={handleFulfillment} class='w-1/2 py-4 rounded-xl bg-black text-white font-["Semi"]'>Marked as fulfilled</button>
                                            )}

                                            <button onClick={onClose} class='w-1/2 py-4 rounded-xl border shadow font-["Semi"]'>Cancel</button>

                                        </div>
                                    </div>
                                )}

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Fulfillment