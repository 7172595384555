import React, { useEffect, useRef, useState } from 'react'
import { ME_QUERY } from '../../Data/Me'
import { useMutation, useQuery, gql } from '@apollo/client'
import { TwitterPicker } from 'react-color'
import { Switch, Listbox, Tab } from '@headlessui/react'
import { createClient } from '@supabase/supabase-js'
import { FaFacebook, FaInstagram, FaTiktok, FaXTwitter } from 'react-icons/fa'
import { BsFacebook, BsInstagram, BsTwitterX } from 'react-icons/bs'
import { RiTiktokLine } from 'react-icons/ri'
import { useFormik } from 'formik'
import ColorEditableText from '../Duo'
import { UPDATE_FEATURED_POST } from '../Template1'
import dots from '../../Assets/dots.png'
import { TbBrandTiktok } from 'react-icons/tb'
import { FaFacebookF } from 'react-icons/fa6'

const Update = gql`
mutation updateStorefront(
  $id: Int!,
  $backgroundColor: String,
  $productsSection: Boolean!,
  $newsletterSection: Boolean!,
  $storefrontIsPasscode: Boolean!,
  $storefrontPass: String,
  $font: String,
  $componentColor: String,
  $embeddedLink: String,
  $headingColor: String,
  $subTextColor: String,
  $featuredSection: Boolean!,
  $socialsSection: Boolean!,
  $actionButton: String,
  $actionButtonText: String,
  $newsletterSubText: String,
  $newsletterHeading: String,
  $layoutVertical: Boolean!,
  $instagramShown: Boolean!,
  $linksSection: Boolean!,
  $newsletterImage: String,
  $tiktokShown: Boolean!,
  $facebookShown: Boolean!,
  $twitterShown: Boolean!,
  $instagram: String,
  $facebook: String,
  $tiktok: String,
  $twitter: String,
  $subscribeText: String,
  $description: String,
  $subscribeSubText: String,
  $headerImage: String,
  $secondaryImage: String,
  $subText: String,
  $basic: Boolean!,
  $simple: Boolean!,
  $image: Boolean!,
  $log: Boolean!,
  $log2: Boolean!,
  $template: Int
) {
  updateStorefront(
    id: $id,
    backgroundColor: $backgroundColor,
    productsSection: $productsSection,
    newsletterImage: $newsletterImage,
    newsletterSection: $newsletterSection,
    componentColor: $componentColor,
    font: $font,
    description: $description,
    headingColor: $headingColor,
    linksSection: $linksSection,
    subTextColor: $subTextColor,
    storefrontIsPasscode: $storefrontIsPasscode,
    storefrontPass: $storefrontPass,
    featuredSection: $featuredSection,
    socialsSection: $socialsSection,
    actionButton: $actionButton,
    actionButtonText: $actionButtonText,
    embeddedLink: $embeddedLink,
    newsletterSubText: $newsletterSubText,
    newsletterHeading: $newsletterHeading,
    layoutVertical: $layoutVertical,
    instagramShown: $instagramShown,
    tiktokShown: $tiktokShown,
    facebookShown: $facebookShown,
    twitterShown: $twitterShown,
    instagram: $instagram,
    facebook: $facebook,
    tiktok: $tiktok,
    twitter: $twitter,
    subscribeText: $subscribeText,
    subscribeSubText: $subscribeSubText,
    headerImage: $headerImage,
    secondaryImage: $secondaryImage,
    subText: $subText,
    basic: $basic,
    simple: $simple,
    image: $image,
    log: $log,
    log2: $log2,
    template: $template
  ) {
    id
  }
}
`

const EditTemplate = () => {
  const { data, error, loading } = useQuery(ME_QUERY)
  const [link, setLink] = useState('');
  const [submittedLink, setSubmittedLink] = useState('');
  const renderEmbeddedContent = () => {
    if (!submittedLink) return null;

    // YouTube Video
    if (submittedLink.includes('youtube.com') || submittedLink.includes('youtu.be')) {
      const videoId = submittedLink.split('v=')[1] || submittedLink.split('/').pop();
      const embedLink = `https://www.youtube.com/embed/${videoId}`;
      return <iframe src={embedLink} title="YouTube Video" class='rounded-xl' frameBorder="0" allowFullScreen style={{ width: '100%' }}></iframe>;
    }

    // TikTok Video
    if (submittedLink.includes('tiktok.com')) {
      return (
        <blockquote className="tiktok-embed" cite={submittedLink} data-video-id={submittedLink.split('/video/')[1]} style={{ width: '100%', height: 'auto' }}>
          <iframe src={submittedLink} frameBorder="0" allowFullScreen title="TikTok Video" style={{ width: '100%', height: '400px' }}></iframe>
        </blockquote>
      );
    }

    // Twitch Stream or Video
    if (submittedLink.includes('twitch.tv')) {
      const twitchEmbedLink = `https://player.twitch.tv/?channel=${submittedLink.split('twitch.tv/')[1]}&parent=yourdomain.com`;
      return <iframe src={twitchEmbedLink} frameBorder="0" allowFullScreen scrolling="no" height="400" width="100%" title="Twitch Video"></iframe>;
    }

    // For Images
    if (submittedLink.match(/\.(jpeg|jpg|gif|png)$/)) {
      return <img src={submittedLink} alt="User submitted content" style={{ maxWidth: '100%', height: 'auto' }} />;
    }

    // Default to showing as a link
    return <a href={submittedLink} target="_blank" rel="noopener noreferrer">{submittedLink}</a>;
  };

  const EmbeddedDialog = () => {
    const handleSubmit = (e) => {
      e.preventDefault();
      setSubmittedLink(link);
    };


    return (
      <div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className='self-stretch border-gainsboro border-[1px] font-["Face"] border-solid px-5 py-3 rounded-lg'
            placeholder="Enter your link here"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            style={{ width: '300px', marginRight: '10px' }}
          />
          <button type="submit" class='font-["Semi"] border-0 outline-0 bg-royalblue px-4 py-2 rounded-xl text-white'>Submit</button>
        </form>

        <div style={{ marginTop: '20px' }}>
          {renderEmbeddedContent()}
        </div>
      </div>
    );
  }

  const SectionDialog = () => {

    return (
      <div class='p-5 bg-white justify-center items-center flex flex-col relative rounded-xl'>
        <div class='flex flex-col gap-5 w-48 mb-5'>

          <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
            <div style={{ fontFamily: 'Bold' }}>Newsletter</div>
            <div>
              <Switch
                checked={sections.newsletter}
                onChange={() => setSections({ ...sections, newsletter: !sections.newsletter })}
                className={`${sections.newsletter ? 'bg-white' : 'bg-black'
                  }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${sections.newsletter ? 'translate-x-5' : 'translate-x-0'
                    }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                />
              </Switch>
            </div>
          </div>
          <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
            <div style={{ fontFamily: 'Bold' }}>Featured</div>
            <div>
              <Switch
                checked={sections.featured}
                onChange={() => setSections({ ...sections, featured: !sections.featured })}
                className={`${sections.featured ? 'bg-white' : 'bg-black'
                  }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${sections.featured ? 'translate-x-5' : 'translate-x-0'
                    }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                />
              </Switch>
            </div>
          </div>

          <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
            <div style={{ fontFamily: 'Bold' }}>Products</div>
            <div>
              <Switch
                checked={sections.products}
                onChange={() => setSections({ ...sections, products: !sections.products })}
                className={`${sections.products ? 'bg-white' : 'bg-black'
                  }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${sections.products ? 'translate-x-5' : 'translate-x-0'
                    }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                />
              </Switch>
            </div>
          </div>
          <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
            <div style={{ fontFamily: 'Bold' }}>Social</div>
            <div>
              <Switch
                checked={sections.socials}
                onChange={() => setSections({ ...sections, socials: !sections.socials })}
                className={`${sections.socials ? 'bg-white' : 'bg-black'
                  }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${sections.socials ? 'translate-x-5' : 'translate-x-0'
                    }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                />
              </Switch>
            </div>
          </div>
          <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
            <div style={{ fontFamily: 'Bold' }}>Links</div>
            <div>
              <Switch
                checked={sections.links}
                onChange={() => setSections({ ...sections, links: !sections.links })}
                className={`${sections.links ? 'bg-white' : 'bg-black'
                  }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${sections.links ? 'translate-x-5' : 'translate-x-0'
                    }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                />
              </Switch>
            </div>
          </div>
        </div>


      </div>
    )
  }
  const handleFormat = (clickedFormat) => {
    setProductsFormat({
      basic: clickedFormat === 'basic',
      simple: clickedFormat === 'simple',
      image: clickedFormat === 'image',
      log: clickedFormat === 'log',
      log2: clickedFormat === 'log2'
    });
  };

  const [layoutVertical, setLayoutVertical] = useState(true)

  const BackgroundDialog = () => {
    return (
      <div class='w-full'>
        <div class='flex flex-row p-3 border-[1px] rounded-full border-gray-100 border-solid items-center gap-5'>

          <div onClick={() => handleBackground('background')} class={`w-full ${background.background === true ? 'bg-royalblue text-[#ffffff]' : 'text-royalblue'} text-center rounded-full p-5`}>
            <div class='text-sm font-["Semi"]'>Background</div>

          </div>
          <div onClick={() => handleBackground('backgroundImage')} class={`w-full ${background.backgroundImage === true ? 'bg-royalblue text-[#ffffff]' : 'text-royalblue'} text-center rounded-full p-5`}>
            <div class='text-sm font-["Semi"]'>Background Image</div>
          </div>
        </div>

        {background.background === true && (
          <div class='p-5'>
            <div class='font-["Semi"]'>Background Color</div>
            <div class='my-3 inline-block w-52 rounded-xl gap-2'>
              <div class='flex items-center gap-5'>
                <div class='p-5 border-solid border-gainsboro border-[1px] rounded-lg shrink-0' onClick={() => setActiveColorPicker('backgroundColor')} style={{ backgroundColor: colorPickers.backgroundColor }} >

                </div>

                <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                  {colorPickers.backgroundColor}
                </div>
              </div>
            </div>

          </div>
        )}
        {activeColorPicker && (
          <div ref={colorPickerRef} className="bg-royalblue p-5 mx-5 gap-3 flex flex-col inline-block rounded-xl">
            <div>
              <FormattedStringComponent inputString={activeColorPicker} />
            </div>
            <div>
              <TwitterPicker
                styles={{
                  default: {
                    card: {
                      boxShadow: 'none',
                      borderRadius: '10px'
                    }
                  }
                }}
                triangle='false'
                color={colorPickers[activeColorPicker]}
                onChange={newColor => handleColorChange(activeColorPicker, newColor)}
              />
            </div>
          </div>
        )}
      </div>
    )
  }


  const SocialsDialog = () => {
    return (
      <div class='px-5 bg-white relative rounded-xl'>

        <div class='grid grid-cols-2 gap-5'>
          <div class='p-5 rounded-xl self-stretch   bg-royalblue text-white'>
            <div class='flex items-center justify-between my-3'>
              <div class='text-sm' style={{ fontFamily: 'Bold' }}>Instagram</div>
              <div>
                <Switch
                  checked={instagramShown}
                  onChange={setInstagramShown}
                  className={`${instagramShown ? 'bg-white' : 'bg-black'
                    }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                >
                  <span className="sr-only">Enable notifications</span>
                  <span
                    className={`${instagramShown ? 'translate-x-5' : 'translate-x-0'
                      }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                  />
                </Switch>
              </div>

            </div>
            <div class='mb-4 mt-2'>
              {instagramShown === true && (
                <div class='border-0 outline-0 self-stretch'>

                  <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                    <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                      <BsInstagram className="h-5 w-5 text-royalblue" />
                    </div>
                    <input onChange={(event) => handleTextChange('instagram', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                      className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.instagram} placeholder='Instagram link' />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class='p-5 rounded-xl self-stretch bg-royalblue text-white'>
            <div class='flex items-center justify-between my-3'>
              <div class='text-sm' style={{ fontFamily: 'Bold' }}>Facebook</div>
              <div>
                <Switch
                  checked={facebookShown}
                  onChange={setFacebookShown}
                  className={`${facebookShown ? 'bg-white' : 'bg-black'
                    }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                >
                  <span className="sr-only">Enable notifications</span>
                  <span
                    className={`${facebookShown ? 'translate-x-5' : 'translate-x-0'
                      }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                  />
                </Switch>
              </div>
            </div>
            <div class='my-4'>
              {facebookShown === true && (
                <div class='border-0 outline-0 self-stretch'>

                  <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                    <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                      <BsFacebook className="h-5 w-5 text-royalblue" />
                    </div>
                    <input onChange={(event) => handleTextChange('facebook', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                      className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.facebook} placeholder='Facebook link' />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class='p-5 rounded-xl self-stretch  bg-royalblue text-white'>

            <div class='flex items-center justify-between'>
              <div class='text-sm' style={{ fontFamily: 'Bold' }}>Twitter</div>
              <div>
                <Switch
                  checked={twitterShown}
                  onChange={setTwitterShown}
                  className={`${twitterShown ? 'bg-white' : 'bg-black'
                    }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                >
                  <span className="sr-only">Enable notifications</span>
                  <span
                    className={`${twitterShown ? 'translate-x-5' : 'translate-x-0'
                      }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                  />
                </Switch>
              </div>
            </div>
            <div class='my-4'>

              {twitterShown === true && (
                <div class='border-0 outline-0 self-stretch'>

                  <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                    <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                      <BsTwitterX className="h-5 w-5 text-royalblue" />
                    </div>
                    <input onChange={(event) => handleTextChange('twitter', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                      className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.twitter} placeholder='Twitter link' />
                  </div>
                </div>
              )}

            </div>
          </div>
          <div class='p-5 rounded-xl self-stretch bg-royalblue text-white'>

            <div class='flex items-center justify-between '>
              <div class='text-sm' style={{ fontFamily: 'Bold' }}>Tiktok</div>
              <div>
                <Switch
                  checked={tiktokShown}
                  onChange={setTikTokShown}
                  className={`${tiktokShown ? 'bg-white' : 'bg-black'
                    }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                >
                  <span className="sr-only">Enable notifications</span>
                  <span
                    className={`${tiktokShown ? 'translate-x-5' : 'translate-x-0'
                      }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                  />
                </Switch>
              </div>
            </div>
            <div class='my-4'>


              {tiktokShown === true && (
                <div class='border-0 outline-0 self-stretch'>

                  <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                    <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                      <FaTiktok className="h-5 w-5 text-royalblue" />
                    </div>
                    <input onChange={(event) => handleTextChange('tiktok', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                      className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.tiktok} placeholder='Tiktok link' />
                  </div>
                </div>
              )}


            </div>
          </div>
        </div>



      </div>
    )
  }



  const handleChange = (e) => {
    setTextValues({ ...textValues, [e.target.name]: e.target.value });
  };
  const NameDialog = () => {

    return (
      <div class='p-5 bg-white relative flex flex-col justify-center items-center rounded-xl'>
        <div class='mb-5 font-["Bold"] text-center text-2xl'>Name</div>

        <input
          id="subdomain"
          name="subdomain"
          type="text"
          onChange={handleChange}
          value={textValues.subdomain}
          className='self-stretch border-gainsboro border-[1px] font-["Face"] border-solid px-5 py-3 rounded-lg'
          placeholder='Subdomain'
        />
        <div class='font-["Semi"] text-sm mt-2'>
          This is how your link will come out {textValues.subdomain}.creatorzhub.com

        </div>


      </div>
    )
  }

  const [tiktokShown, setTikTokShown] = useState(true)
  const [facebookShown, setFacebookShown] = useState(true)
  const [instagramShown, setInstagramShown] = useState(true)
  const [twitterShown, setTwitterShown] = useState(true)

  const handleTextChange = (field, editedText) => {
    setTextValues((prevState) => ({ ...prevState, [field]: editedText }));
  };

  const [activeColorPicker, setActiveColorPicker] = useState(null);


  const FeaturedDialog = () => {
    const [selectedPostId, setSelectedPostId] = useState(data?.me.featuredPost?.post?.id);
    const postId = parseInt(selectedPostId, 10)
    const [updateFeaturedPost] = useMutation(UPDATE_FEATURED_POST, { refetchQueries: [ME_QUERY] });

    const handleSelectFeaturedPost = async () => {
      if (!selectedPostId) {
        alert('Please select a post to set as featured.');
        return;
      }

      try {
        await updateFeaturedPost({
          variables: {
            userId: data.me.id,
            postId: postId,
          },
        });
        alert('Featured post updated successfully!');
      } catch (error) {
        console.error('Error updating featured post:', error);
        alert('An error occurred while updating the featured post.');
      }
    };
    return (
      <div class='p-5 relative rounded-xl'>
        <div class='mb-5 font-["Bold"] text-center text-2xl'>Featured</div>
        <div class='flex items-center border-gainsboro border-[1px] border-solid p-3 rounded-xl w-full justify-between'>

          <Listbox value={selectedPostId} onChange={setSelectedPostId}>
            {({ open }) => (
              <>
                <Listbox.Button
                  className="relative bg-royalblue border-0 text-[#fff] flex items-center space-x-6 rounded-full shadow-sm inline-block p-3 text-left cursor-default"
                >
                  <span className="block truncate font-['Semi']">
                    {selectedPostId ? data.me.posts.find(post => post.id === selectedPostId).title : 'Select a post'}
                  </span>
                  <span className="inset-y-0 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#fff"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 12a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 1.414-1.414L10 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-3 3A1 1 0 0 1 10 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </Listbox.Button>

                <Listbox.Options
                  className="absolute z-10 mt-20 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {data.me.posts.map((post) => (
                    <Listbox.Option
                      key={post.id}
                      value={post.id}
                      className={({ active }) =>
                        `${active ? 'text-white bg-black' : 'text-gray-900'}
      cursor-default select-none relative py-2 pl-3 pr-9`
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                            {post.title}
                          </span>
                          {selected ? (
                            <span
                              className={`${active ? 'text-white' : 'text-indigo-600'}
            absolute inset-y-0 right-0 flex items-center pr-4`}
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 12a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 1.414-1.414L10 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-3 3A1 1 0 0 1 10 12z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>

          <button onClick={handleSelectFeaturedPost} class='font-["Semi"] text-sm bg-royalblue border-0 px-5 py-2.5 rounded-full text-white'>Set Featured Post</button>
        </div>


      </div>
    )
  }


  const MediaDialog = () => {
    const supabase = createClient(
      'https://rbsteedexxccoqrnyczp.supabase.co',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJic3RlZWRleHhjY29xcm55Y3pwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU5MzIwMTksImV4cCI6MjAwMTUwODAxOX0.FT3oCWVeAGjrMRhLTdCFBSOtIdMDeZj6ApuALkJ185A'
    );

    const inputFile = useRef(null)
    const inputFile2 = useRef(null)
    const inputFile3 = useRef(null)


    const [newsletterUploading, setNewsletterUploading] = useState(false);
    const [newsletterNull, setNewsletter] = useState(null);
    const [newsletterSource, setNewslettername] = useState(null)

    async function uploadNewsletter(event) {
      try {
        setNewsletterUploading(true);

        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.');
        }

        const file = event.target.files[0];
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${fileName}`;
        setNewslettername(fileName);

        // Upload the file to Supabase storage
        let { error: uploadError } = await supabase.storage.from('conten').upload(filePath, file);

        if (uploadError) {
          throw uploadError;
        }

        // Get the public URL of the uploaded file
        let { data } = await supabase.storage.from('conten').getPublicUrl(`${filePath}`);
        const publicFile = data.publicUrl;

        // Update the heroImage with the public URL
        setNewsletter(publicFile);

        // Update the textValues state with the new heroImage
        setTextValues({
          ...textValues,
          newsletterImage: publicFile,
        });

        console.log(data.publicUrl);
      } catch (error) {
        alert(error.message);
      } finally {
        setUploading(false);
      }
    }


    const [secondaryUploading, setSecondaryUploading] = useState(false);
    const [secondaryNull, setSecondary] = useState(null);
    const [secondarySource, setSecondaryname] = useState(null)

    async function uploadSecondary(event) {
      try {
        setSecondaryUploading(true);

        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.');
        }

        const file = event.target.files[0];
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${fileName}`;
        setSecondaryname(fileName);

        // Upload the file to Supabase storage
        let { error: uploadError } = await supabase.storage.from('conten').upload(filePath, file);

        if (uploadError) {
          throw uploadError;
        }

        // Get the public URL of the uploaded file
        let { data } = await supabase.storage.from('conten').getPublicUrl(`${filePath}`);
        const publicFile = data.publicUrl;

        // Update the heroImage with the public URL
        setSecondary(publicFile);

        // Update the textValues state with the new heroImage
        setTextValues({
          ...textValues,
          secondaryImage: publicFile,
        });

        console.log(data.publicUrl);
      } catch (error) {
        alert(error.message);
      } finally {
        setUploading(false);
      }
    }


    const [uploading, setUploading] = useState(false);
    const [publicFile, setFile] = useState(null);
    const [fileSource, setFilename] = useState(null)

    async function uploadFile(event) {
      try {
        setUploading(true);

        // Check if the file is selected
        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.');
        }

        const file = event.target.files[0];
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
        const filePath = `images/${fileName}`; // Added 'images/' for better organization
        setFilename(fileName);

        // Upload the file to Supabase storage
        const { error: uploadError } = await supabase.storage.from('conten').upload(filePath, file);

        if (uploadError) {
          throw uploadError;
        }

        // Get the public URL of the uploaded file
        const { data: publicUrlData, error: publicUrlError } = await supabase.storage
          .from('conten')
          .getPublicUrl(filePath);

        if (publicUrlError) {
          throw publicUrlError;
        }

        const publicFile = publicUrlData.publicUrl;

        // Update the heroImage with the public URL
        setFile(publicFile);

        // Update the textValues state with the new headerImage
        setTextValues((prevTextValues) => ({
          ...prevTextValues,
          headerImage: publicFile,
        }));

        console.log(publicFile);
      } catch (error) {
        alert(error.message);
      } finally {
        setUploading(false);
      }
    }

    return (
      <div>
        <div class='mb-5 font-["Bold"] text-center text-2xl'>Media</div>
        <div class='grid grid-cols-2 gap-3'>
          <div class='flex items-center space-x-3'>
            <div class='border p-5 my-5 w-full bg-royalblue rounded-2xl'>
              <div class='w-full flex items-center justify-between mb-5'>
                <div class='text-lg text-white' style={{ fontFamily: 'Bold' }}>Header Image</div>
                <img
                  className="rounded-full h-12"
                  src={textValues.headerImage || data.me.avatar}
                  alt="Hero"
                  onError={(e) => {
                    e.target.src = data.me.avatar;
                  }}
                />
              </div>


              <label
                style={{ fontFamily: 'Semi' }}
                className='bg-white text-royalblue w-full flex justify-center items-center py-3  cursor-pointer text-sm text-black rounded-lg'
                htmlFor='inputFile'
              >
                Change Header Image
                <input
                  type='file'
                  id='inputFile'
                  onChange={uploadFile}
                  ref={inputFile}
                  accept='image/video*'
                  style={{ display: 'none' }}
                />
              </label>
            </div>
          </div>

          <div class='flex items-center space-x-3'>
            <div class='border p-5 my-5 w-full bg-royalblue rounded-2xl'>
              <div class='w-full flex items-center justify-between mb-5'>
                <div class='text-lg text-white' style={{ fontFamily: 'Bold' }}>Secondary Image</div>
                <img
                  className="rounded-full h-12"
                  src={textValues.secondaryImage || data.me.avatar}
                  alt="Hero"
                  onError={(e) => {
                    e.target.src = data.me.avatar;
                  }}
                />
              </div>


              <label
                style={{ fontFamily: 'Semi' }}
                className='bg-white text-royalblue w-full flex justify-center items-center py-3  cursor-pointer text-sm text-black rounded-lg'
                htmlFor='inputFile2'
              >
                Change Secondary Image
                <input
                  type='file'
                  id='inputFile2'
                  onChange={uploadSecondary}
                  ref={inputFile2}
                  accept='image/video*'
                  style={{ display: 'none' }}
                />
              </label>
            </div>
          </div>


          <div class='flex items-center space-x-3'>
            <div class='border p-5 my-5 w-full bg-royalblue rounded-2xl'>
              <div class='w-full flex items-center justify-between mb-5'>
                <div class='text-lg text-white' style={{ fontFamily: 'Bold' }}>Newsletter Image</div>
                <img
                  className="rounded-full h-12"
                  src={textValues.newsletterImage || data.me.avatar}
                  alt="Hero"
                  onError={(e) => {
                    e.target.src = data.me.avatar;
                  }}
                />
              </div>


              <label
                style={{ fontFamily: 'Semi' }}
                className='bg-white text-royalblue w-full flex justify-center items-center py-3  cursor-pointer text-sm text-black rounded-lg'
                htmlFor='inputFile3'
              >
                Change Newsletter Image
                <input
                  type='file'
                  id='inputFile3'
                  onChange={uploadNewsletter}
                  ref={inputFile3}
                  accept='image/video*'
                  style={{ display: 'none' }}
                />
              </label>
            </div>
          </div>

        </div>


      </div>

    )
  }

  const Basic = ({ item, row }) => {
    return (
      <div style={{ backgroundColor: colorPickers.componentColor }} className={`py-5 px-4 rounded-[28px]`}>
        <div className=" gap-2">
          <div class='w-full flex flex-col'>
            {item.thumbnail ? (
              <img className="rounded-xl w-full" src={item.thumbnail} />

            ) : (
              <div class='w-full h-40 rounded-xl bg-royalblue'></div>
            )}
          </div>
          <div className="justify-start items-start gap-3">

            <div className="flex-col justify-start items-start gap-1.5 my-2 flex">
              <div style={{ color: colorPickers.productHeading }} className="text-black text-sm font-normal font-['Semi'] leading-none">{item.title}</div>


            </div>
          </div>

          <div style={{ backgroundColor: colorPickers.actionButton, color: colorPickers.actionButtonText }} class='w-full bg-black text-[#fff] text-center rounded-full text-[15px] font-["Semi"] py-2.5'>
            Buy for {item.price}

          </div>
        </div>


      </div>
    )
  }

  const Log = ({ item, row }) => {
    return (
      <div class='flex items-center p-3 rounded-xl self-stretch bg-white'>

        <img class='h-14 w-14 rounded-[15px] mr-3' src={item.thumbnail} />

        <div class='w-full flex flex-col'>
          <div class='text-sm font-["Semi"]'>{item.title}</div>
          <div class={`text-black/opacity-60 ${row === true && 'w-32'} line-clamp-2 text-xs font-["Face"]`}>{item.description}</div>
        </div>
      </div>
    )
  }

  const Log2 = ({ item, row, preview }) => {
    return (
      <div class='flex flex-col p-3 gap-1 rounded-xl self-stretch bg-white'>
        {preview === false && (<img class='h-12 w-12 rounded-[15px]' src={item.thumbnail} />)}
        <div class='text-sm font-["Semi"]'>{item.title}</div>
        <div class={`text-xs font-["Face"] ${row === true && 'w-48 line-clamp-3'} line-clamp-3 text-gray-400 mt-1`}>{item.description}</div>
        <button class='w-full py-2 mt-2 outline-0 font-["Semi"] text-white border-0 bg-black rounded-xl'>Buy</button>
      </div>
    )
  }

  const Simple = ({ item, row }) => {
    return (
      <div style={{ backgroundColor: colorPickers.componentColor }} className={`overflow-hidden rounded-[28px]`}>

        <img className="w-full" src={item.thumbnail} />
        <div class='px-5 pt-3 pb-5'>
          <div className="flex-col justify-start items-start gap-3 flex">
            {textValues.headerImage === "" ? (
              <img className="relative rounded-[10px] w-10 h-10" src={item.author.avatar} />
            ) : (
              <img className="relative rounded-[10px] w-10 h-10" src={textValues.headerImage} />
            )}
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className="flex-col justify-start items-start flex">
                <div style={{ color: colorPickers.productHeading }} className="text-black text-sm font-normal font-['Semi'] leading-none">{item.title}</div>
              </div>
              <div className="justify-start items-center inline-flex">
                <div style={{ color: colorPickers.productSubheading }} className={`text-black/opacity-60 text-xs  ${row === true && 'w-32 line-clamp-3'} line-clamp-3 font-['Face']`}>{item.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Image = ({ item, row }) => {
    return (
      <div style={{ backgroundColor: colorPickers.componentColor }} className={`overflow-hidden rounded-[28px]`}>

        <img className="w-full" src={item.thumbnail} />
        <div class='px-5 pt-3 pb-5'>
          <div className="flex-col justify-start items-start gap-3 flex">
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className="flex-col justify-start items-start flex">
                <div style={{ color: colorPickers.productHeading }} className="text-black text-sm font-normal font-['Semi'] leading-none">{item.title}</div>
              </div>
              <div className="justify-start items-center inline-flex">
                <div style={{ color: colorPickers.productSubheading }} className={`text-black/opacity-60 text-xs font-normal font-['Face'] ${row === true && 'w-32 line-clamp-3'} line-clamp-3`}>{item.description}</div>
              </div>
              <div className='bg-black text-white font-["Semi"] rounded-full text-xs rounded-full px-3 py-1'>
                Buy now
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }





  const [productsFormat, setProductsFormat] = useState({
    basic: data?.me?.Storefront?.basic,
    simple: data?.me?.Storefront?.simple,
    image: data?.me?.Storefront?.image,
    log: data?.me?.Storefront?.log,
    log2: data?.me?.Storefront?.log2
  })

  const [create] = useMutation(Update)

  const handleFormSubmit = (values) => {
    alert(JSON.stringify(values));
    console.log(values);
    create({
      variables: {
        id: data?.me?.Storefront?.id,
        backgroundColor: colorPickers.backgroundColor,
        productsSection: sections.products,
        newsletterSection: sections.newsletter,
        componentColor: colorPickers.componentColor,
        headingColor: colorPickers.headingColor,
        subTextColor: colorPickers.subtextColor,
        featuredSection: sections.featured,
        socialsSection: sections.socials,
        linksSection: sections.links,
        actionButton: textValues.actionButton,
        actionButtonText: textValues.actionButtonText,
        newsletterSubText: textValues.newsletterSubText,
        newsletterHeading: textValues.newsletterHeading,
        layoutVertical: layoutVertical,
        instagramShown: instagramShown,
        tiktokShown: tiktokShown,
        facebookShown: facebookShown,
        font: font,
        twitterShown: twitterShown,
        instagram: textValues.instagram,
        facebook: textValues.facebook,
        tiktok: textValues.tiktok,
        twitter: textValues.twitter,
        subscribeText: textValues.subscribeText,
        subscribeSubText: textValues.subscribeSubText,
        headerImage: textValues.headerImage,
        secondaryImage: textValues.secondaryImage,
        subText: textValues.subText,
        description: textValues.description,
        storefrontPass: textValues.storefrontPassword,
        storefrontIsPasscode: privacy,
        embeddedLink: submittedLink,
        basic: productsFormat.basic,
        simple: productsFormat.simple,
        image: productsFormat.image,
        newsletterImage: textValues.newsletterImage,
        log: productsFormat.log,
        log2: productsFormat.log2,
        template: 1 // or the appropriate template value
      }
    });
  };

  const formik = useFormik({
    initialValues: {

    },
    onSubmit: handleFormSubmit,
  });
  const [textValues, setTextValues] = useState({
    subscribeText: data?.me?.Storefront?.subscribeText,
    subText: data?.me?.name,
    description: data?.me?.Storefront?.description || "Text here",
    headerImage: data?.me?.Storefront?.headerImage,
    secondaryImage: "",
    newsletterImage: data?.me?.Storefront?.newsletterImage,
    storefrontPassword: ""
  });

  const [colorPickers, setColorPickers] = useState({
    backgroundColor: data?.me?.Storefront?.backgroundColor,
    componentColor: data?.me?.Storefront?.componentColor,
    headingColor: data?.me?.Storefront?.headingColor,
    subtextColor: "#777",
    actionButton: "#000",
    actionButtonText: "#FFF",
    newsletterHeading: "#000",
    newsletterSubText: "#777",
    productHeading: "#000",
    productSubheading: "#777",
    productPrice: "#000"
  })




  const [sections, setSections] = useState({
    newsletter: true,
    products: true,
    featured: true,
    socials: true,
    links: true
  })

  const [background, setBackground] = useState({
    background: true,
    backgroundImage: false
  })

  const handleBackground = (clickedSections) => {
    setBackground({
      background: clickedSections === 'background',
      backgroundImage: clickedSections === 'backgroundImage',
    });
  };

  const [style, setStyle] = useState({
    shadow: true,
    outline: true
  })

  const handleStyle = (clickedTab) => {
    setStyle({
      shadow: clickedTab === 'shadow',
      outline: clickedTab === 'outline'
    });
  };

  const [privacy, isPrivacy] = useState(false)

  const Tabs = [
    'Name',
    'Format',
    'Sections',
    'Background',
    'Color',
    'Media',
    'Socials',
    'Newsletter',
    'Embedded Link',
    'Featured',
    'Privacy'
  ]


  const NewsletterDialog = () => {
    return (
      <div>
        <Tab.Group>
          <Tab.List class='w-full space-x-4  flex border-solid border-gainsboro border-[1px] rounded-full p-2 '>

            <Tab className={({ selected }) =>
              classNames(
                'p-5 w-full outline-0 text-[#fff] text-center rounded-full border-0',
                selected
                  ? 'bg-royalblue text-[#fff]'
                  : 'text-royalblue bg-[#fff]'
              )
            } style={{ fontFamily: 'Semi' }}>
              Color
            </Tab>
            <Tab className={({ selected }) =>
              classNames(
                'p-5 w-full outline-0 text-[#fff] text-center rounded-full border-0',
                selected
                  ? 'bg-royalblue text-[#fff]'
                  : 'text-royalblue bg-[#fff]'
              )
            } style={{ fontFamily: 'Semi' }}>
              Component
            </Tab>
          </Tab.List>
          <Tab.Panel>

            <div class='grid grid-cols-2 gap-5 mt-5 p-3 w-full'>
              <div class='w-full'>
                <div class='text-2xl font-["Bold"]'>Header Text</div>
                <div class='my-6 inline-block w-full gap-2'>
                  <div class='flex p-3 items-center self-stretch rounded-xl bg-royalblue gap-5'>
                    <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('newsletterHeading')} style={{ backgroundColor: colorPickers.newsletterHeading }} >

                    </div>

                    <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                      {colorPickers.newsletterHeading}
                    </div>
                  </div>
                </div>
              </div>
              <div class='w-full'>
                <div class='text-2xl font-["Bold"]'>Sub Text</div>
                <div class='my-6 inline-block w-full gap-2'>
                  <div class='flex p-3 items-center self-stretch rounded-xl bg-royalblue gap-5'>
                    <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('newsletterSubText')} style={{ backgroundColor: colorPickers.newsletterSubText }} >

                    </div>

                    <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                      {colorPickers.newsletterSubText}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>

          </Tab.Panel>
        </Tab.Group>
        {activeColorPicker && (
          <div ref={colorPickerRef} className="bg-royalblue p-5 mx-5 gap-3 inline-block rounded-xl">
            <div class='mb-3'>
              <FormattedStringComponent inputString={activeColorPicker} />
            </div>
            <div>
              <TwitterPicker
                styles={{
                  default: {
                    card: {
                      boxShadow: 'none',
                      borderRadius: '10px'
                    }
                  }
                }}
                triangle='false'
                color={colorPickers[activeColorPicker]}
                onChange={newColor => handleColorChange(activeColorPicker, newColor)}
              />
            </div>
          </div>
        )}
      </div>
    )
  }



  const [font, setFont] = useState("Normal")

  useEffect(() => {
    // Check for errors or loading state
    if (!loading && !error && data?.me) {
      setTextValues({
        subscribeSubText: 'Subscribe and get the latest details on new drops and everything!',
        subText: data?.me?.name,
        description: data?.me?.Storefront?.description || "Add text here",
        headerImage: data?.me?.Storefront?.headerImage,
        secondaryImage: '',
        newsletterImage: data?.me?.Storefront?.newsletterImage
      })

      setColorPickers({
        backgroundColor: data?.me?.Storefront?.backgroundColor,
        componentColor: data?.me?.Storefront?.componentColor,
        headingColor: data?.me?.Storefront?.headingColor
      })


      setSections({
        newsletter: data?.me?.Storefront?.newsletterSection,
        products: data?.me?.Storefront?.productsSection,
        featured: data?.me?.Storefront?.featuredSection,
        socials: data?.me?.Storefront?.socialsSection,
        links: true
      })


    }
  }, [data])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const ColorDialog = () => {
    return (
      <Tab.Group>
        <Tab.List class='flex flex-row p-3 border-[1px] rounded-full border-gray-100 border-solid items-center gap-5'>


          <Tab className={({ selected }) =>
            classNames(
              'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
              selected
                ? 'bg-royalblue text-center text-[#fff] border-0'
                : 'text-royalblue text-center bg-white'
            )
          } style={{ fontFamily: 'Semi' }}>
            Text
          </Tab>
          <Tab className={({ selected }) =>
            classNames(
              'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
              selected
                ? 'bg-royalblue text-center text-[#fff] border-0'
                : 'text-royalblue text-center bg-white'
            )
          } style={{ fontFamily: 'Semi' }}>
            Component
          </Tab>

        </Tab.List>
        <Tab.Panel>
          <div class='grid grid-cols-3 gap-x-7 text-base gap-y-5'>
            <div class=''>
              <div class='font-["Semi"] text-xl'>Heading Text</div>
              <div class='my-6 inline-block w-full gap-2'>
                <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                  <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('headingColor')} style={{ backgroundColor: colorPickers.headingColor }} >

                  </div>

                  <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                    {colorPickers.headingColor}
                  </div>
                </div>
              </div>
            </div>
            <div class=''>
              <div class='font-["Semi"] text-xl'>Sub Text</div>
              <div class='my-6 inline-block w-full gap-2'>
                <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                  <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('subtextColor')} style={{ backgroundColor: colorPickers.subtextColor }} >

                  </div>

                  <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                    {colorPickers.subtextColor}
                  </div>
                </div>
              </div>
            </div>
            <div class=''>
              <div class='font-["Semi"] text-xl'>Action Button Text</div>
              <div class='my-6 inline-block w-full gap-2'>
                <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                  <div class='p-5 border-solid border-gainsboro border-[1px] rounded-lg shrink-0' onClick={() => setActiveColorPicker('actionButtonText')} style={{ backgroundColor: colorPickers.actionButtonText }} >

                  </div>

                  <div class='w-full p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                    {colorPickers.actionButtonText}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Tab.Panel>
        <Tab.Panel>
          <div class='grid grid-cols-3 gap-x-7 text-base gap-y-5'>
            <div>
              <div class='font-["Semi"] text-xl'>Component Color</div>
              <div class='my-6 inline-block w-full gap-2'>
                <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                  <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('componentColor')} style={{ backgroundColor: colorPickers.componentColor }} >

                  </div>

                  <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                    {colorPickers.componentColor}
                  </div>
                </div>
              </div>
            </div>


            <div class=''>
              <div class='font-["Semi"] text-xl'>Action Button</div>
              <div class='my-6 inline-block w-full gap-2'>
                <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                  <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('actionButton')} style={{ backgroundColor: colorPickers.actionButton }} >

                  </div>

                  <div class='w-full p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                    {colorPickers.actionButton}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Tab.Panel>








        <div>
        </div>
        {activeColorPicker && (
          <div ref={colorPickerRef} className="bg-royalblue p-5 gap-3 inline-block rounded-xl">

            <div>
              <TwitterPicker
                styles={{
                  default: {
                    card: {
                      boxShadow: 'none',
                      borderRadius: '10px'
                    }
                  }
                }}
                triangle='false'
                color={colorPickers[activeColorPicker]}
                onChange={newColor => handleColorChange(activeColorPicker, newColor)}
              />
            </div>
          </div>
        )}
      </Tab.Group>
    )
  }
  const colorPickerRef = useRef(null);
  function formatString(s) {
    let formatted = '';
    for (let i = 0; i < s.length; i++) {
      if (i > 0 && s[i] === s[i].toUpperCase() && s[i - 1] === s[i - 1].toLowerCase()) {
        formatted += ' ';
      }
      formatted += s[i];
    }
    return formatted;
  }
  function FormattedStringComponent({ inputString }) {
    const formattedString = formatString(inputString);

    return <div class='text-xl font-["Bold"] capitalize text-white'>{formattedString}</div>;
  }
  const handleColorChange = (field, newColor) => {
    setColorPickers((prevState) => ({ ...prevState, [field]: newColor.hex }));
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('Clicked outside');
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        console.log('Clicked outside of color picker');
        setActiveColorPicker(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [pickerVisible, setPickerVisible] = useState({
    background: false,
    bannerColor: false
  });
  const Post = {
    thumbnail: '',
    description: "description",
    title: "product title",
    price: 20,
    author: {
      avatar: ''
    }
  }
  if (error) return <div>{error.message}</div>
  if (loading) return <div>loading...</div>
  return (
    <div class='w-full'>
      <form onSubmit={formik.handleSubmit}>
        <button type='submit'>Create</button>
      </form>
      <Tab.Group vertical={true}>
        <div class='w-full border-b'>


        </div>

        <div>

          <div class='border-b flex w-full' />
          <div class='flex max-w-screen-2xl mx-auto'>
            <Tab.List class='w-1/6  flex flex-col h-full bg-royalblue rounded-xl p-3 '>
              {Tabs.map(item => (
                <Tab className={({ selected }) =>
                  classNames(
                    'p-3 px-5 w-full outline-0 text-[#fff] text-start rounded-2xl border-0',
                    selected
                      ? 'text-royalblue bg-[#fff] border-0'
                      : 'bg-royalblue'
                  )
                } style={{ fontFamily: 'Semi' }}>
                  {item}
                </Tab>
              ))}
            </Tab.List>
            <div class='w-2/3 flex justify-center'>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <NameDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <Tab.Group>

                  <Tab.List class='flex flex-row p-3 border-[1px] rounded-full border-gray-100 border-solid items-center gap-5'>


                    <Tab className={({ selected }) =>
                      classNames(
                        'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
                        selected
                          ? 'bg-royalblue text-center text-[#fff] border-0'
                          : 'text-royalblue text-center bg-white'
                      )
                    } style={{ fontFamily: 'Semi' }}>
                      Format
                    </Tab>
                    <Tab className={({ selected }) =>
                      classNames(
                        'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
                        selected
                          ? 'bg-royalblue text-center text-[#fff] border-0'
                          : 'text-royalblue text-center bg-white'
                      )
                    } style={{ fontFamily: 'Semi' }}>
                      Layout
                    </Tab>
                    <Tab className={({ selected }) =>
                      classNames(
                        'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
                        selected
                          ? 'bg-royalblue text-center text-[#fff] border-0'
                          : 'text-royalblue text-center bg-white'
                      )
                    } style={{ fontFamily: 'Semi' }}>
                      Color
                    </Tab>

                  </Tab.List>
                  <div class='self-stretch'>

                    <Tab.Panel class={`self-stretch p-5`} >
                      <div class='grid grid-cols-3 w-full mt-2 gap-3'>
                        <div onClick={() => handleFormat('basic')} class='self-stretch p-5 rounded-xl bg-royalblue'>
                          <Basic item={data?.me?.onePost || Post} />
                        </div>
                        <div onClick={() => handleFormat('simple')} class='self-stretch p-5 rounded-xl bg-royalblue'>
                          <Simple item={data?.me?.onePost || Post} />

                        </div>
                        <div onClick={() => handleFormat('image')} class='self-stretch p-5 rounded-xl bg-royalblue'>
                          <Image item={data?.me?.onePost || Post} />

                        </div>
                      </div>
                      <div class='grid grid-cols-2 gap-5 mt-3'>
                        <div onClick={() => handleFormat('log')} class='self-stretch p-5 rounded-xl bg-royalblue'>
                          <Log item={data?.me?.onePost || Post} />

                        </div>
                        <div onClick={() => handleFormat('log2')} class='self-stretch p-5 rounded-xl bg-royalblue'>
                          <Log2 preview={true} item={data?.me?.onePost || Post} />

                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel class='p-5'>
                      <div>
                        <div class='grid grid-cols-2 w-full rounded-xl  gap-5'>
                          <div class='bg-royalblue rounded-xl py-3'>
                            <div onClick={() => setLayoutVertical(true)} class='gap-5 p-4 self-stretch grid grid-cols-2 bg-royalblue rounded-xl'>
                              <div class='bg-white rounded-xl h-20 w-full'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-full'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-full'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-full'>

                              </div>
                            </div>
                            <div class='text-center text-sm font-["Semi"] text-white'>Grid</div>
                          </div>
                          <div class='bg-royalblue rounded-xl py-3'>
                            <div onClick={() => setLayoutVertical(false)} class='px-4 py-3 flex items-center gap-4 rounded-xl bg-royalblue'>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                            </div>
                            <div onClick={() => setLayoutVertical(false)} class='px-4 py-3 flex items-center gap-4 rounded-xl bg-royalblue'>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                              <div class='bg-white rounded-xl h-20 w-20'>

                              </div>
                            </div>
                            <div class='text-center text-sm font-["Semi"] text-white'>Row</div>
                          </div>
                        </div>

                      </div>
                    </Tab.Panel>
                    <Tab.Panel class='w-4/5'>

                      <div class='mt-10 grid grid-cols-4 gap-5'>
                        <div onClick={() => setFont("Normal")} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["Normal"]'>
                          Normal
                        </div>
                        <div onClick={() => setFont('KodeMono')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["KMNormal"]'>
                          KodeMono
                        </div>
                        <div onClick={() => setFont('Caveat')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["CNormal"]'>
                          Caveat
                        </div>
                        <div onClick={() => setFont('Roboto')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["RNormal"]'>
                          Roboto
                        </div>
                        <div onClick={() => setFont('Namdhinggo')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["NNormal"]'>
                          Namdhinggo
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel class='p-5'>
                      <div class='grid grid-cols-2 gap-x-7 text-base gap-y-5'>
                        <div class='w-full'>
                          <div class='font-["Semi"] text-xl'>Component Color</div>
                          <div class='my-6 inline-block w-full gap-2'>
                            <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                              <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('componentColor')} style={{ backgroundColor: colorPickers.componentColor }} >

                              </div>

                              <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                                {colorPickers.componentColor}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class=''>
                          <div class='font-["Semi"] text-xl'>Product Heading</div>
                          <div class='my-6 inline-block w-full gap-2'>
                            <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                              <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('productHeading')} style={{ backgroundColor: colorPickers.productHeading }} >

                              </div>

                              <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                                {colorPickers.productHeading}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class=''>
                          <div class='font-["Semi"] text-xl'>Product Subheading</div>
                          <div class='my-6 inline-block w-full gap-2'>
                            <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                              <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('productSubheading')} style={{ backgroundColor: colorPickers.productSubheading }} >

                              </div>

                              <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                                {colorPickers.productSubheading}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class=''>
                          <div class='font-["Semi"] text-xl'>Product Price</div>
                          <div class='my-6 inline-block w-full gap-2'>
                            <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                              <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('productPrice')} style={{ backgroundColor: colorPickers.productPrice }} >

                              </div>

                              <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                                {colorPickers.productPrice}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {activeColorPicker && (
                        <div ref={colorPickerRef} className="bg-royalblue p-5 gap-3 inline-block rounded-xl">
                          <div>
                            <TwitterPicker
                              styles={{
                                default: {
                                  card: {
                                    boxShadow: 'none',
                                    borderRadius: '10px'
                                  }
                                }
                              }}
                              triangle='false'
                              color={colorPickers[activeColorPicker]}
                              onChange={newColor => handleColorChange(activeColorPicker, newColor)}
                            />
                          </div>
                        </div>
                      )}
                    </Tab.Panel>



                  </div>



                </Tab.Group>
              </Tab.Panel>


              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <SectionDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <BackgroundDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <Tab.Group>
                  <Tab.List class='flex flex-row p-3 border-[1px] rounded-full border-gray-100 border-solid items-center gap-5'>


                    <Tab className={({ selected }) =>
                      classNames(
                        'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
                        selected
                          ? 'bg-royalblue text-center text-[#fff] border-0'
                          : 'text-royalblue text-center bg-white'
                      )
                    } style={{ fontFamily: 'Semi' }}>
                      Text
                    </Tab>
                    <Tab className={({ selected }) =>
                      classNames(
                        'p-5 w-full text-center outline-0 text-[#fff] rounded-full border-0',
                        selected
                          ? 'bg-royalblue text-center text-[#fff] border-0'
                          : 'text-royalblue text-center bg-white'
                      )
                    } style={{ fontFamily: 'Semi' }}>
                      Component
                    </Tab>

                  </Tab.List>
                  <Tab.Panel class='p-5'>
                    <div class='grid grid-cols-2 gap-x-7 text-base gap-y-5'>
                      <div class=''>
                        <div class='font-["Semi"] text-xl'>Heading Text</div>
                        <div class='my-6 inline-block w-full gap-2'>
                          <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                            <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('headingColor')} style={{ backgroundColor: colorPickers.headingColor }} >

                            </div>

                            <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                              {colorPickers.headingColor}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class=''>
                        <div class='font-["Semi"] text-xl'>Sub Text</div>
                        <div class='my-6 inline-block w-full gap-2'>
                          <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                            <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('subtextColor')} style={{ backgroundColor: colorPickers.subtextColor }} >

                            </div>

                            <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                              {colorPickers.subtextColor}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class=''>
                        <div class='font-["Semi"] text-xl'>Action Button Text</div>
                        <div class='my-6 inline-block w-full gap-2'>
                          <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                            <div class='p-5 border-solid border-gainsboro border-[1px] rounded-lg shrink-0' onClick={() => setActiveColorPicker('actionButtonText')} style={{ backgroundColor: colorPickers.actionButtonText }} >

                            </div>

                            <div class='w-full p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                              {colorPickers.actionButtonText}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    {activeColorPicker && (
                      <div ref={colorPickerRef} className="bg-royalblue p-5 gap-3 inline-block rounded-xl">

                        <div>
                          <TwitterPicker
                            styles={{
                              default: {
                                card: {
                                  boxShadow: 'none',
                                  borderRadius: '10px'
                                }
                              }
                            }}
                            triangle='false'
                            color={colorPickers[activeColorPicker]}
                            onChange={newColor => handleColorChange(activeColorPicker, newColor)}
                          />
                        </div>
                      </div>
                    )}
                  </Tab.Panel>
                  <Tab.Panel class='p-5'>
                    <div class='grid grid-cols-2 gap-x-7 text-base gap-y-5'>
                      <div>
                        <div class='font-["Semi"] text-xl'>Component Color</div>
                        <div class='my-6 inline-block w-full gap-2'>
                          <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                            <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('componentColor')} style={{ backgroundColor: colorPickers.componentColor }} >

                            </div>

                            <div class='w-full border-gainsboro border-[1px] border-solid p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                              {colorPickers.componentColor}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class=''>
                        <div class='font-["Semi"] text-xl'>Action Button</div>
                        <div class='my-6 inline-block w-full gap-2'>
                          <div class='flex items-center self-stretch p-3 bg-royalblue rounded-xl gap-5'>
                            <div class='p-5 rounded-lg shrink-0' onClick={() => setActiveColorPicker('actionButton')} style={{ backgroundColor: colorPickers.actionButton }} >

                            </div>

                            <div class='w-full p-3 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                              {colorPickers.actionButton}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    {activeColorPicker && (
                      <div ref={colorPickerRef} className="bg-royalblue p-5 gap-3 inline-block rounded-xl">

                        <div>
                          <TwitterPicker
                            styles={{
                              default: {
                                card: {
                                  boxShadow: 'none',
                                  borderRadius: '10px'
                                }
                              }
                            }}
                            triangle='false'
                            color={colorPickers[activeColorPicker]}
                            onChange={newColor => handleColorChange(activeColorPicker, newColor)}
                          />
                        </div>
                      </div>
                    )}
                  </Tab.Panel>








                  <div>
                  </div>

                </Tab.Group>
              </Tab.Panel>

              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <MediaDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <SocialsDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <NewsletterDialog />
              </Tab.Panel>
              <Tab.Panel>
                <EmbeddedDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <FeaturedDialog />
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>
                <div className="flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between">
                  <Switch
                    checked={privacy}
                    onChange={() => isPrivacy(!privacy)} // Toggle between true and false
                    className={`${privacy ? 'bg-white' : 'bg-black'} relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${privacy ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                <div class={`mt-5 ${privacy === false && 'hidden'}`}>
                     <input placeholder='password' onChange={(event) => handleTextChange('storefrontPassword', event.target.value)}  />
                </div>
                <div>
                {textValues.storefrontPassword}
                {privacy}
                </div>
              </Tab.Panel>
            </div>


            <div class='pt-10 w-1/3 flex justify-center'>
              <div className={`xl:w-[335px] border-[8px] border-solid border-[#000] w-[270px] h-[510px] overflow-y-scroll xl:h-[590px] rounded-[60px] flex flex-col`}>
                <div class='relative'>
                  <div class='h-28'>
                  </div>
                  <div style={{
                    backgroundColor: colorPickers.backgroundColor ? `${colorPickers.backgroundColor}` : ''
                  }} class='flex flex-col  mx-auto rounded-t-3xl px-5'>

                    <div className="flex-col justify-center relative w-full self-stretch items-center inline-flex">
                      <div class='flex-col justify-center absolute top-[-30px] flex items-center w-full'>
                        {textValues.headerImage === "" ? (
                          <img className="rounded-full w-20 h-20" src={data.me.avatar} />
                        ) : (
                          <img className="rounded-full w-20 h-20" src={textValues.headerImage} />
                        )}
                      </div>

                      <div className="flex-col text-center inline-flex flex mt-12">
                        <ColorEditableText
                          textColor={colorPickers.headingColor}
                          initialText={textValues.subText || data.me.name}
                          picker={pickerVisible.heading}
                          styles={`${font === 'Caveat' && 'font-["CBold"]'} text-xl  ${font === 'Namdhinggo' && 'font-["NBold"]'}  ${font === 'Roboto' && 'font-["RBold"]'}  ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'}`}
                          onTextChange={(editedText) => handleTextChange('subtext', editedText)}
                          onColorChange={(newColor) => handleColorChange('headingColor', newColor)}
                          defaultColor={colorPickers.headingColor}
                        />
                      </div>
                      <div className="flex-col inline-flex flex">
                        <ColorEditableText
                          textColor={colorPickers.subtextColor}
                          initialText={textValues.description || data.me.bio}
                          picker={pickerVisible.heading}
                          styles={`${font === 'Caveat' && 'font-["CBold"]'} text-sm  text-center ${font === 'Namdhinggo' && 'font-["NBold"]'}  ${font === 'Roboto' && 'font-["RBold"]'}  ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'}`}
                          onTextChange={(editedText) => handleTextChange('description', editedText)}
                          onColorChange={(newColor) => handleColorChange('subtextColor', newColor)}
                          defaultColor={colorPickers.subtextColor}
                        />

                      </div>






                      <div class='flex items-center inline-flex justify-center w-full'>

                        {sections.socials === true && (
                          <div class='flex items-center mt-2 w-full gap-3'>
                            {instagramShown === true && (
                              <FaTiktok class='w-4 text-white' />
                            )}

                            {facebookShown === true && (
                              <FaInstagram class='w-5 text-white' />
                            )}

                            {tiktokShown === true && (
                              <BsTwitterX class='w-5 text-white' />
                            )}

                            {twitterShown === true && (
                              <FaFacebookF class='w-3 text-white' />
                            )}

                          </div>

                        )}
                      </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      {renderEmbeddedContent()}
                    </div>
                    <div>
                      {sections.newsletter === true && (
                        <div class='mt-5'>
                          <div style={{ color: colorPickers.headingColor }} className={`text-center text-lg font-semibold px-3 ${font === 'Namdhinggo' && 'font-["NBold"]'} ${font === 'Roboto' && 'font-["RBold"]'} ${font === 'Normal' && 'font-["Semi"]'} ${font === 'Caveat' && 'font-["CSemi"]'} ${font === 'KodeMono' && 'font-["KMSemi"]'} leading-normal leading-7`}>Newsletter</div>
                          <div class='w-full my-5'>

                            <div style={{ backgroundColor: colorPickers.componentColor }} className={`self-stretch p-5 relative bg-white rounded-[28px]  flex-col justify-start items-start flex`}>
                              <div class='flex justify-between w-full items-center'>
                                <div>
                                  <ColorEditableText
                                    textColor={colorPickers.headingColor}
                                    initialText={textValues.subscribeText}
                                    picker={pickerVisible.heading}
                                    styles={`${font === 'Caveat' && 'font-["CBold"]'} text-xl font-['Bold']  ${font === 'Namdhinggo' && 'font-["NBold"]'}  ${font === 'Roboto' && 'font-["RBold"]'}  ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'}`}
                                    onTextChange={(editedText) => handleTextChange('subscribeText', editedText)}
                                    onColorChange={(newColor) => handleColorChange('headingColor', newColor)}
                                    defaultColor={colorPickers.headingColor}
                                  />
                                  <ColorEditableText
                                    textColor={colorPickers.newsletterSubText}
                                    initialText={textValues.subscribeSubText || "Subscribe and get the latest details on new drops and everything!"}
                                    picker={pickerVisible.heading}
                                    styles={`${font === 'Caveat' && 'font-["CBold"]'} text-gray-400 my-1 mb-3 text-[13px] font-["Face"] ${font === 'Namdhinggo' && 'font-["NBold"]'}  ${font === 'Roboto' && 'font-["RBold"]'}  ${font === 'Normal' && 'font-["Face"]'} ${font === 'KodeMono' && 'font-["KMBold"]'}`}
                                    onTextChange={(editedText) => handleTextChange('subscribeSubText', editedText)}
                                    onColorChange={(newColor) => handleColorChange('newsletterSubText', newColor)}
                                    defaultColor={colorPickers.newsletterSubText}
                                  />
                                </div>

                              </div>
                              <div class='relative w-full items-center mt-2 flex'>
                                <input placeholder='example@gmail.com' class='self-stretch w-full px-5 inline-block py-3.5 placeholder:text-gray-300 rounded-full border-gainsboro border-[1px] border-solid' />
                                <button style={{ backgroundColor: colorPickers.actionButton, color: colorPickers.actionButtonText }} class='px-3 text-xs  absolute right-2 flex items-center justify-center gap-3 py-2 rounded-full text-sm border-[0px] bg-black text-[#fff] font-["Bold"]'>
                                  Subscribe
                                </button>
                              </div>


                            </div>



                          </div>
                        </div>
                      )}
                      {sections.products === true && (
                        <div>
                          <div style={{ color: colorPickers.headingColor }} className={`text-center text-lg font-semibold px-3 ${font === 'Namdhinggo' && 'font-["NBold"]'} ${font === 'Roboto' && 'font-["RBold"]'} ${font === 'Normal' && 'font-["Semi"]'} ${font === 'Caveat' && 'font-["CSemi"]'} ${font === 'KodeMono' && 'font-["KMSemi"]'} leading-normal leading-7`}>Products</div>


                          <div className={`w-full ${layoutVertical === true ? 'grid grid-cols-2' : 'hidden'} gap-5 mt-5`}>
                            {data.me.storefrontPosts.map(item => (
                              <div class='w-full'>

                                {productsFormat.basic === true && (
                                  <Basic item={item} />

                                )}
                                {productsFormat.simple === true && (
                                  <Simple item={item} />
                                )}
                                {productsFormat.image === true && (
                                  <Image item={item} />

                                )}


                              </div>
                            ))}
                          </div>
                          <div class={`${productsFormat.log === true && 'flex flex-col w-full'} ${layoutVertical === true ? '' : 'hidden'} space-y-2 ${productsFormat.log2 === true && 'flex flex-col w-full'}`}>

                            {data.me.storefrontPosts.map(item => (
                              <div class='w-full'>

                                {productsFormat.log === true && (
                                  <Log item={item} />
                                )}
                                {productsFormat.log2 === true && (
                                  <Log2 item={item} />

                                )}
                              </div>
                            ))}

                          </div>
                          <div className={`overflow-x-scroll ${layoutVertical === false ? 'flex' : 'hidden'} gap-5 mt-5`}>
                            {data.me.storefrontPosts.map(item => (
                              <div class='w-auto'>
                                {productsFormat.basic === true && (
                                  <Basic row={true} item={item} />

                                )}
                                {productsFormat.simple === true && (
                                  <Simple row={true} item={item} />
                                )}
                                {productsFormat.image === true && (
                                  <Image row={true} item={item} />

                                )}

                              </div>
                            ))}
                          </div>







                          <div class={`overflow-x-scroll  ${layoutVertical === false ? 'flex' : 'hidden'} space-x-2`}>
                            {data.me.storefrontPosts.map(item => (
                              <div>
                                {productsFormat.log === true && (
                                  <Log row={true} item={item} />
                                )}
                                {productsFormat.log2 === true && (
                                  <Log2 row={true} item={item} />
                                )}
                              </div>
                            ))}
                          </div>



                        </div>
                      )}

                      {sections.featured === true && (
                        <div>
                          <div style={{ color: colorPickers.headingColor }} className={`text-center text-lg font-semibold px-3 ${font === 'Namdhinggo' && 'font-["NBold"]'} ${font === 'Roboto' && 'font-["RBold"]'} ${font === 'Normal' && 'font-["Semi"]'} ${font === 'Caveat' && 'font-["CSemi"]'} ${font === 'KodeMono' && 'font-["KMSemi"]'} leading-normal leading-7`}>Featured</div>
                          {data.me.featuredPost ?
                            (
                              <div class='relative w-full'>
                                <img src={data?.me?.featuredPost?.post?.thumbnail} class='w-full rounded-xl' />
                              </div>
                            )
                            :
                            (
                              <div class='w-full flex items-center justify-center rounded-xl h-52 border-dotted border-gainsboro border-[2px]'>
                                <div class='text-sm text-gainsboro font-["Face"]'>
                                  Add a Featured Product
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {sections.links === true && (
                        <div>
                          <div style={{ color: colorPickers.headingColor }} className={`text-center text-lg font-semibold px-3 ${font === 'Namdhinggo' && 'font-["NBold"]'} ${font === 'Roboto' && 'font-["RBold"]'} ${font === 'Normal' && 'font-["Semi"]'} ${font === 'Caveat' && 'font-["CSemi"]'} ${font === 'KodeMono' && 'font-["KMSemi"]'} leading-normal leading-7`}>Links</div>
                          <div class='gap-2 flex flex-col'>
                            {data.me.Links.map(item => (
                              <div style={{ backgroundColor: colorPickers.componentColor }} class={`rounded-xl flex px-3 py-2 items-center justify-between`}>
                                <img class='w-10 h-10 rounded-full' src={item.image} />
                                <div className={`text-center text-lg font-semibold px-3 ${font === 'Namdhinggo' && 'font-["NBold"]'} ${font === 'Roboto' && 'font-["RBold"]'} ${font === 'Normal' && 'font-["Semi"]'} text-sm ${font === 'Caveat' && 'font-["CSemi"]'} ${font === 'KodeMono' && 'font-["KMSemi"]'} leading-normal leading-7`}>{item.linkText}</div>

                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>








      </Tab.Group>
    </div>
  )
}

export default EditTemplate