import React from 'react'

const Affiliate = () => {
    return (
        <div>
            <div class='w-full flex items-center bg-royalblue h-screen'>
                <div class='fixed top-0 w-full'>
                    <a href='/' class='self-stretch flex items-center justify-between 2xl:px-10 xs:m-5 m-5 2xl:m-0 2xl:py-5'>
                        <img src='/head.svg' class='h-9' />
                    </a>
                </div>
                <div class='fixed bottom-5 right-5 flex items-center justify-center rounded-full h-14 w-14 bg-[#fff]'>
                    <img src='/Comment2.svg' class='w-7 h-7' />
                </div>
                <div class='xs:max-w-md md:max-w-2xl md:mx-auto max-w-full m-5 p-10 xs:mx-auto bg-[#fff] shadow-lg border-[1px] border-gainsboro rounded-xl border-solid'>

                    <div className='flex items-center justify-center flex-col gap-5'>
                        <div class='flex flex-col gap-2 xs:mb-7 mb-0'>
                            <img src='/union5.svg' className='xl:h-14 lg:h-12 md:h-10 h-9' />
                            <div className='xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl xs:text-2xl text-2xl font-["Bold"] text-center'>Become a affiliate!</div>
                            <div class='font-["Face"] text-center text-gray-200'>Become an affiliate and get extra Creatorzhub perks!</div>
                        </div>

                    </div>
                    <div class='w-full flex flex-col mb-5'>
                        <div class='font-["Face"] mb-3'>Name</div>
                        <input
                            className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                        />
                    </div>
                    <div class='w-full flex flex-col mb-5'>
                        <div class='font-["Face"] mb-3'>Email</div>
                        <input
                            className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                        />
                    </div>

                    <div class='w-full flex flex-col mb-5'>
                        <div class='font-["Face"] mb-3'>Creatorzhub username</div>
                        <input
                            className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                        />
                    </div>
                    <button class='py-5 w-full border-0 rounded-full bg-royalblue text-[#fff] font-["Semi"]'>
                        Send <span aria-hidden="true">&rarr;</span>
                    </button>
                    <div class='py-5 text-center text-sm font-["Face"] text-gray-400'>You must have an account first to become an affiliate. <br /> No account? <a href='/register' class='text-royalblue no-underline font-["Semi"]'>Here</a></div>
                </div>
            </div>

        </div>
    )
}

export default Affiliate