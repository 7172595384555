import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useMutation } from '@apollo/client'
import { gql } from 'graphql-tag'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
const CREATE_PAYMENT = gql`
  mutation ClothingPayment($paymentData: PaymentCreateInput!, $shippingData: ShippingCreateInput) {
    ClothingPayment(paymentData: $paymentData, shippingData: $shippingData) {
      id
      amount
      # Include any other fields you want to fetch
    }
  }
`;

const DELETE_POST = gql`
   mutation deletePost($id: Int!){
    deletePost(id: $id)
   }
`
const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#000",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#000" },
            "::placeholder": { color: "#000" },
            backgroundColor: "#fff", // Optional: Add a background color
            border: "1px solid #e0e0e0", // Optional: Add a border color
            borderRadius: "8px", // Curved edges
            padding: "10px" // Optional: Add padding for better spacing
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee",
            borderColor: "#ffc7ee", // Add a border color for invalid state
            borderRadius: "8px" // Curved edges for invalid state
        }
    }
}

function ProductDialog({ item, isOpen, onClose, bgColor, txColor }) {
    const [paying, setPaying] = useState(false)
    let [isPaying, setIsPaying] = useState(false)

    function closeModal() {
        setIsPaying(false)
    }

    function openModal() {
        setIsPaying(true)
    }
    const [success, setSuccess] = React.useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const [pay, {data}] = useMutation(CREATE_PAYMENT)
    const [deletePost] = useMutation(DELETE_POST)

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    // Handle changes to the email input
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };


    // Handle changes to the name input
    const handleNameChange = (event) => {
        setName(event.target.value);
    };


    const [shippingData, setShippingData] = useState({
        criteria: '',
        address: '',
        zipCode: 0,
        state: '',
        city: '',
        country: '',
        name: name,
        emailAdress: email,
    });
    const zipCode = parseInt(shippingData.zipCode, 10);

    const handlePaymentSubmit = async (e) => {
        const paymentData = {
            amount: item?.price,
            id: item?.author?.id,
            email: email,
            name: name,
            productId: item?.id,
        }

        try {
            const { data } = await pay({
                variables: {
                    paymentData,
                    zipCode: zipCode,
                    shippingData
                },
            });

            // Handle the response data here if needed
            console.log(data);
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };


    const handlePaymentDonation = async (e) => {
        const paymentData = {
            amount: selectedDonation,
            id: item?.author?.id,
            email: email,
            name: name,
            productId: item?.id,
        }
        try {
            const { data } = await pay({
                variables: {
                    paymentData,
                },
            });

            // Handle the response data here if needed
            console.log(data);
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };

    const handleSizeClick = (size) => {
        // When a size is clicked, update the criteria in the state
        setShippingData({
            ...shippingData,
            criteria: size,
        });
    };

    const Donations = [
        { amount: 1 },
        { amount: 5 },
        { amount: 10 },
        { amount: 15 },
        { amount: 20 },
        { amount: 100 }
    ]

    const [selectedDonation, setSelectedDonation] = useState(null);

    const handleDonationSelect = (amount) => {
        setSelectedDonation(amount);
        openModal()
    };

    const handleDonation = async (e) => {



        e.preventDefault()
        const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })

        const { id } = paymentMethod

        if (selectedDonation === null) {
            console.log("Please select a donation amount");
            return;
        }

        // Rest of your code remains unchanged

        const response = await fetch("https://server-kyt.herokuapp.com/payment2", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amount: selectedDonation * 100, // Convert to cents
                id: id,
                email: email,
            }),
        });

        console.log("Successful payment");
        handlePaymentDonation();

        setSuccess(true);
        closeModal()

        // Set a timeout to hide the message after 2 seconds (2000 milliseconds)
        setTimeout(() => {
            setSuccess(false);
        }, 3000);
        console.log(response.body);
    };

    const handleShippingInputChange = (e) => {
        const { name, value } = e.target;

        // If the input name is "zipCode," parse the value as an integer
        const parsedValue = name === 'zipCode' ? parseInt(value, 10) || 0 : value;

        setShippingData({
            ...shippingData,
            [name]: parsedValue,
        });
    };

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        
        // Create a payment method using Stripe
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
    
        const { id } = paymentMethod;
    
        if (!error) {
            try {
                // Make a POST request to your server
                const response = await fetch('https://server-kyt.herokuapp.com/payment2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        amount: item.price + '00',
                        id,
                        email: email, // The user's email address
                        title: item.title,
                        idNumber: data?.ClothingPayment?.id || 'defaultIdNumber', // Use optional chaining
                        name: item?.author?.name,
                        customer: name
                    }),
                });
    
                // Check if the response is OK
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                // Parse the response body as JSON
                const responseBody = await response.json();
                console.log('Successful payment:', responseBody);
    
                // Handle the payment success
                handlePaymentSubmit();
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
            } catch (error) {
                console.log('Error:', error);
            }
        } else {
            console.log('Stripe error:', error.message);
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        const { id } = paymentMethod;

        if (!error) {
            try {
                const response = await fetch('https://server-kyt.herokuapp.com/payment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        amount: item.price + '00',
                        id,
                        email: email, // The user's email address
                        file: item.file,
                        title: item.title,
                        idNumber: data?.ClothingPayment?.id || 'defaultIdNumber', // Use optional chaining
                        name: item?.author?.name,
                        customer: name,
                        // The document to send (base64 encoded)
                    }),
                });

                console.log('Successful payment');
                handlePaymentSubmit();
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
                console.log(response.body);
            } catch (error) {
                console.log('Error', error);
            }
        } else {
            console.log(error.message);
        }
    };

    return (
        <div>
            <Transition show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="inset-0"
                    onClose={onClose}
                >
                    <div className="min-h-screen">




                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="p-5 rounded-t-3xl self-stretch fixed bg-white bottom-0 transform transition-transform duration-300">



                                {isOpen && item !== null && ( // Check if item is not null
                                    // Render your dialog content here, including item details
                                    <div className="overflow-y-scroll max-h-screen">

                                        <div>
                                            <div class='w-full'>
                                                <div class='flex items-center justify-between self-stretch'>
                                                    <div class='my-3 text-2xl font-["Bold"]'>{item.title}</div>
                                                    <img src={item.thumbnail} className="w-10 rounded-full h-10" />
                                                </div>
                                                <div class='flex items-center py-2 gap-3'>
                                                    <div class='font-["Semi"] text-lg'>{item.isDonation ? 'Donation' : `$${item.price}`}</div>
                                                    <div class='h-5 w-[1px] bg-gray-300' />
                                                    <div class='flex items-center gap-x-2'>
                                                        <img src='/rating.svg' class='xs:h-auto h-4' />
                                                        <div class='text-gray-500 font-["Face"] text-sm'>{item.isDonation ? 'Donation rating' : 'Rated by others'}</div>
                                                    </div>
                                                </div>
                                                <div class='mt-2 mb-4 font-["Face"] text-gray-500 w-full flex-grow min-w-full text-sm pr-3'>{item.description}</div>
                                                <div class='flex items-center mb-6 gap-3'>
                                                    <img src='/checks.png' class='w-4 h-4' />
                                                    <div class='text-sm font-["Semi"]'>{item.isDonation === true ? `${item.author.name} is ready to recieve` : `${item.isPhysical === true ? ('In stock and ready to ship') : ('Downloadable and ready to share')}`}</div>
                                                </div>

                                                <Transition show={isPaying} as={Fragment}>
                                                    <Dialog
                                                        as="div"
                                                        className="fixed inset-0 z-10"
                                                        onClose={onClose}
                                                    >
                                                        <div className="min-h-screen">




                                                            <Transition.Child
                                                                as={Fragment}
                                                                enter="ease-out duration-300"
                                                                enterFrom="opacity-0 scale-95"
                                                                enterTo="opacity-100 scale-100"
                                                                leave="ease-in duration-200"
                                                                leaveFrom="opacity-100 scale-100"
                                                                leaveTo="opacity-0 scale-95"
                                                            >
                                                                <div className="rounded-t-3xl w-full fixed bg-white bottom-0 transform transition-transform duration-300">

                                                                    <div class='p-5'>
                                                                        {item.isPhysical === false && (
                                                                            <div>
                                                                                <div class='flex items-center justify-between mb-4'>
                                                                                    <div class='text-2xl w-2/3' style={{ fontFamily: 'Bold' }}>Checkout</div>
                                                                                    <img src={item.thumbnail} class='w-10 h-10 rounded-full' />
                                                                                </div>
                                                                                <div class='my-3 flex items-center border-solid border-gainsboro border-[1px] rounded-xl overflow-hidden gap-3'>
                                                                                    <img src={item.thumbnail} class='w-24 h-24' />
                                                                                    <div class='py-2 pr-3'>
                                                                                        <div class='font-["Semi"] line-clamp-1 text-sm'>{item.title}</div>
                                                                                        <div class='line-clamp-3 text-xs text-gray-500 font-["Face"] mb-2 mt-1'>{item.description}</div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )}
                                                                        {item.isPhysical === true && (
                                                                            <div class='self-stretch'>
                                                                                <div class='flex items-center justify-between mb-4'>
                                                                                    <div class='text-2xl w-2/3' style={{ fontFamily: 'Bold' }}>Checkout</div>
                                                                                    <img src={item.thumbnail} class='w-10 h-10 rounded-full' />
                                                                                </div>
                                                                                <div class='my-3 flex items-center border-solid border-gainsboro border-[1px] rounded-xl overflow-hidden gap-3'>
                                                                                    <img src={item.thumbnail} class='w-24 h-24' />
                                                                                    <div class='py-2 pr-3'>
                                                                                        <div class='font-["Semi"] line-clamp-1 text-sm'>{item.title}</div>
                                                                                        <div class='line-clamp-3 text-xs text-gray-500 font-["Face"] mb-2 mt-1'>{item.description}</div>

                                                                                    </div>
                                                                                </div>

                                                                                <form>
                                                                                    <div class='font-["Semi"] my-4 text-sm'>Shipping Information</div>

                                                                                    <div className="flex my-4 w-full overflow-hidden border-[1px] border-gainsboro border-solid rounded-[14px] items-center">
                                                                                        <div class='w-full'>
                                                                                            <input
                                                                                                style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                                type="text"
                                                                                                name="name"
                                                                                                placeholder="Name"
                                                                                                value={name}
                                                                                                onChange={handleNameChange}
                                                                                                className="h-12 px-3 w-full border-0 font-semi text-sm outline-0 box-border"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="w-[2px] h-12 bg-gainsboro"></div> {/* Divider */}
                                                                                        <div class='w-full'>
                                                                                            <input
                                                                                                style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                                type="text"
                                                                                                placeholder="Email"
                                                                                                name="emailAdress"
                                                                                                value={email}
                                                                                                onChange={handleEmailChange}
                                                                                                className="h-12 px-3 w-full border-0 font-semi text-sm outline-0 box-border"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="space-y-2 mt-3 w-full">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="address"
                                                                                            placeholder="Address"
                                                                                            value={shippingData.address}
                                                                                            onChange={handleShippingInputChange}
                                                                                            className="w-full h-12 px-3 border-[1px] font-semi border-gainsboro border-solid text-sm outline-0 rounded-[14px] box-border"
                                                                                            style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="flex my-4 w-full overflow-hidden border-[1px] border-gainsboro border-solid rounded-[14px] items-center">
                                                                                        <div className="w-full">
                                                                                            <input
                                                                                                placeholder="Country"
                                                                                                type="text"
                                                                                                name="country"
                                                                                                value={shippingData.country}
                                                                                                onChange={handleShippingInputChange}
                                                                                                className="h-12 px-3 w-full border-0 font-semi text-sm outline-0 box-border"
                                                                                                style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="w-[1px] h-12 bg-gainsboro"></div> {/* Divider */}
                                                                                        <div className="w-full">
                                                                                            <input
                                                                                                placeholder="State"
                                                                                                type="text"
                                                                                                name="state"
                                                                                                value={shippingData.state}
                                                                                                style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                                onChange={handleShippingInputChange}
                                                                                                className="h-12 px-3 font-semi w-full border-0 text-sm outline-0 box-border"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="w-[1px] h-12 bg-gainsboro"></div> {/* Divider */}
                                                                                        <div className="w-full">
                                                                                            <input
                                                                                                placeholder="City"
                                                                                                type="text"
                                                                                                name="city"
                                                                                                value={shippingData.city}
                                                                                                onChange={handleShippingInputChange}
                                                                                                className="h-12 w-full px-3 border-0 text-sm font-semi outline-0 box-border"
                                                                                                style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class='space-y-2 mt-3'>
                                                                                        <input
                                                                                            style={{ fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif' }}
                                                                                            placeholder='Zip code'
                                                                                            type="text"
                                                                                            name="zipCode"
                                                                                            value={shippingData.zipCode}
                                                                                            onChange={handleShippingInputChange}
                                                                                            className="w-full h-12 px-3 border-[1px] border-gainsboro border-solid text-sm outline-0 font-medium rounded-[14px] box-border" />
                                                                                    </div>
                                                                                </form>
                                                                                {item.isClothing === true && (
                                                                                    <div>
                                                                                        <div class='text-xl' style={{ fontFamily: 'Semi' }}>Sizes</div>
                                                                                        <div class='my-3'>
                                                                                            {item.Clothing.map((item) => (
                                                                                                <div class='space-x-4 flex items-center' key={item.id}>
                                                                                                    {item.sizes.map((size) => (
                                                                                                        <div style={{ fontFamily: 'Face' }}
                                                                                                            className={`border rounded-full px-5 py-1 text-sm ${shippingData.criteria === size ? 'bg-black text-white' : ''
                                                                                                                }`}
                                                                                                            onClick={() => handleSizeClick(size)}
                                                                                                        >
                                                                                                            {size}
                                                                                                        </div>
                                                                                                    ))}

                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                                {item.isMerchandise === true && (
                                                                                    <div>
                                                                                        <div class='text-xl' style={{ fontFamily: 'Semi' }}>Sizes</div>
                                                                                        <div class='my-3'>
                                                                                            {item.Merchandise.map((item) => (
                                                                                                <div class='space-x-4 flex items-center' key={item.id}>
                                                                                                    {item.color.map((color) => (
                                                                                                        <div style={{ fontFamily: 'Face' }} class='border rounded-full px-5 py-1 text-sm'>
                                                                                                            {color}
                                                                                                        </div>
                                                                                                    ))}

                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                        )}


                                                                        {item.isPhysical === true ? (
                                                                            <div />
                                                                        ) : (
                                                                            <div className="my-2 flex flex-col w-full mt-2">
                                                                                <div class='font-["Semi"] mb-2 mt-4 text-sm'>Name & Email</div>
                                                                                <div class=' border-[1px] border-solid border-gainsboro rounded-[16px]'>
                                                                                    <input
                                                                                        value={name}
                                                                                        onChange={handleNameChange}
                                                                                        className="py-4 font-['Face'] focus:outline-none focus:ring-0 border-solid px-4 self-stretch outline-0 border-0 border-gainsboro rounded-t-[14px]"
                                                                                        placeholder="John Doe"
                                                                                    />
                                                                                    <div class='h-[1px] w-full bg-gainsboro'></div>
                                                                                    <input
                                                                                        value={email}
                                                                                        onChange={handleEmailChange}
                                                                                        className="py-4 font-['Face'] focus:outline-none focus:ring-0 px-4 self-stretch border-solid border-0 border-gainsboro rounded-b-[14px] outline-0"
                                                                                        placeholder="doe@example.com"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {item.isDonation === true ? (
                                                                            <form class='self-stretch' onSubmit={handleDonation}>
                                                                                <div class='font-["Semi"] mb-2 mt-4 text-sm'>Card Information</div>

                                                                                <div className="p-3 py-4 border-[1px] rounded-xl border-gainsboro self-stretch border-solid my-3">
                                                                                    <CardElement options={CARD_OPTIONS} />
                                                                                </div>
                                                                                <div class='mt-3 flex flex-row items-center gap-2'>
                                                                                    <img src='/checks2.png' class='w-7 h-7' />
                                                                                    <div class='font-["Semi"] text-sm'>Guaranteed safe & secure payments</div>
                                                                                </div>
                                                                                {success === true && (
                                                                                    <div class={`my-2 py-2 bg-black ${item?.author?.Website?.borderRadius} text-white font-["Semi"] `}>
                                                                                        Payment was successful
                                                                                    </div>
                                                                                )}
                                                                                <button type='submit'
                                                                                    className={`w-full py-4 mt-3 bg-black text-[15px] outline-0 border-0 text-white rounded-[16px] font-bold font-["Roboto, Open Sans, Segoe UI, sans-serif"]`}
                                                                                >
                                                                                    Donate ${selectedDonation}
                                                                                </button>

                                                                                <button
                                                                                    type="button"
                                                                                    style={{ fontFamily: 'Semi' }}
                                                                                    className={`w-full py-4 bg-gray-100 outline-0 mt-4 text-[15px] border-0 rounded-[16px] text-black bg-black`}
                                                                                    onClick={closeModal}
                                                                                >
                                                                                    Close
                                                                                </button>

                                                                            </form>
                                                                        ) : (
                                                                            <div>
                                                                                {item.isPhysical === false ? (
                                                                                    <form onSubmit={handleSubmit}>
                                                                                        <div class='font-["Semi"] mb-2 mt-4 text-sm'>Card Information</div>

                                                                                        <div className="p-3 py-4 border-[1px] rounded-xl border-gainsboro border-solid my-3">
                                                                                            <CardElement options={CARD_OPTIONS} />
                                                                                        </div>
                                                                                        <div class='mt-3 flex flex-row items-center gap-2'>
                                                                                            <img src='/checks2.png' class='w-7 h-7' />
                                                                                            <div class='font-["Semi"] text-sm'>Guaranteed safe & secure payments</div>
                                                                                        </div>
                                                                                        {success === true && (<div class='text-sm py-2 font-["Semi"]'>Purchase succesful 🎉</div>)}

                                                                                        <button type='submit' class='w-full py-4 mt-3 bg-black text-[15px] outline-0 border-0 text-white rounded-[16px] font-bold font-["Roboto, Open Sans, Segoe UI, sans-serif"]'>
                                                                                            Pay ${item.price}
                                                                                        </button>
                                                                                        <div className="mt-4">
                                                                                            <button
                                                                                                type="button"
                                                                                                style={{ fontFamily: 'Semi' }}
                                                                                                className={`w-full py-4 bg-gray-100 outline-0 text-[15px] border-0 rounded-[16px] text-black bg-black`}
                                                                                                onClick={closeModal}
                                                                                            >
                                                                                                Close
                                                                                            </button>
                                                                                        </div>
                                                                                    </form>
                                                                                ) : (
                                                                                    <form onSubmit={handleSubmit2}>
                                                                                        <div class='font-["Semi"] mb-2 mt-4 text-sm'>Card Information</div>

                                                                                        <div className="p-3 py-4 border-[1px] rounded-xl border-gainsboro border-solid my-3">
                                                                                            <CardElement options={CARD_OPTIONS} />
                                                                                        </div>
                                                                                        <div class='mt-3 flex flex-row items-center gap-2'>
                                                                                            <img src='/checks2.png' class='w-7 h-7' />
                                                                                            <div class='font-["Semi"] text-sm'>Guaranteed safe & secure payments</div>
                                                                                        </div>
                                                                                        {success === true && (<div class='text-sm py-2 font-["Semi"]'>Purchase succesful 🎉</div>)}

                                                                                        <button type='submit' class='w-full py-4 mt-3 bg-black text-[15px] outline-0 border-0 text-white rounded-[16px] font-bold font-["Roboto, Open Sans, Segoe UI, sans-serif"]'>
                                                                                            Pay ${item.price}
                                                                                        </button>
                                                                                        <div className="mt-4">
                                                                                            <button
                                                                                                type="button"
                                                                                                style={{ fontFamily: 'Semi' }}
                                                                                                className={`w-full py-4 bg-gray-100 outline-0 text-[15px] border-0 rounded-[16px] text-black bg-black`}
                                                                                                onClick={closeModal}
                                                                                            >
                                                                                                Close
                                                                                            </button>
                                                                                        </div>
                                                                                    </form>
                                                                                )}

                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </Transition.Child>
                                                        </div>

                                                    </Dialog>
                                                </Transition>
                                                {item.isDonation === true ? (
                                                    <div class='w-full self-stretch flex-grow gap-3 grid grid-cols-3 py-2 '>

                                                        {Donations.map(post => (
                                                            <button
                                                                key={post.amount}
                                                                className={`border-0 rounded-[14px] focus:outline-none px-7 outline-0 text-sm py-2 font-["Semi"] self-stretch bg-black text-white
                                                                    }`}
                                                                onClick={() => handleDonationSelect(post.amount)}
                                                            >
                                                                ${post.amount}
                                                            </button>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <button onClick={openModal} type='submit' class='w-full py-4 bg-black focus:outline-none focus:ring-0 text-[15px] outline-0 border-0 text-white rounded-[16px] font-bold  font-["Roboto, Open Sans, Segoe UI, sans-serif"]'>
                                                        Purchase
                                                    </button>
                                                )}


                                            </div>

                                            <div className="mt-3">
                                                <button
                                                    type="button"
                                                    style={{ fontFamily: 'Semi' }}
                                                    className={`w-full py-4 bg-gray-100 outline-0 text-[15px] border-0 rounded-[16px] text-black bg-black`}
                                                    onClick={onClose}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                        {/* Close button */}
                                    </div>
                                )}


                            </div>
                        </Transition.Child>

                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default ProductDialog;