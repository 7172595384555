import React from 'react'
import { BanknotesIcon, QuestionMarkCircleIcon, CreditCardIcon, LinkIcon, ShoppingBagIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline'
import FrameComponent7 from './Landing/FrameComponent7'
import { Feedback } from './Landing'

const FAQs = [
  { question: 'What is Creatorzhub?', bgColor: 'bg-orange-200', iconColor: 'text-orange-500', icon: BanknotesIcon, answer: 'Creatorzhub is an all-in-one platform designed to empower creators by providing tools and features to monetize and engage their audience effectively in the digital space.' },
  { question: 'How can I get started with Creatorzhub?', bgColor: 'bg-blue-200', iconColor: 'text-blue-500', icon: QuestionMarkCircleIcon, answer: "Getting started with Creatorzhub is easy! Simply sign up for an account on our website, and you'll gain access to our comprehensive suite of tools and features. From there, you can explore all that Creatorzhub has to offer and start maximizing your creative potential." },
  { question: 'Is Creatorzhub suitable for all types of creators?', bgColor: 'bg-red-200', iconColor: 'text-red-500',icon: CreditCardIcon, answer: "Yes, Creatorzhub is designed to cater to a wide range of creators, including but not limited to influencers, YouTubers, podcasters, bloggers, artists, and musicians. Whether you're a seasoned content creator or just starting out, Creatorzhub offers essential tools and support to help you thrive in the digital landscape." },
  { question: 'How can Creatorzhub help me monetize my content?', bgColor: 'bg-purple-200', iconColor: 'text-purple-500', icon: ShoppingBagIcon, answer: "Creatorzhub offers various monetization options, including ad integration, subscription services, and merchandise sales. These features enable creators to diversify their revenue streams and maximize their earning potential." },
  { question: 'How does Creatorzhub support audience growth?', bgColor: 'bg-rose-200', iconColor: 'text-rose-500', icon: Square3Stack3DIcon, answer: 'Creatorzhub provides powerful analytics and optimization tools that offer insights into audience behavior, content performance, and market trends. By leveraging data-driven recommendations and actionable insights, creators can refine their strategies to reach new heights of success.' },
]
export const Faq = () => {
  return (
    <div>
      <FrameComponent7 />
      <div class='py-32'>
      <Feedback />
        <div class='max-w-screen-2xl mx-auto'>
          <div className="font-['Bold'] text-center mb-4 text-black 2xl:text-6xl xl:text-6xl lg:text-5xl md:text-5xl sm:text-4xl text-4xl">
          Frequently Asked <br />Questions
          </div>
          <div class='text-center md:max-w-2xl md:mx-auto mx-7 font-["Normal"]'>Have a different question and can’t find the answer you’re looking for? Reach out to our support team by <a href='/' class='text-royalblue no-underline font-["Semi"]'>sending us an email</a> or asking question below and 
          we’ll get back to you as soon as we can.</div>

          <div class='self-stretch flex flex-col items-center justify-center'>
                  <div class='relative'>
                          <input placeholder='Ask a question' class='mt-4 md:w-96 w-60 placeholder:text-gray-400 outline-0 px-5 font-["Face"] py-5 border-solid rounded-full border-gainsboro border-solid' />
                          <div class='absolute bg-royalblue md:flex hidden text-sm px-5 top-6 right-2 text-[#fff] py-3 rounded-full font-["Semi"]'>Ask Question</div>
                          <div class='absolute bg-royalblue md:hidden flex text-sm px-5 top-6 right-2 text-[#fff] py-3 rounded-full font-["Semi"]'>Ask</div>

                  </div>
                  <div class='mt-3 text-center text-sm font-["Face"] text-gray-400'>If question is appropiate it will be posted</div>
          </div>

          <div class='self-stretch mt-7 p-5 gap-5 grid md:grid-cols-3'>
            {FAQs.map(item => (
              <div class='p-10 bg-royalblue rounded-xl self-stretch'>
               
                  <div class='text-lg font-["Bold"] text-[#fff]'>{item.question}</div>
                  <div class='font-["Face"] text-[#fff] mt-2'>{item.answer}</div>

             
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
