import React, { useState } from 'react';
import EditableText from './EditableText'

const ColorEditableText = ({
    font,
    fontSize,
    initialText,
    picker,
    onTextChange,
    styles,
    onColorChange,
    textColor,
    defaultColor,
}) => {

    const [pickerVisible, setPickerVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
  

   
    return (
        <div>
            <div class='relative'>
                <div onClick={() => setIsOpen(true)}>
                    <EditableText
                        font={font}
                        fontSize={fontSize}
                        color={textColor}
                        styles={styles}
                        initialText={initialText}
                        onTextChange={(editedText) => onTextChange(editedText)}
                        onColorChange={(color) => onColorChange(color)}
                        onClick={() => setPickerVisible(`${picker}`, true)}
                    >
                        {initialText}
                    </EditableText>
                </div>

            </div>
          

           
        </div>
    );
};

export default ColorEditableText;