import React from 'react'
import { Tab } from '@headlessui/react'
import { gql, useMutation } from '@apollo/client'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Feedback } from '../../Routes/Landing'
const Signin = gql`
mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			token
            user{
                verified
            }
		}
	}
    `

const Register = gql`
mutation signup($email: String!, $password: String!, $name: String!, $stripe_id: String!, $stripe_email: String, $stripe_name: String) {
		signup(email: $email, password: $password, name: $name, stripe_id: $stripe_id, stripe_email: $stripe_email, stripe_name: $stripe_name ) {
			token
		}
	}
    `

const Login = () => {
    const navigate = useNavigate()
    const Auth = [
        { name: 'Sign in' },
        { name: 'Sign up' }
    ]
    const [register] = useMutation(Register);



    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email Required"),
        password: Yup.string().max(20, "Must be 20 characters or less").required("Password Required"),
        confirmPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
        name: Yup.string().max(15, "Must be 15 characters or less").required("Name Required")
    })
    const [login, { error }] = useMutation(Signin)
    const handleSignIn = async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
            const response = await login({
                variables: values,
            });

            const token = response.data.login.token;
            await localStorage.setItem("token", token);

            const isVerified = response.data.login.user.verified; // Assuming the verification status is available in the response

            if (!isVerified) {
                // Redirect to the unverified page
                navigate('/verification');
            } else {
                // Redirect to the dashboard if the user is verified
                navigate('/dashboard');
            }
        } catch (error) {
            // Handle authentication failure (e.g., show an error message)
            console.error("Authentication failed", error);
        } finally {
            setSubmitting(false);
        }
    };
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            <div class='w-full flex items-center bg-royalblue h-screen'>
                  <div class='fixed top-0 w-full'>
                         <a href='/' class='self-stretch flex items-center justify-between 2xl:px-10 xs:m-5 m-5 2xl:m-0 2xl:py-5'>
                                <img src='/head.svg' class='h-9'/>
                         </a>
                  </div>
               <Feedback blue={false} />

             
                        <div class='xs:max-w-md m-5 p-10 xs:mx-auto bg-[#fff] shadow-lg border-[1px] border-gainsboro rounded-xl border-solid'>

                            <div className='flex items-center justify-center flex-col gap-5'>
                                <img src='/union5.svg' className='xl:h-14 lg:h-12 md:h-10 h-9' />
                                <div class='flex flex-col gap-2 xs:mb-7 mb-0'>
                                    <div className='xl:text-4xl lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl text-2xl font-["Bold"] text-center'>Welcome back!</div>
                                    <div class='font-["Face"] text-center text-gray-200'>Welcome back, get started by entering details</div>
                                </div>
                               
                            </div>
                   
                                <Formik

                                    initialValues={{
                                        email: '',
                                        password: '',


                                    }}
                                    onSubmit={handleSignIn}
                                >
                                    <Form >

                                        <div className='mt-5 flex flex-col'>
                                            <label style={{ fontFamily: 'Semi' }} htmlFor="email" className="ml-2 mb-3 leading-6 text-black">
                                                Email
                                            </label>


                                            <Field
                                                id="email"
                                                name="email"
                                                autoComplete="current-password"
                                                required
                                                className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                />

                                        </div>
                                        <div className='my-5 flex flex-col'>
                                            <label style={{ fontFamily: 'Semi' }} htmlFor="password" className="ml-2 mb-3 leading-6 text-black">
                                                Password
                                            </label>
                                            <Field
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"
                                                />

                                        </div>
                                        {error && <div 
                                            className="text-[#fff] bg-red-500 font-['Face'] p-3 border-2 mt-3 mb-5 rounded-lg">
                                            {error.message}
                                        </div>}
                                        <button type='submit' className='w-full text-center block py-5 outline-0 border-0 rounded-full bg-royalblue text-white' style={{ fontFamily: 'Bold' }}>Continue <span aria-hidden="true">&rarr;</span></button>
                                        <div className='font-["Face"] text-center mt-4 text-sm'>Dont have an account? <a href='/register' className='text-royalblue font-["Semi"] no-underline'>Sign up</a></div>

                                    </Form>


                                </Formik>
                    </div>
                </div>

     




        </>
    )
}

export default Login