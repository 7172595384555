import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { ME_QUERY } from '../Data/Me'
import { Banner, Bottom, Items } from './DataProcessor';
import { FrameComponent, Head, Logout, User } from './DataProcessor';
import SimpleChart from '../Components/Ana';
import { FaEye, FaEyeSlash, FaMousePointer, FaRegHandPointer, FaRegHeart, FaShoppingBag, FaShoppingCart } from 'react-icons/fa';
import OrdersChart from '../Components/OrdersChart';
import { UisEllipsisH } from '\'';
import OrdersChart2 from '../Components/OrdersChart2';
import Fulfillment from '../Templates/Fulfillment';
import { ArrowsUpDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { BsTag } from 'react-icons/bs';





const Orders = () => {

    const { data, error, loading } = useQuery(ME_QUERY)
    const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);
    const [selectedPost, setSelectedPost] = useState(null);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [fulfilledCount, setFulfilledCount] = useState(0);
    const [unfulfilledCount, setUnfulfilledCount] = useState(0);

    useEffect(() => {
        if (data) {
            const now = new Date();
            const last30Days = new Date(now.setDate(now.getDate() - 30));

            const recentPayouts = data.me.Payouts.filter(payout => {
                const payoutDate = new Date(payout.createdAt);
                return payoutDate >= last30Days;
            });

            const total = recentPayouts.reduce((acc, payout) => acc + payout.amount, 0);
            setTotalRevenue(total);

            const fulfilledPayouts = data.me.Payouts.filter(payout => payout.fulfilled);
            setFulfilledCount(fulfilledPayouts.length);

            const unfulfilledPayouts = data.me.Payouts.filter(payout => !payout.fulfilled);
            setUnfulfilledCount(unfulfilledPayouts.length);
        }
    }, [data]);



    const openDialog = (item) => {
        setSelectedPost(item);

    };
    const closeDialog = () => {
        setSelectedPost(null);
    };
    const totalPay = data?.me?.Payouts ? sumPropertyValue(data.me.Payouts, 'amount') : 0;
    if (error) return <div>{error.message}</div>
    if (loading) return <div>Loading</div>
    return (
        <div>
            <Banner />

            <div className="w-full fixed top-10 rounded-xl bg-main-color-white flex flex-row items-start justify-start leading-[normal] tracking-[normal] mq1050:pl-5 mq1050:pr-5 mq1050:box-border">
                <div class='fixed bottom-5 right-5 flex items-center justify-center rounded-full h-14 w-14 bg-royalblue'>
                    <img src='/Comment.svg' class='w-7 h-7' />
                </div>

                <div className="relative w-[280px] border-r-[1px] border-0 border-solid border-gainsboro bg-main-color-white box-border flex flex-col items-start justify-between overflow-y-auto min-h-screen">
                    <Head />
                    <div className="self-stretch flex flex-col pt-6 items-start justify-start px-[23px] gap-[15px] shrink-0 [debug_commit:f6aba90] border-t-[1px] border-solid border-whitesmoke-200 mq750:pt-5 mq750:pb-[183px] mq1050:pt-[21px] mq1050:pb-[281px] border-0 mq450:gap-[18px] overflow-y-auto flex-grow">
                        <User data={data.me} />
                        <Items isOrders={true} data={data.me} />
                    </div>
                    <div className="self-stretch flex-shrink-0">
                        <Bottom allData={data.me} />
                    </div>
                </div>
                <main className="flex-1 flex flex-col w-full h-screen items-start justify-start gap-[27px] max-w-[calc(100%_-_280px)] mq1050:max-w-full">
                    <FrameComponent data={data.me} />
                    <section className="self-stretch flex flex-row items-start justify-start py-0 px-8  overflow-y-scroll  h-full box-border g-full max-w-full text-left text-11xl text-gray1-200 font-body-medium-medium">
                        <div className="w-full flex flex-col items-start justify-start gap-[24px] max-w-full">
                            <div className="w-full flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                                        <div className="flex flex-row w-full justify-between">
                                            <div>
                                                <h1 className="m-0 relative text-inherit leading-[48px] font-['Bold'] mq1050:text-5xl mq1050:leading-[38px] mq450:text-lg mq450:leading-[29px]">
                                                    Orders
                                                </h1>
                                                <div className="relative text-sm mt-1 font-['Face'] text-darkgray-300">
                                                    Welcome back, username
                                                </div>
                                            </div>
                                            <div>
                                                <div className="h-10 w-10 rounded-[48px] bg-main-color-white box-border flex flex-row items-center justify-center py-2 px-1.5 border-[2px] border-dashed border-gainsboro">
                                                    <img
                                                        className="h-6 w-6 relative overflow-hidden shrink-0"
                                                        alt=""
                                                        src="/icon-3.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='w-full mt-[24px]  flex flex-col'>
                                    <div class='grid grid-cols-4 h-20 gap-5 mb-5 sm:mb-9'>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaShoppingCart class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Orders</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>The number of orders</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaEye class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Fulfilled </div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>All fulfilled orders</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaEyeSlash class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Unfulfilled</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>All unfulfilled orders</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue shrink-0 w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaMousePointer class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>30 Day</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>Revenue made in the last 30 days</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class='flex items-center space-x-5 w-full'>
                                        <div class='border-[1px] w-full border-solid h-full rounded-xl border-gainsboro flex flex-col'>
                                            <div class='flex flex-col items-start px-5 pt-3'>
                                                <div class='w-full flex items-center justify-between'>
                                                    <div>
                                                        <div class='text-base text-black font-["Semi"]'>Orders</div>
                                                    </div>
                                                    <div>
                                                        <div class='border-solid text-sm px-2 py-1 font-["Semi"] flex items-center rounded-lg border-gainsboro border-[1px]'>
                                                            {data.me.Payouts.length} Orders
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='px-1 flex-grow'>
                                                <OrdersChart />
                                            </div>
                                        </div>
                                        <div class='border-[1px] w-full border-solid h-full rounded-xl border-gainsboro flex flex-col'>
                                            <div class='flex flex-col items-start px-5 pt-3'>
                                                <div class='w-full flex items-center justify-between'>
                                                    <div>
                                                        <div class='text-base text-black font-["Semi"]'>Orders per product</div>
                                                    </div>
                                                    <div>
                                                        <div class='border-solid text-sm px-2 py-1 font-["Semi"] flex items-center rounded-lg border-gainsboro border-[1px]'>
                                                            {data.me.Payouts.length} Orders
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='px-1 flex-grow'>
                                                <OrdersChart2 />
                                            </div>
                                        </div>
                                    </div>
                                    <div class='flex flex-col w-full text-[15px] rounded-xl'>
                                        <div class='text-[#000] font-["Semi"] text-sm rounded-[10px] justify-between flex items-center px-4 my-5'>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Product</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Status</div>
                                            <div class='text-center  md:hidden 2xl:flex w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Category</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Clicks</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' /> Price</div>
                                            <div class='text-center w-full'>More</div>
                                        </div>
                                        {data.me.Payouts.length === 0 ? (
                                            <div class='self-stretch rounded-xl p-14 flex items-center justify-center border-gainsboro round-xl border-[1px] border-solid'>
                                                <div class='font-["Semi"]'>You 0 orders</div>
                                            </div>
                                        ) : (
                                            <div class='border-gainsboro rounded-xl border-[1px] border-solid'>
                                                {data.me.Payouts.map(item => (
                                                    <div class={`border-solid border-gainsboro ${data?.me?.Payouts?.length === 1 ? '' : 'border-b-[1px]'} border-[0px]`}>

                                                        <div className="flex flex-row p-4 items-center justify-between gap-[20px]">
                                                            <div className="w-full flex flex-row items-center justify-start py-0 px-0 box-border gap-[16px]">
                                                                {!item?.Product?.thumbnail || item?.Product?.thumbnail === "" ? (
                                                                    <div
                                                                        className="h-10 flex items-center justify-center w-10 rounded-lg relative overflow-hidden bg-royalblue shrink-0"


                                                                    >
                                                                        <FaShoppingBag class='w-5 h-5 text-white' />
                                                                    </div>
                                                                ) : (
                                                                    <img
                                                                        className="h-10 w-10 rounded-lg relative overflow-hidden shrink-0"
                                                                        loading="lazy"
                                                                        alt=""
                                                                        src={item?.Product?.thumbnail}
                                                                    />
                                                                )}
                                                                <div className="flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px]">
                                                                    <div className="relative text-[#000] text-sm font-['Semi'] whitespace-nowrap">
                                                                        {item?.Product?.title}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class='w-full justify-center items-center flex'>
                                                                {item?.Product?.setDeleted === true ? (
                                                                    <div class='flex items-center gap-2 text-[13px] font-["Semi"]'><div class='bg-orange-400 w-1.5 h-1.5 rounded-full' /> Inactive</div>
                                                                ) : (
                                                                    <div class='flex items-center gap-2 text-[13px] font-["Semi"]'><div class='bg-green-400 w-1.5 h-1.5 rounded-full' /> Active</div>
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col  md:hidden 2xl:flex flex items-center justify-center w-full font-['Semi'] text-gray-400 text-[13px]">
                                                                {item?.Product?.isDonation === true && 'Donation'}
                                                                {item?.Product?.isEbook === true && 'Donation'}
                                                                {item?.Product?.isDigitalDownload === true && 'Donation'}
                                                                {item?.Product?.isAudio === true && 'Donation'}
                                                                {item?.Product?.isCourse === true && 'Donation'}
                                                                {item?.Product?.isPhysical === true && (item?.Product?.isClothing === true ? 'Clothing' : 'Merchandise')}

                                                            </div>
                                                            <div class='flex w-full items-center justify-center gap-2 flex-row'>
                                                                <FaRegHandPointer class='text-gray-300' />
                                                                <div className='text-sm text-[#000] font-["Semi"]'>
                                                                    {item?.Product?.points}
                                                                </div>
                                                            </div>
                                                            <div className="w-full flex flex-col text-sm font-['Semi'] items-center justify-center pt-0 px-0 pb-px box-border text-right text-lg">
                                                                {item?.Product?.isDonation === true ? (
                                                                    <div class='flex items-center gap-2'>
                                                                        <FaRegHeart class='text-gray-300' />
                                                                        Donation
                                                                    </div>
                                                                ) : (
                                                                    <div class='flex items-center gap-2'>
                                                                        <BsTag class='text-gray-300' />
                                                                        ${item.amount}.00
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex w-full justify-center items-center">

                                                                <div onClick={() => openDialog(item)} className="relative cursor-pointer text-xs inline-block py-2.5 px-[19px] border-solid border-[1px] border-gainsboro  rounded-full items-center justify-center gap-[12px] font-['Bold'] text-[#000]">
                                                                    Fulfill
                                                                </div>
                                                                <Fulfillment item={selectedPost} isOpen={!!selectedPost} onClose={closeDialog} />

                                                            </div>

                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </main>
            </div>
        </div>

    )
}

export default Orders