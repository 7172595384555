import React, { useState } from 'react'
import { ME_QUERY } from '../Data/Me'
import { useQuery } from '@apollo/client'
import { ChevronRightIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Banner, Bottom, FrameComponent, Head, Logout, User } from './DataProcessor';
import SimpleChart from '../Components/Ana';
import { useEffect } from 'react';
import { Items } from './DataProcessor';
import Add from './Add';
import { FaDollarSign, FaHandPointer, FaRegHandPointer, FaShoppingBag, FaShoppingCart, FaUser } from 'react-icons/fa';
import { ArrowsUpDownIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import Subscribe from '../Components/Subscribe';


const Analytics = () => {

    const { data, error, loading } = useQuery(ME_QUERY)

    const [cumulativePayouts, setCumulativePayouts] = useState({});
    function getTimeAgoString(createdAt) {
        const currentDate = new Date();
        const createdAtDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdAtDate.getTime();

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (seconds < 60) {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        } else if (minutes < 60) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (hours < 24) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (days < 30) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (months < 12) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else {
            return `${years} year${years !== 1 ? 's' : ''} ago`;
        }
    }
    useEffect(() => {

        if (!loading && !error && data && data.me && data.me.createdAt && data.me.Payouts) {
            const accountCreationDate = new Date(data.me.createdAt);
            const payouts = data.me.Payouts;

            let cumulativeData = {};

            // Initialize with $0 on the account creation date
            cumulativeData[accountCreationDate.toISOString().split('T')[0]] = 0;

            // Calculate cumulative payouts
            payouts.forEach((payout) => {
                const payoutDate = new Date(payout.createdAt);

                // Include payouts that occurred on or after the account creation date
                if (payoutDate >= accountCreationDate) {
                    const dateKey = payoutDate.toISOString().split('T')[0];

                    if (!cumulativeData[dateKey]) {
                        cumulativeData[dateKey] = 0;
                    }

                    cumulativeData[dateKey] += payout.amount;
                }
            });

            setCumulativePayouts(cumulativeData);
        }
    }, [loading, error, data]);

    const [averageSalesPerDay, setAverageSalesPerDay] = useState(0);

    useEffect(() => {
        if (!loading && !error && data) {
            // Process the data to calculate total sales and count of days
            const salesPerDay = {};
            data.me.Payouts.forEach(payout => {
                const createdAt = new Date(payout.createdAt).toLocaleDateString();
                if (!salesPerDay[createdAt]) {
                    salesPerDay[createdAt] = 0;
                }
                salesPerDay[createdAt] += payout.amount;
            });

            // Calculate total sales and count of days
            const totalSales = Object.values(salesPerDay).reduce((acc, cur) => acc + cur, 0);
            const numberOfDays = Object.keys(salesPerDay).length;

            // Calculate average sales per day
            const average = numberOfDays > 0 ? totalSales / numberOfDays : 0;
            setAverageSalesPerDay(average);
        }
    }, [loading, error, data]);
    const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

    const totalPay = data?.me?.Payouts ? sumPropertyValue(data.me.Payouts, 'amount') : 0;

    const [averageOrdersPerDay, setAverageOrdersPerDay] = useState(0);
    const [percentageWidth, setPercentage] = useState(0)
    useEffect(() => {
        if (!loading && !error && data) {
            // Process the data to count orders per day
            const ordersPerDay = {};
            data.me.Payouts.forEach(payout => {
                const createdAt = new Date(payout.createdAt).toLocaleDateString();
                if (!ordersPerDay[createdAt]) {
                    ordersPerDay[createdAt] = 0;
                }
                ordersPerDay[createdAt]++;
            });
            const totalAmount2 = data.me.Payouts.reduce((acc, payout) => acc + payout.amount, 0);

            const productRevenue = data.me.Payouts.reduce((acc, payout) => {
                const product = payout.Product.title;
                const amount = payout.amount;
                acc[product] = (acc[product] || 0) + amount;
                return acc;
            }, {});

            const productPercentage = Object.keys(productRevenue).reduce((acc, product) => {
                acc[product] = (productRevenue[product] / totalAmount2) * 100;
                return acc;
            }, {});

            setPercentage(productPercentage)


            // Calculate total number of orders and count of days
            const totalOrders = Object.values(ordersPerDay).reduce((acc, cur) => acc + cur, 0);
            const numberOfDays = Object.keys(ordersPerDay).length;

            // Calculate average orders per day
            const average = numberOfDays > 0 ? totalOrders / numberOfDays : 0;
            setAverageOrdersPerDay(average);
        }
    }, [loading, error, data]);
    const [totalClicks, setTotalClicks] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    useEffect(() => {
        if (!loading && !error && data) {
            // Calculate total points from posts
            const pointsSum = data.me.posts.reduce((acc, post) => acc + (post.points || 0), 0);
            setTotalPoints(pointsSum);
        }
    }, [loading, error, data]);

    useEffect(() => {
        if (!loading && !error && data) {
            // Calculate total clicks from links
            const clicksSum = data.me.Links.reduce((acc, link) => acc + link.clicks, 0);
            setTotalClicks(clicksSum);
        }
    }, [loading, error, data]);

    const [open, setOpen] = useState(false)

    if (error) return <div>{error.message}</div>
    if (loading) return <div>Loading</div>



    return (
        <div>
            <Banner />

            <div className="w-full fixed top-10 rounded-xl bg-main-color-white flex flex-row items-start justify-start leading-[normal] tracking-[normal] mq1050:pl-5 mq1050:pr-5 mq1050:box-border">
                <div class='fixed bottom-5 right-5 flex items-center justify-center rounded-full h-14 w-14 bg-royalblue'>
                    <img src='/Comment.svg' class='w-7 h-7' />
                </div>

                <div className="relative w-[280px] border-r-[1px] border-0 border-solid border-gainsboro bg-main-color-white box-border flex flex-col items-start justify-between overflow-y-auto min-h-screen">
                    <Head />
                    <div className="self-stretch flex flex-col pt-6 items-start justify-start px-[23px] gap-[15px] shrink-0 [debug_commit:f6aba90] border-t-[1px] border-solid border-whitesmoke-200 mq750:pt-5 mq750:pb-[183px] mq1050:pt-[21px] mq1050:pb-[281px] border-0 mq450:gap-[18px] overflow-y-auto flex-grow">
                        <User data={data.me} />
                        <Items isAnalytics={true} data={data.me} />
                    </div>
                    <div className="self-stretch flex-shrink-0">
                        <Bottom allData={data.me} />
                    </div>
                </div>

                <main className="flex-1 flex flex-col w-full h-screen items-start justify-start gap-[27px] max-w-[calc(100%_-_280px)] mq1050:max-w-full">
                    <FrameComponent data={data.me} />
                    <section className="self-stretch flex flex-row items-start justify-start py-0 px-8  overflow-y-scroll  h-full box-border g-full max-w-full text-left text-11xl text-gray1-200 font-body-medium-medium">
                        <div className="w-full flex flex-col items-start justify-start gap-[24px] max-w-full">
                            <div className="w-full flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                                        <div className="flex flex-row w-full justify-between">
                                            <div>
                                                <h1 className="m-0 relative text-inherit leading-[48px] font-['Bold'] mq1050:text-5xl mq1050:leading-[38px] mq450:text-lg mq450:leading-[29px]">
                                                    Analytics
                                                </h1>
                                                <div className="relative text-sm mt-1 font-['Face'] text-darkgray-300">
                                                    Welcome back, username
                                                </div>
                                            </div>
                                            <div>
                                                <div className="h-10 w-10 rounded-[48px] bg-main-color-white box-border flex flex-row items-center justify-center py-2 px-1.5 border-[2px] border-dashed border-gainsboro">
                                                    <img
                                                        className="h-6 w-6 relative overflow-hidden shrink-0"
                                                        alt=""
                                                        src="/icon-3.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='mt-[24px] w-full mb-3'>
                                    <div class='grid grid-cols-4 gap-5 h-20 md:mb-3 w-full'>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaHandPointer class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Clicks</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>Overall click data</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaUser class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Audience</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>Audience list</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaDollarSign class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Revenue</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>Made money</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='border-gainsboro p-5 rounded-xl border-[1px] border-solid'>
                                            <div class='flex items-center justify-between w-full'>
                                                <div class='flex flex-row space-x-4'>
                                                    <div class='bg-royalblue w-9 h-9 rounded-full flex items-center justify-center'>
                                                        <FaShoppingCart class='text-[#fff] w-4 h-4' />
                                                    </div>
                                                    <div>
                                                        <div class='text-sm text-[#000] font-["Semi"]'>Orders</div>
                                                        <div class='text-xs font-["Face"] text-gray-400 mt-1'>Number of orders</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class='border-[1px] border-gainsboro border-solid rounded-lg flex items-center justify-center h-8 w-8'>
                                                        <ChevronRightIcon class='text-black w-4 h-4' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class='border-[1px] border-solid h-[300px] w-full rounded-xl border-gainsboro'>
                                    <div class='flex flex-col items-start px-5 pt-5'>
                                        <div class='w-full flex items-center justify-between mb-2'>
                                            <div class='text-xl font-["Bold"] text-[#000]'>Revenue</div>
                                            <div class='border-gainsboro border-solid flex items-center gap-3 border-[1px] text-[#000] text-sm px-4 font-["Semi"] py-2 rounded-xl'>
                                                Deposit
                                                <BanknotesIcon class='text-[#000] w-3 h-3' />
                                            </div>

                                        </div>
                                        <div class='text-2xl text-[#000] font-["Semi"]'>${totalPay}.00 USD</div>
                                    </div>

                                    <SimpleChart data={cumulativePayouts} />
                                </div>

                                <div class='mt-4 w-full flex gap-5'>
                                    <div class='w-1/2 rounded-xl'>

                                        <div class=' mb-2 text-[#000] font-["Semi"] flex items-center px-4 pb-3 rounded-[10px] text-sm justify-between'>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Product</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Clicks</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Status</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'><ArrowsUpDownIcon class='w-4 h-4' />Sales</div>
                                            <div class='text-center w-full flex items-center justify-center gap-2'>More</div>
                                        </div>
                                        {data.me.anaProducts.length === 0 ? (
                                            <div class='border-[1px] border-solid flex items-center justify-center h-full border-gainsboro p-4 rounded-xl'>
                                                <div class='w-40 flex justify-center items-center flex-col'>
                                                    <div class='text-[#000] font-["Semi"] mb-3'>Start selling by creating a product</div>
                                                    <Add addSign={true} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div class='flex border-[1px] border-solid border-gainsboro rounded-xl flex-col'>
                                                {data.me.anaProducts.map(item => {
                                                    const productTitle = item.title;

                                                    const percentage = percentageWidth[productTitle] || 0;
                                                    return (
                                                        <div class={`border-solid border-gainsboro border-[0px]`}>

                                                            <div class='justify-between px-4 py-2.5 flex items-center'>

                                                                <div class='flex w-full items-center flex-row gap-3'>

                                                                    <div>
                                                                        {!item.thumbnail ? (
                                                                            <div class='relative'>

                                                                                <div class='w-10 h-10 bg-royalblue flex items-center justify-center rounded-[12px]' >
                                                                                    <FaShoppingBag class='text-[#fff] w-4 h-4' />
                                                                                </div>

                                                                            </div>
                                                                        ) : (
                                                                            <div class='relative'>
                                                                                <img src={item.thumbnail} class='w-10 h-10 rounded-[12px]' />

                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                    <div>
                                                                        <div class='text-[13px] text-[#000] text-start line-clamp-1 font-["Bold"]'>{item.title}</div>
                                                                    </div>
                                                                </div>
                                                                <div class='flex w-full justify-center items-center justify-center gap-2 flex-row'>
                                                                    <FaRegHandPointer class='text-gray-300 w-4 h-4' />
                                                                    <div className='text-sm text-[#000] font-["Semi"]'>
                                                                        {item.points}
                                                                    </div>
                                                                </div>
                                                                <div class='w-full flex items-center justify-center'>
                                                                    <div class='bg-green-100 px-4 py-2  inline-block text-green-500 rounded-lg text-xs font-["Bold"]'>
                                                                        Active
                                                                    </div>
                                                                </div>
                                                                <div class='w-full flex items-center space-x-3'>
                                                                    <div class='text-xs text-black font-["Semi"]'>
                                                                        {percentage.toFixed(0)}%
                                                                    </div>
                                                                    <div className="w-full bg-gray-100 h-1.5 overflow-hidden rounded-full relative">
                                                                        <div
                                                                            className="bg-royalblue h-1.5"
                                                                            style={{ width: `${percentage}%` }}
                                                                        ></div>
                                                                    </div>

                                                                </div>
                                                                <div class='w-full flex justify-center'>
                                                                    <div class='text-xs inline-block rounded-full px-3 font-["Semi"] py-2 border-[1px] text-[#000] border-solid border-gainsboro'>Edit</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}


                                    </div>
                                    <div class='grid grid-cols-2 gap-5 w-1/2'>
                                        <div>
                                            <div class='flex flex-col'>
                                                <div class='flex flex-col gap-2 mt-2'>
                                                    <div class='text-[20px] font-["Bold"]'>More analysis</div>
                                                </div>
                                            </div>
                                            <div class='flex flex-col w-full my-4 gap-3'>
                                                <div class='border-solid rounded-xl flex justify-between items-center border-gainsboro border-[1px] p-4'>
                                                    <div class='flex items-center gap-2'>
                                                        <div class='w-8 h-8 bg-royalblue flex items-center justify-center rounded-full'>
                                                            <FaDollarSign class='w-4 h-4 text-[#fff]' />
                                                        </div>
                                                        <div class='font-["Semi"] text-[14px]'>Average sale</div>
                                                    </div>
                                                    <div class='text-[#000] border-solid border-[1px] border-gainsboro text-xs w-12 h-8 flex items-center justify-center rounded-lg font-["Semi"]'>
                                                        ${averageSalesPerDay.toFixed(2)}
                                                    </div>
                                                </div>
                                                <div class='border-solid rounded-xl flex justify-between items-center border-gainsboro border-[1px] p-4'>
                                                    <div class='flex items-center gap-2'>
                                                        <div class='w-8 h-8 bg-royalblue flex items-center justify-center rounded-full'>
                                                            <FaShoppingCart class='w-4 h-4 text-[#fff]' />
                                                        </div>
                                                        <div class='font-["Semi"] text-[14px]'>Average Orders per day</div>
                                                    </div>
                                                    <div class='text-[#000] border-solid border-[1px] border-gainsboro text-xs w-12 h-8 flex items-center justify-center rounded-lg font-["Semi"]'>
                                                        {averageOrdersPerDay.toFixed(2)}
                                                    </div>
                                                </div>
                                                <div class='flex flex-row gap-2 items-center text-xs font-["Bold"] px-3'>
                                                    Analysis created by
                                                    <div class='w-5 h-5 rounded-full bg-royalblue '>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class='bg-royalblue p-10 rounded-xl'>
                                                <img src='/Head.svg' class='h-8' />
                                                <div class='my-2 mb-4'>
                                                    <div class='text-3xl font-["Promo"] text-[#fff]'>Get more with pro, upgrade today</div>
                                                </div>
                                                <button onClick={() => setOpen(true)} class='px-5 py-3 bg-[#fff] text-royalblue font-["Bold"] rounded-full border-0'>Get pro</button>
                                            </div>
                                            {open && (
                                                <Subscribe />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>

    )
}

export default Analytics