import React, { useRef, useState } from 'react'
import { ME_QUERY } from '../Data/Me'
import { useQuery, gql, useMutation } from '@apollo/client'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { Tab, Switch, Listbox } from '@headlessui/react'
import { TwitterPicker } from 'react-color'
import { FaTiktok } from 'react-icons/fa'
import link from '../Assets/link.png'
import { useFormik } from 'formik'
import ADD from '../Assets/ADD.png'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { useEffect } from 'react'
import user from '../Assets/user.png'
import { createClient } from '@supabase/supabase-js'
import { EllipsisHorizontalIcon, } from '@heroicons/react/20/solid'
import linkBlack from '../Assets/linkblack.png'
import dotsBlack from '../Assets/dotsblack.png'
import ColorEditableText from './Duo'
import dots from '../Assets/dots.png'
export const UPDATE_FEATURED_POST = gql`
mutation setFeaturedPost($userId: Int!, $postId: Int!) {
  setFeaturedPost(userId: $userId, postId: $postId) {
    id
  }
}
`
const Update = gql`
mutation createWebsite(
 $backgroundColor: String,
 $headerText: String,
 $headerImage: String,
 $heroImage: String,
 $backdropAvatar: Boolean!,
 $bannerText: String,
 $isBackground: Boolean!,
 $basicHero: Boolean!,
 $mediaHero: Boolean!,
 $backdrop: Boolean!,
 $backdropColor: String,
 $background: String,
 $bannerColor: String,
 $headingColor: String,
 $text1Color: String,
 $borderRadius: String,
 $heroSection: Boolean!,
 $newsletterSection: Boolean!,
 $newsletterButton: String,
 $featuredSection: Boolean!,
 $outline: Boolean!,
 $linkSection: Boolean!,
 $textColor: String,
 $iconColor: String,
 $mediaColor: String,
 $productsSection: Boolean!,
 $socialsSection: Boolean!,
 $banner: Boolean!,
 $instagramShown: Boolean!,
 $linkSectionText: String,
 $tiktokShown: Boolean!,
 $facebookShown: Boolean!,
 $twitterShown: Boolean!,
 $text2Color: String,
 $text3Color: String,
 $instagram: String,
 $facebook: String,
 $tiktok: String,
 $twitter: String,
 $aboutColorName: String,
 $authorColorDescription: String,
 $featuredColorHeading: String,
 $productsColorDescription: String,
 $productsColorHeading: String,
 $aboutColorHeading: String,
 $aboutColorDescription: String,
 $featuredColorDescription: String,
 $heading1: String,
 $text1: String,
 $text2: String,
 $text3: String,
 $aboutHeading: String,
 $aboutDescription: String,
 $aboutName: String,
 $authorDescription: String,
 $featuredName: String,
 $featuredDescription: String,
 $productsName: String,
 $template: Int,
 $productsDescription: String,
 $heroImageText: String,
 $heroImageButton: String,
 $newsletter: Boolean!,
 $featured1: Boolean!,
 $featured2: Boolean!,
 $featured3: Boolean!,
 $newsletterText: String,
 $newsletterDescription: String,
 $youtubeName: String,
 $twitterName: String,
 $facebookName: String,
 $tiktokName: String,
 $font: String,
 $basic: Boolean!,
 $detailed: Boolean!,
 $twitterStyle: Boolean!,
 $link: Boolean!,
 $linkPlus: Boolean!,
 $thumbnail: Boolean!
) {
  createWebsite(
    backgroundColor: $backgroundColor,
    headerText: $headerText,
    headerImage: $headerImage,
    backdropAvatar: $backdropAvatar,
    newsletterButton: $newsletterButton,
    background: $background,
    isBackground: $isBackground,
    mediaHero: $mediaHero,
    outline: $outline,
    basicHero: $basicHero,
    heroImage: $heroImage,
    bannerText: $bannerText,
    linkSectionText: $linkSectionText,
    borderRadius: $borderRadius,
    iconColor: $iconColor,
    mediaColor: $mediaColor,
    featured1: $featured1,
    font: $font,
    featured2: $featured2,
    featured3: $featured3,
    textColor: $textColor,
    bannerColor: $bannerColor,
    headingColor: $headingColor,
    text1Color: $text1Color,
    banner: $banner,
    featuredSection: $featuredSection,
    heroSection: $heroSection,
    backdrop: $backdrop,
    backdropColor: $backdropColor,
    socialsSection: $socialsSection,
    productsSection: $productsSection,
    newsletterSection: $newsletterSection,
    linkSection: $linkSection,
    instagramShown: $instagramShown,
    tiktokShown: $tiktokShown,
    facebookShown: $facebookShown,
    twitterShown: $twitterShown,
    text2Color: $text2Color,
    text3Color: $text3Color,
    instagram: $instagram,
    facebook: $facebook,
    tiktok: $tiktok,
    twitter: $twitter,
    aboutColorName: $aboutColorName,
    authorColorDescription: $authorColorDescription,
    featuredColorHeading: $featuredColorHeading,
    productsColorDescription: $productsColorDescription,
    productsColorHeading: $productsColorHeading,
    aboutColorHeading: $aboutColorHeading,
    aboutColorDescription: $aboutColorDescription,
    featuredColorDescription: $featuredColorDescription,
    heading1: $heading1,
    text1: $text1,
    text2: $text2,
    text3: $text3,
    aboutHeading: $aboutHeading,
    aboutDescription: $aboutDescription,
    aboutName: $aboutName,
    authorDescription: $authorDescription,
    featuredName: $featuredName,
    featuredDescription: $featuredDescription,
    productsName: $productsName,
    template: $template,
    productsDescription: $productsDescription,
    heroImageText: $heroImageText,
    heroImageButton: $heroImageButton,
    newsletter: $newsletter,
    newsletterText: $newsletterText,
    newsletterDescription: $newsletterDescription,
    youtubeName: $youtubeName,
    twitterName: $twitterName,
    facebookName: $facebookName,
    tiktokName: $tiktokName,
    basic: $basic,
    detailed: $detailed,
    twitterStyle: $twitterStyle,
    link: $link,
    linkPlus: $linkPlus,
    thumbnail: $thumbnail
  ) {
    id
  }
}


`



const Edit = () => {


  // Display the array of products in the console





  const { data, error, loading } = useQuery(ME_QUERY)
  const [backdrop, setBackdrop] = useState(false)

  const [textValues, setTextValues] = useState({
    heading1: "Add Text Here",
    text1: "Add Text Here",
    text2: data?.me?.bio,
    heroImage: "",
    headerImage: data?.me?.avatar,
    heroImageButton: "Add Text Here",
    heroImageText: "Add Text Here",
    newsletterText: "Add Text Here",
    background: "Add Text Here",
    newsletterButton: "Add Text Here",
    newsletterDescription: "Add Text Here",
    text3: "",
    headerText: "Add Text Here",
    aboutHeading: "Add Text Here",
    aboutDescription: "Add Text Here",
    aboutName: data?.me?.name,
    authorDescription: "Add Text Here",
    featuredName: "Add Text Here",
    featuredDescription: '',
    productDescription: '',
    bannerText: "Add Text Here",
    productsName: "Add Text Here",
    instagram: '',
    facebook: '',
    tiktok: '',
    twitter: ''
  });
  const [colorPickers, setColorPickers] = useState({
    backgroundColor: "#f3f4f6",
    backdropColor: '#000',
    bannerColor: '',
    headerText: "#000",
    headingColor: "",
    mediaColor: "#fff",
    textColor: "#000",
    text1Color: "",
    text2Color: "#9CA3AF",
    text3Color: "",
    aboutColorHeading: "",
    newsletterButton: "#f3f4f6",
    aboutColorDescription: "#9CA3AF",
    authorColorDescription: "",
    aboutColorName: "#fff",
    featuredColorHeading: "",
    featuredColorDescription: "#9CA3AF",
    productsColorHeading: "",
    productsColorDescription: "#9CA3AF"
  });
  const [linksData, setLinksData] = useState([
    { linkText: '', link: '', image: '' }, // Initial empty link data
  ]);

  const [borderRadius, setBorderRadius] = useState('')


  const [sections, setSections] = useState({
    hero: false,
    newsletter: false,
    featured: false,
    links: data?.me?.Links?.length === 0 ? false : true,
    products: data?.me?.posts?.length === 0 ? false : true,
    socials: false
  })

  const [hero, setHero] = useState({
    basic: true,
    media: false
  })

  useEffect(() => {
    // Check for errors or loading state
    if (!loading && !error && data?.me?.Website) {
      // Update state with data
      setTextValues({
        heading1: "Add Text Here",
        text1: "Add Text Here",
        text2: data?.me?.bio,
        heroImage: "",
        headerImage: data?.me?.avatar,
        heroImageButton: "Add Text Here",
        heroImageText: "Add Text Here",
        newsletterText: "Add Text Here",
        background: "Add Text Here",
        newsletterButton: "Add Text Here",
        newsletterDescription: "Add Text Here",
        text3: "",
        headerText: "Add Text Here",
        aboutHeading: "Add Text Here",
        aboutDescription: "Add Text Here",
        aboutName: data?.me?.name,
        authorDescription: "Add Text Here",
        featuredName: "Add Text Here",
        featuredDescription: '',
        productDescription: '',
        bannerText: "Add Text Here",
        productsName: "Add Text Here",
        instagram: '',
        facebook: '',
        tiktok: '',
        twitter: ''

      });

      setColorPickers({
        backgroundColor: "#f3f4f6",
        bannerColor: '',
        backdropColor: '#000',
        headerText: "#fff",
        headingColor: "#fff",
        mediaColor: "#fff",
        textColor: "#000",
        text1Color: "",
        text2Color: "#9CA3AF",
        text3Color: "",
        aboutColorHeading: "",
        newsletterButton: "#222",
        aboutColorDescription: "#9CA3AF",
        authorColorDescription: "",
        aboutColorName: "#fff",
        featuredColorHeading: "",
        featuredColorDescription: "#9CA3AF",
        productsColorHeading: "",
        productsColorDescription: "#9CA3AF"
      })

      setSections({
        hero: false,
        newsletter: false,
        featured: false,
        links: data?.me?.Links?.length === 0 ? false : true,
        products: data?.me?.posts?.length === 0 ? false : true,
        socials: false
      })

      setHero({
        basic: false,
        media: true,

      })
    }
  }, [loading, error, data]);
  const [style, setStyle] = useState({
    outline: false,
    backdrop: false,
    color: true
  })

  const handleStyle = (clickedTab) => {
    setStyle({
      outline: clickedTab === 'outline',
      backdrop: clickedTab === 'backdrop',
      color: clickedTab === 'color'
    });
  };

  const [banner, setBanner] = useState(false)
  const [instagramShown, setInstagramShown] = useState(true)
  const [facebookShown, setFacebookShown] = useState(true)
  const [tiktokShown, setTikTokShown] = useState(true)
  const [twitterShown, setTwitterShown] = useState(true)
  const [activeColorPicker, setActiveColorPicker] = useState(null);


  const [selectedPostId, setSelectedPostId] = useState(data?.me.featuredPost?.post?.id);
  const postId = parseInt(selectedPostId, 10)
  const [updateFeaturedPost] = useMutation(UPDATE_FEATURED_POST, { refetchQueries: [ME_QUERY] });

  const handleSelectFeaturedPost = async () => {
    if (!selectedPostId) {
      alert('Please select a post to set as featured.');
      return;
    }

    try {
      await updateFeaturedPost({
        variables: {
          userId: data.me.id,
          postId: postId,
        },
      });
      alert('Featured post updated successfully!');
    } catch (error) {
      console.error('Error updating featured post:', error);
      alert('An error occurred while updating the featured post.');
    }
  };

  const inputFile = React.useRef(null);

  const inputFile3 = React.useRef(null)
  const [create] = useMutation(Update)
  const [imageLoading, setImageLoading] = useState(false);


  const [image1, setImage1] = useState();
  const [imageLoading1, setImageLoading1] = useState(false)
  const updateHeaderImage = (newImageUrl) => {
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      headerImage: newImageUrl,
    }));
  };

  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'kyroapp');
    setImageLoading(true);
    const res = await fetch('https://api.cloudinary.com/v1_1/dapcizjsz/image/upload', {
      method: 'POST',
      body: data,
    });
    const file = await res.json();

    updateHeaderImage(file.secure_url); // Update the headerImage in state

    setImageLoading(false);
  };


  const Tabs = [
    'Format',
    'Links',
    'Sections',
    'Background',
    'Color',
    'Media',
    'Font',
    'Socials',
    'Featured'
  ]
  // useEffect to update colorPickers when data prop changes


  // Assuming you have the form inputs and other logic set up, I'll provide a simple example using an input field
  const handleFormSubmit = (values) => {
    alert(JSON.stringify(values));
    console.log(values);
    create({
      variables: {
        template: 1,
        instagramShown: instagramShown,
        facebookShown: facebookShown,
        tiktokShown: tiktokShown,
        twitterShown: twitterShown,
        featuredSection: sections.featured,
        newsletter: true,
        textColor: colorPickers.textColor,
        mediaColor: colorPickers.mediaColor,
        isBackground: backgroundBoolean,
        background: textValues.background,
        basicHero: hero.basic,
        mediaHero: hero.media,
        iconColor: iconColor.color,
        socialsSection: sections.socials,
        productsSection: sections.products,
        outline: style.outline,
        backdropAvatar: backdropAvatar,
        backgroundColor: colorPickers.backgroundColor,
        linkSection: sections.links,
        heroSection: sections.hero,
        newsletterSection: sections.newsletter,
        basic: productFormat.basic,
        twitterStyle: productFormat.twitter,
        detailed: productFormat.detailed,
        newsletterButton: colorPickers.newsletterButton,
        link: productFormat.link,
        thumbnail: productFormat.thumbnail,
        linkPlus: productFormat.linkPlus,
        headerText: colorPickers.headerText,
        headingColor: colorPickers.headingColor,
        headerImage: textValues.headerImage,
        heroImage: textValues.heroImage,
        heroImageText: textValues.heroImageText,
        heroImageButton: textValues.heroImageButton,
        backdrop: style.backdrop,
        backdropColor: colorPickers.backdropColor,
        banner: banner,
        bannerText: textValues.bannerText,
        bannerColor: colorPickers.bannerColor,
        text1Color: colorPickers.text1Color,
        font: font,
        text2Color: colorPickers.text2Color,
        text3Color: colorPickers.text3Color,
        featured1: featured.f1,
        featured2: featured.f2,
        featured3: featured.f3,
        newsletterDescription: textValues.newsletterDescription,
        newsletterText: textValues.newsletterText,
        productsColorDescription: colorPickers.productsColorDescription,
        productsColorHeading: colorPickers.productsColorHeading,
        featuredColorHeading: colorPickers.featuredColorHeading,
        authorColorDescription: colorPickers.authorColorDescription,
        aboutName: textValues.aboutName,
        aboutColorName: colorPickers.aboutColorName,
        aboutColorHeading: colorPickers.aboutColorHeading,
        aboutColorDescription: colorPickers.aboutColorDescription,
        featuredColorDescription: colorPickers.featuredColorDescription,
        heading1: textValues.heading1,
        text1: textValues.text1,
        text2: textValues.text2,
        borderRadius: borderRadius,
        text3: textValues.text3,
        linkSectionText: textValues.linkSectionText,
        aboutHeading: textValues.aboutHeading,
        aboutDescription: textValues.aboutDescription,
        authorDescription: textValues.authorDescription,
        featuredName: textValues.featuredName,
        featuredDescription: textValues.featuredDescription,
        productsName: textValues.productsName,
        productsDescription: textValues.productsDescription,
        instagram: textValues.instagram,
        facebook: textValues.facebook,
        twitter: textValues.twitter,
        tiktok: textValues.tiktok
      },
    });
  };
  const [outline, setOutline] = useState(false)
  const [featured, setFeatured] = useState({
    f1: true,
    f2: false,
    f3: false
  })

  const handleFeatured = (clickedTab) => {
    setFeatured({
      f1: clickedTab === 'f1',
      f2: clickedTab === 'f2',
      f3: clickedTab === 'f3',
    });
  };
  function formatString(s) {
    let formatted = '';
    for (let i = 0; i < s.length; i++) {
      if (i > 0 && s[i] === s[i].toUpperCase() && s[i - 1] === s[i - 1].toLowerCase()) {
        formatted += ' ';
      }
      formatted += s[i];
    }
    return formatted;
  }
  const colorPickerRef = useRef(null);
  function FormattedStringComponent({ inputString }) {
    const formattedString = formatString(inputString);

    return <div class='text-xl font-["Bold"] capitalize text-white'>{formattedString}</div>;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('Clicked outside');
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        console.log('Clicked outside of color picker');
        setActiveColorPicker(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  function getFileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();

    if (extension === 'mp4') {
      return 'video';
    } else if (['png', 'jpg', 'jpeg'].includes(extension)) {
      return 'image';
    } else {
      return 'unknown';
    }
  }

  
  const [backgroundImageorColor, setBackgroundImageorColor] = useState({
    backgroundColor: true,
    backgroundImage: false
  })
  const formik = useFormik({
    initialValues: {
      //
    },
    onSubmit: handleFormSubmit, // Use the custom submit handler defined above

  });
  const uploadImage1 = async (e, index) => {
    const files = e.target.files;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'kyroapp');
    setImageLoading1(true);

    const res = await fetch('https://api.cloudinary.com/v1_1/dapcizjsz/image/upload', {
      method: 'POST',
      body: data,
    });

    const file = await res.json();

    const updatedLinksData = [...linksData];
    updatedLinksData[index].image = file.secure_url;
    setLinksData(updatedLinksData);

    setImageLoading1(false);
  };
  const supabase = createClient(
    'https://rbsteedexxccoqrnyczp.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJic3RlZWRleHhjY29xcm55Y3pwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU5MzIwMTksImV4cCI6MjAwMTUwODAxOX0.FT3oCWVeAGjrMRhLTdCFBSOtIdMDeZj6ApuALkJ185A'
  );
  const [font, setFont] = useState("Normal")
  const [pickerVisible, setPickerVisible] = useState({
    background: false,
    bannerColor: false
  });

  const [edit, setEdit] = React.useState(true)

  const handleColorChange = (field, newColor) => {
    setColorPickers((prevState) => ({ ...prevState, [field]: newColor.hex }));
  };
  const [uploading, setUploading] = useState(false);
  const [publicFile, setFile] = useState(null);
  const [fileSource, setFilename] = useState(null)



  const handlePickerVisible = (field, isVisible) => {
    setPickerVisible((prevState) => ({ ...prevState, [field]: true }));
  };

  const handleTextChange = (field, editedText) => {
    setTextValues((prevState) => ({ ...prevState, [field]: editedText }));
  };
  async function uploadFile(event) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.');
      }

      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;
      setFilename(fileName);

      // Upload the file to Supabase storage
      let { error: uploadError } = await supabase.storage.from('conten').upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      // Get the public URL of the uploaded file
      let { data } = await supabase.storage.from('conten').getPublicUrl(`${filePath}`);
      const publicFile = data.publicUrl;

      // Update the heroImage with the public URL
      setFile(publicFile);

      // Update the textValues state with the new heroImage
      setTextValues({
        ...textValues,
        heroImage: publicFile,
      });

      console.log(data.publicUrl);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  const [backgroundUploading, setBackgroundUploading] = useState(false);
  const [backgroundNull, setBackground] = useState(null);
  const [backgroundSource, setBackgroundname] = useState(null)

  async function uploadBackground(event) {
    try {
      setBackgroundUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.');
      }

      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;
      setBackgroundname(fileName);

      // Upload the file to Supabase storage
      let { error: uploadError } = await supabase.storage.from('conten').upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      // Get the public URL of the uploaded file
      let { data } = await supabase.storage.from('conten').getPublicUrl(`${filePath}`);
      const publicFile = data.publicUrl;

      // Update the heroImage with the public URL
      setBackground(publicFile);

      // Update the textValues state with the new heroImage
      setTextValues({
        ...textValues,
        background: publicFile,
      });

      console.log(data.publicUrl);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  const [backgroundBoolean, setBackgroundBoolean] = useState(false)

  const [backdropAvatar, setBackdropAvatar] = useState(false)

  const handleBackground = (clickedTab) => {
    setBackgroundImageorColor({
      backgroundColor: clickedTab === 'backgroundColor',
      backgroundImage: clickedTab === 'backgroundImage',
    });
  };


  const [productFormat, setProductFormat] = useState({
    basic: false,
    thumbnail: true,
    link: false,
    linkPlus: false,
    detailed: false,
    twitter: false
  })



  const handleInputChange = (index, field, value) => {
    const newLinksData = [...linksData];
    newLinksData[index][field] = value;
    setLinksData(newLinksData);
  };

  const [message, setMessage] = useState(false)

  const handleAddLink = () => {
    // Check if the current link is empty
    const isCurrentLinkEmpty = linksData.some(link => link.linkText === '' && link.link === '' && link.image === '');

    if (isCurrentLinkEmpty) {
      setMessage(true)
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    }
    // If the current link is not empty, add a new link
    if (!isCurrentLinkEmpty) {
      setLinksData([...linksData, { linkText: '', link: '', image: '' }]);
    }
  };
  const [format, setFormat] = useState({
    base: false,
    radius: true,
    backdrop: false
  })


  const handleFormat = (clickedTab) => {
    setFormat({
      base: clickedTab === 'base',
      radius: clickedTab === 'radius',
      backdrop: clickedTab === 'backdrop',
    });
  };



  function getFileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();

    if (extension === 'mp4') {
      return 'video';
    } else if (['png', 'jpg', 'jpeg'].includes(extension)) {
      return 'image';
    } else {
      return 'unknown';
    }
  }

  const fileType = getFileType(textValues.heroImage);
  const handleProduct = (clickedTab) => {
    setProductFormat({
      basic: clickedTab === 'basic',
      thumbnail: clickedTab === 'thumbnail',
      link: clickedTab === 'link',
      linkPlus: clickedTab === 'linkPlus',
      detailed: clickedTab === 'detailed',
      twitter: clickedTab === 'twitter'
    });
  };
  const Basic = ({ item }) => {
    return (
      <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} className={`w-full p-1.5 ${style.backdrop === true && 'border-2'} ${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`}>
        <div className='relative'>
          <img className={`w-full ${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`} src={item.thumbnail} alt="Main Image" />
          <img className='absolute w-4 h-4 top-2 right-3' src={dots} alt="Overlay Image" />
        </div>
        <div className="pt-3 pb-2 px-2.5 ">
          <div style={{ color: colorPickers.textColor }} class="font-['Bold'] mb-1 text-sm">{item.title}</div>
          <div style={{ color: colorPickers.textColor }} className="overflow-hidden opacity-50 line-clamp-2 text-white text-sm font-['Face'] leading-[21px]">{item.description}</div>
        </div>
      </div>
    );
  }

  const Thumbnail = ({ data }) => {
    return (
      <div style={{ background: colorPickers.mediaColor, borderColor: (style.backdrop || style.outline) === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} class={`${borderRadius} ${(style.backdrop || style.outline) === true && 'border-2'} w-full flex items-center justify-between mt-2 px-3 py-3`}>
        <img src={data.thumbnail} className={`w-10 h-10 ${borderRadius}`} />
        <div style={{ color: colorPickers.textColor }} className="text-sm line-clamp-1 font-['Bold']">{data.title}</div>


        <EllipsisHorizontalIcon style={{ color: colorPickers.textColor }} class='w-4 h-4' />
      </div>
    );
  }

  const Link = ({ data }) => {
    return (
      <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} class={`${borderRadius} ${style.backdrop === true && 'border-2'} w-full flex items-center justify-between mt-2 px-3 py-3`}>
        <div class='w-5 h-5' />

        <div style={{ color: colorPickers.textColor, fontFamily: 'Semi' }} class='line-clamp-1'>{data.title}</div>

        <EllipsisHorizontalIcon style={{ color: colorPickers.textColor }} class='w-4 h-4' />

      </div>
    );
  }


  const [iconColor, setIconColor] = useState('black');

  const toggleIconColor = () => {
    setIconColor((prevColor) => (prevColor === 'white' ? 'black' : 'white'));
  };


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error.message}</p>;

  return (
    <div class='w-full'>

      <Tab.Group vertical={true}>
        <div class='w-full border-b'>


        </div>
        <div>
          <div class='w-full border-b' />
          <div class='flex max-w-screen-2xl mx-auto'>



            <Tab.List class='w-1/6  flex flex-col h-96 bg-royalblue rounded-xl p-3 '>
              {Tabs.map(item => (
                <Tab className={({ selected }) =>
                  classNames(
                    'p-3 px-5 w-full outline-0 text-[#fff] text-start rounded-2xl border-0',
                    selected
                      ? 'text-royalblue bg-[#fff] border-0'
                      : 'bg-royalblue'
                  )
                } style={{ fontFamily: 'Semi' }}>
                  {item}
                </Tab>
              ))}
            </Tab.List>










            <div class='w-2/3 flex justify-center'>

              <Tab.Panel class='p-10 flex w-full flex-col'>

                <div class='flex flex-row p-3 border-[1px] rounded-full border-gray-100 border-solid items-center gap-5'>

                  <div onClick={() => handleFormat('radius')} class={`w-full ${format.radius === true ? 'bg-royalblue text-[#ffffff]' : 'text-royalblue'} text-center rounded-full p-5`}>
                    <div class='text-sm font-["Semi"]'>Style</div>

                  </div>
                  <div onClick={() => handleFormat('backdrop')} class={`w-full ${format.backdrop === true ? 'bg-royalblue text-[#ffffff]' : 'text-royalblue'} text-center rounded-full p-5`}>
                    <div class='text-sm font-["Semi"]'>Color</div>

                  </div>

                </div>
                <div class={`p-5 ${format.radius === false && 'hidden'} `}>
                  <div class='text-2xl font-["Bold"]'>Border radius</div>

                  <div class='grid grid-cols-3 w-full rounded-xl bg-royalblue p-3  mt-7 gap-10 '>
                    <div class={`${borderRadius === 'rounded-none' && ''} p-5 rounded-2xl`} onClick={() => setBorderRadius('rounded-none')}>
                      <div class='rounded-none py-5 bg-[#fff] p-3 border flex items-center justify-between'>

                      </div>
                    </div>

                    <div class={`${borderRadius === 'rounded-lg' && ''} p-5`} onClick={() => setBorderRadius('rounded-lg')}>
                      <div class='p-3 py-5 bg-[#fff] rounded-lg border flex items-center justify-between'>

                      </div>
                    </div>


                    <div class={`${borderRadius === 'rounded-full' && ''} p-5`} onClick={() => setBorderRadius('rounded-full')}>
                      <div class='p-3 py-5 bg-[#fff] rounded-full border flex items-center justify-between'>

                      </div>
                    </div>
                  </div>
                  <div class='text-2xl font-["Bold"] mt-5'>Style</div>
                  <div class='grid grid-cols-3 w-full rounded-xl bg-royalblue items-center p-3  mt-7 gap-10 '>
                    <div onClick={() => handleStyle('backdrop')} class={`p-5 self-stretch ${style.backdrop === true && ''}`}>
                      <div className={`bg-white px-3 ${borderRadius} py-4 flex justify-between items-center overflow-hidden border-2 border-solid self-stretch`} style={{ borderColor: colorPickers.backdropColor, boxShadow: `8px 8px 0px ${colorPickers.backdropColor}` }}>

                      </div>
                    </div>

                    <div onClick={() => handleStyle('color')} class={`p-5 self-stretch ${style.color === true && ''}`}>
                      <div className={`bg-gray-200 px-3 ${borderRadius} py-4 flex justify-between items-center overflow-hidden border self-stretch`}>

                      </div>
                    </div>

                    <div onClick={() => handleStyle('outline')} class={`p-5 self-stretch ${style.outline === true && ''}`}>
                      <div className={`bg-white px-3 ${borderRadius} border-2 py-4 flex justify-between items-center overflow-hidden border self-stretch`} style={{ borderColor: colorPickers.backdropColor }}>

                      </div>
                    </div>
                  </div>

                </div>
                <div class={`p-2 ${format.backdrop === false && 'hidden'} self-stretch grid grid-cols-2 gap-5`}>
                  <div class='p-5'>
                    <div class='text-2xl font-["Bold"] mt-5'>Color</div>
                    <div class='my-7 bg-royalblue inline-block w-full rounded-xl gap-4 p-5'>
                      <div class='flex items-center gap-5'>
                        <div class='w-12 h-12 rounded-lg' onClick={() => setActiveColorPicker('backdropColor')} style={{ backgroundColor: colorPickers.backdropColor }} >

                        </div>

                        <div class='w-full p-4 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                          {colorPickers.backdropColor}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='p-5'>
                    <div class='text-2xl font-["Bold"] mt-5'>Component Color</div>
                    <div class='my-7 bg-royalblue inline-block w-full rounded-xl gap-4 p-5'>
                      <div class='flex self-stretch items-center gap-5'>
                        <div class='w-12 h-12 rounded-lg' onClick={() => setActiveColorPicker('mediaColor')} style={{ backgroundColor: colorPickers.mediaColor }} >

                        </div>

                        <div class='w-full p-4 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                          {colorPickers.mediaColor}
                        </div>
                      </div>
                    </div>
                  </div>

                  {activeColorPicker && (
                    <div ref={colorPickerRef} className="bg-royalblue p-5 mx-5 gap-3 flex flex-col inline-block rounded-xl">
                      <div>
                        <FormattedStringComponent inputString={activeColorPicker} />
                      </div>
                      <div>
                        <TwitterPicker
                          styles={{
                            default: {
                              card: {
                                boxShadow: 'none',
                                borderRadius: '10px'
                              }
                            }
                          }}
                          triangle='false'
                          color={colorPickers[activeColorPicker]}
                          onChange={newColor => handleColorChange(activeColorPicker, newColor)}
                        />
                      </div>
                    </div>
                  )}

                </div>
              </Tab.Panel>
              <Tab.Panel class='py-7 px-2 flex  w-4/5 flex-col'>

                {!data.me.Links.some(link => link.linkText || link.image) && !linksData.some(link => link.linkText || link.image) ? (
                     <div class='flex flex-col border-solid p-5 text-[15px] gap-3 border-gainsboro border-[1px] rounded-xl'>
                     <div class='justify-between flex items-center'>
                       <div class='font-["Bold"] text-2xl'>Links</div>
                       <div class='px-5 font-["Semi"] py-3 rounded-lg bg-primary-blue text-sm text-[#fff]'>Add link</div>
                     </div>
                  </div>
                ) : (
                  <div class='flex flex-col border-solid p-5 text-[15px] gap-3 border-gainsboro border-[1px] rounded-xl'>
                    <div class='justify-between flex items-center'>
                      <div class='font-["Bold"] text-2xl'>Links</div>
                      <div class='px-5 font-["Semi"] py-3 rounded-lg bg-primary-blue text-sm text-[#fff]'>Add link</div>
                    </div>
                    <div class='bg-royalblue text-[#fff] font-["Semi"] text-sm rounded-[10px] justify-between flex items-center p-3 px-10 mb-5'>
                      <div>About</div>

                      <div>Link</div>
                      <div>More</div>
                    </div>
                    {data.me.Links.map(item => (

                      <div className="flex flex-row items-center px-4 justify-between gap-[20px]">
                        <div className="w-1/3 flex flex-row items-center justify-start py-0 px-0 box-border gap-[16px]">
                          <div class='border-solid border-gainsboro border-[1px] p-1.5 rounded-[5px]' />
                          {!item.image || item.image === "" ? (
                            <div
                              className="h-12 w-12 rounded-full relative overflow-hidden bg-royalblue shrink-0"


                            />
                          ) : (
                            <img
                              className="h-12 w-12 rounded-full relative overflow-hidden shrink-0"
                              loading="lazy"
                              alt=""
                              src={item.image}
                            />
                          )}
                          <div className="flex flex-col flex-wrap items-start justify-start py-0 px-0 box-border gap-[4px]">
                            <div className="relative text-[#000]  font-['Semi']">
                              {item.linkText}
                            </div>

                          </div>
                        </div>



                        <div className="w-1/3 flex flex-col text-sm font-['Semi']">
                          {item.link}
                        </div>
                        <div className="cursor-pointer py-2.5 px-[19px] border-solid border-[1px] border-gainsboro  rounded-full flex flex-row items-center justify-center gap-[12px]">

                          <div className="relative text-xs  font-['Bold'] text-[#000]">
                            Edit
                          </div>

                        </div>

                      </div>

                    ))}


                  </div>
                )}




              </Tab.Panel>

              <Tab.Panel class='p-10 items-center flex w-4/5 flex-col'>

                <div class='flex flex-col gap-5 w-48'>

                  <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
                    <div style={{ fontFamily: 'Bold' }}>Newsletter</div>
                    <div>
                      <Switch
                        checked={sections.newsletter}
                        onChange={() => setSections({ ...sections, newsletter: !sections.newsletter })}
                        className={`${sections.newsletter ? 'bg-white' : 'bg-black'
                          }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                      >
                        <span className="sr-only">Enable notifications</span>
                        <span
                          className={`${sections.newsletter ? 'translate-x-5' : 'translate-x-0'
                            }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                        />
                      </Switch>
                    </div>
                  </div>
                  <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
                    <div style={{ fontFamily: 'Bold' }}>Featured</div>
                    <div>
                      <Switch
                        checked={sections.featured}
                        onChange={() => setSections({ ...sections, featured: !sections.featured })}
                        className={`${sections.featured ? 'bg-white' : 'bg-black'
                          }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                      >
                        <span className="sr-only">Enable notifications</span>
                        <span
                          className={`${sections.featured ? 'translate-x-5' : 'translate-x-0'
                            }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                        />
                      </Switch>
                    </div>
                  </div>
                  <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
                    <div style={{ fontFamily: 'Bold' }}>Links</div>
                    <div>
                      <Switch
                        checked={sections.links}
                        onChange={() => setSections({ ...sections, links: !sections.links })}
                        className={`${sections.links ? 'bg-white' : 'bg-black'
                          }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                      >
                        <span className="sr-only">Enable notifications</span>
                        <span
                          className={`${sections.links ? 'translate-x-5' : 'translate-x-0'
                            }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                        />
                      </Switch>
                    </div>
                  </div>
                  <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
                    <div style={{ fontFamily: 'Bold' }}>Products</div>
                    <div>
                      <Switch
                        checked={sections.products}
                        onChange={() => setSections({ ...sections, products: !sections.products })}
                        className={`${sections.products ? 'bg-white' : 'bg-black'
                          }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                      >
                        <span className="sr-only">Enable notifications</span>
                        <span
                          className={`${sections.products ? 'translate-x-5' : 'translate-x-0'
                            }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                        />
                      </Switch>
                    </div>
                  </div>
                  <div class='flex items-center p-5 bg-royalblue text-[#fff] text-sm rounded-xl justify-between'>
                    <div style={{ fontFamily: 'Bold' }}>Social</div>
                    <div>
                      <Switch
                        checked={sections.socials}
                        onChange={() => setSections({ ...sections, socials: !sections.socials })}
                        className={`${sections.socials ? 'bg-white' : 'bg-black'
                          }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                      >
                        <span className="sr-only">Enable notifications</span>
                        <span
                          className={`${sections.socials ? 'translate-x-5' : 'translate-x-0'
                            }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                        />
                      </Switch>
                    </div>
                  </div>

                </div>
              </Tab.Panel>
              <Tab.Panel class='px-2 flex  w-4/5 flex-col'>
                <div class='flex flex-row p-3 border-[1px] rounded-full border-gray-100 border-solid items-center gap-5'>

                  <div onClick={() => handleBackground('backgroundColor')} class={`w-full ${backgroundImageorColor.backgroundColor === true ? 'bg-royalblue text-[#ffffff]' : 'text-royalblue'} text-center rounded-full p-5`}>
                    <div class='text-sm font-["Semi"]'>Background Color</div>

                  </div>
                  <div onClick={() => handleBackground('backgroundImage')} class={`w-full ${backgroundImageorColor.backgroundImage === true ? 'bg-royalblue text-[#ffffff]' : 'text-royalblue'} text-center rounded-full p-5`}>
                    <div class='text-sm font-["Semi"]'>Background Image</div>

                  </div>

                </div>
                <div class='mt-5 self-stretch max-h-screen'>
                  <div class={`flex w-full ${backgroundImageorColor.backgroundColor === false && 'hidden'} px-5 justify-between`}>

                    <div class='my-7 bg-royalblue self-stretch inline-block rounded-xl gap-4 p-5'>
                      <div class='flex items-center gap-5'>
                        <div class='w-12 h-12 rounded-lg' onClick={() => setActiveColorPicker('backgroundColor')} style={{ backgroundColor: colorPickers.backgroundColor }} >

                        </div>
                        <div class='w-52 p-4 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                          {colorPickers.backgroundColor}
                        </div>
                      </div>
                    </div>

                    {activeColorPicker && (
                      <div ref={colorPickerRef} className="bg-royalblue p-5 mx-5 gap-3 flex flex-col inline-block rounded-xl">
                        <div>
                          <FormattedStringComponent inputString={activeColorPicker} />
                        </div>
                        <div>
                          <TwitterPicker
                            styles={{
                              default: {
                                card: {
                                  boxShadow: 'none',
                                  borderRadius: '10px'
                                }
                              }
                            }}
                            triangle='false'
                            color={colorPickers[activeColorPicker]}
                            onChange={newColor => handleColorChange(activeColorPicker, newColor)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div class='p-5'>

                    <div class={`inline-block bg-royalblue rounded-xl p-5 ${backgroundImageorColor.backgroundImage === false && 'hidden'}`}>
                      <div class='flex items-center gap-4'>
                        <div>
                          {textValues.background ? (
                            <img src={textValues.background} class='rounded-2xl w-14 h-14' />

                          ) : (
                            <img src={require('../Assets/AD.png')} class='border rounded-full w-14 h-14' />

                          )}
                        </div>
                        <label
                          style={{ fontFamily: 'Semi' }}
                          className='bg-white text-royalblue px-10 flex justify-center items-center py-4  cursor-pointer text-sm text-black rounded-lg'
                          htmlFor='inputFile2'
                        >
                          Change Background
                          <input
                            type='file'
                            id='inputFile2'
                            onChange={uploadBackground}
                            accept="image/*"
                            style={{ display: 'none' }}
                          />
                        </label>
                      </div>
                      {imageLoading1 && <div>Loading...</div>}
                    </div>



                  </div>

                </div>
              </Tab.Panel>
              <Tab.Panel class='flex flex-col w-4/5'>

                <div class='space-y-5 flex flex-col xl:px-[0px] px-10'>

                  <div className='grid grid-cols-2 mt-5 gap-5'>
                    <div class='p-3 px-6'>
                      <div class='text-2xl font-["Bold"]'>Text Color</div>
                      <div class='my-7 bg-royalblue inline-block w-full rounded-xl gap-4 p-5'>
                        <div class='flex items-center gap-5'>
                          <div class='w-12 h-12 rounded-lg shrink-0' onClick={() => setActiveColorPicker('textColor')} style={{ backgroundColor: colorPickers.textColor }} >

                          </div>

                          <div class='w-full p-4 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                            {colorPickers.textColor}
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div class='p-3 px-6'>
                      <div class='text-2xl font-["Bold"]'>Heading Color</div>
                      <div class='my-7 bg-royalblue inline-block w-full rounded-xl gap-4 p-5'>
                        <div class='flex items-center gap-5'>
                          <div class='w-12 h-12 shrink-0 rounded-lg' onClick={() => setActiveColorPicker('headerText')} style={{ backgroundColor: colorPickers.headerText }} >

                          </div>

                          <div class='w-full p-4 font-["Semi"] text-sm bg-[#fff] rounded-lg'>
                            {colorPickers.headerText}
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                  {activeColorPicker && (
                    <div ref={colorPickerRef} className="bg-royalblue p-5 mx-5 gap-3 flex flex-col inline-block rounded-xl">
                      <div>
                        <FormattedStringComponent inputString={activeColorPicker} />
                      </div>
                      <div>
                        <TwitterPicker
                          styles={{
                            default: {
                              card: {
                                boxShadow: 'none',
                                borderRadius: '10px'
                              }
                            }
                          }}
                          triangle='false'
                          color={colorPickers[activeColorPicker]}
                          onChange={newColor => handleColorChange(activeColorPicker, newColor)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel class='flex w-4/5  flex-col'>
                <div class='grid grid-cols-2 p-5 gap-5'>
                  <div class='flex items-center space-x-3'>
                    <div class='border p-5 my-5 w-full bg-royalblue rounded-2xl'>
                      <div class='w-full  flex items-center justify-between mb-5'>
                        <div class='text-lg text-white' style={{ fontFamily: 'Bold' }}>Header Image</div>
                        <img
                          className="rounded-full h-12 w-12"
                          src={textValues.headerImage || ADD}
                          alt="Hero"
                          onError={(e) => {
                            e.target.src = ADD;
                          }}
                        />
                      </div>


                      <label
                        style={{ fontFamily: 'Semi' }}
                        className='bg-white text-royalblue w-full flex justify-center items-center py-3  cursor-pointer text-sm text-black rounded-lg'
                        htmlFor='inputFile'
                      >
                        Change Header Image
                        <input
                          type='file'
                          id='inputFile'
                          onChange={uploadImage}
                          ref={inputFile}
                          accept='image/*'
                          style={{ display: 'none' }}
                        />
                      </label>
                      {imageLoading && <div>Loading...</div>}
                    </div>
                  </div>


                  <div class='flex items-center space-x-3'>
                    <div class='border p-5 my-5 w-full bg-royalblue rounded-2xl'>
                      <div class='w-full flex items-center justify-between mb-5'>
                        <div class='text-lg text-white' style={{ fontFamily: 'Bold' }}>Hero Image</div>
                        <img
                          className="rounded-full h-12"
                          src={textValues.heroImage || ADD}
                          alt="Hero"
                          onError={(e) => {
                            e.target.src = ADD;
                          }}
                        />
                      </div>


                      <label
                        style={{ fontFamily: 'Semi' }}
                        className='bg-white text-royalblue w-full flex justify-center items-center py-3  cursor-pointer text-sm text-black rounded-lg'
                        htmlFor='inputFile3'
                      >
                        Change Hero Image
                        <input
                          type='file'
                          id='inputFile3'
                          onChange={uploadFile}
                          ref={inputFile3}
                          accept='image/video*'
                          style={{ display: 'none' }}
                        />
                      </label>
                      {imageLoading && <div>Loading...</div>}
                    </div>
                  </div>

                </div>





              </Tab.Panel>

              <Tab.Panel class='w-4/5'>

                <div class='mt-10 grid grid-cols-4 gap-5'>
                  <div onClick={() => setFont("Normal")} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["Normal"]'>
                    Normal
                  </div>
                  <div onClick={() => setFont('KodeMono')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["KMNormal"]'>
                    KodeMono
                  </div>
                  <div onClick={() => setFont('Caveat')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["CNormal"]'>
                    Caveat
                  </div>
                  <div onClick={() => setFont('Roboto')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["RNormal"]'>
                    Roboto
                  </div>
                  <div onClick={() => setFont('Namdhinggo')} className='w-full h-20 rounded-2xl flex items-center  text-sm justify-center text-white bg-royalblue font-["NNormal"]'>
                    Namdhinggo
                  </div>
                </div>
              </Tab.Panel>
         
              <Tab.Panel class='flex w-4/5 flex-col'>
                <div class='grid grid-cols-2 gap-5'>
                  <div class='p-5 rounded-xl self-stretch my-4  bg-royalblue text-white'>
                    <div class='flex items-center justify-between my-3'>
                      <div class='text-sm' style={{ fontFamily: 'Bold' }}>Instagram</div>
                      <div>
                        <Switch
                          checked={instagramShown}
                          onChange={setInstagramShown}
                          className={`${instagramShown ? 'bg-white' : 'bg-black'
                            }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${instagramShown ? 'translate-x-5' : 'translate-x-0'
                              }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                          />
                        </Switch>
                      </div>

                    </div>
                    <div class='mb-4 mt-2'>
                      {instagramShown === true && (
                        <div class='border-0 outline-0 self-stretch'>

                          <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                            <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                              <BsInstagram className="h-5 w-5 text-royalblue" />
                            </div>
                            <input onChange={(event) => handleTextChange('instagram', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                              className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.instagram} placeholder='Instagram link' />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class='p-5 rounded-xl self-stretch my-4  bg-royalblue text-white'>
                    <div class='flex items-center justify-between my-3'>
                      <div class='text-sm' style={{ fontFamily: 'Bold' }}>Facebook</div>
                      <div>
                        <Switch
                          checked={facebookShown}
                          onChange={setFacebookShown}
                          className={`${facebookShown ? 'bg-white' : 'bg-black'
                            }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${facebookShown ? 'translate-x-5' : 'translate-x-0'
                              }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                          />
                        </Switch>
                      </div>
                    </div>
                    <div class='my-4'>
                      {facebookShown === true && (
                        <div class='border-0 outline-0 self-stretch'>

                          <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                            <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                              <BsFacebook className="h-5 w-5 text-royalblue" />
                            </div>
                            <input onChange={(event) => handleTextChange('facebook', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                              className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.facebook} placeholder='Facebook link' />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class='p-5 rounded-xl self-stretch my-4  bg-royalblue text-white'>

                    <div class='flex items-center justify-between'>
                      <div class='text-sm' style={{ fontFamily: 'Bold' }}>Twitter</div>
                      <div>
                        <Switch
                          checked={twitterShown}
                          onChange={setTwitterShown}
                          className={`${twitterShown ? 'bg-white' : 'bg-black'
                            }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${twitterShown ? 'translate-x-5' : 'translate-x-0'
                              }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                          />
                        </Switch>
                      </div>
                    </div>
                    <div class='my-4'>

                      {twitterShown === true && (
                        <div class='border-0 outline-0 self-stretch'>

                          <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                            <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                              <BsTwitter className="h-5 w-5 text-royalblue" />
                            </div>
                            <input onChange={(event) => handleTextChange('twitter', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                              className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.twitter} placeholder='Twitter link' />
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                  <div class='p-5 rounded-xl self-stretch my-4  bg-royalblue text-white'>

                    <div class='flex items-center justify-between '>
                      <div class='text-sm' style={{ fontFamily: 'Bold' }}>Tiktok</div>
                      <div>
                        <Switch
                          checked={tiktokShown}
                          onChange={setTikTokShown}
                          className={`${tiktokShown ? 'bg-white' : 'bg-black'
                            }  relative inline-flex h-[22px] items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}

                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${tiktokShown ? 'translate-x-5' : 'translate-x-0'
                              }  pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-royalblue shadow-lg ring-0 transition duration-200 ease-in-out`}

                          />
                        </Switch>
                      </div>
                    </div>
                    <div class='my-4'>


                      {tiktokShown === true && (
                        <div class='border-0 outline-0 self-stretch'>

                          <div class='relative border-0 self-stretch items-center outline-0 mt-5'>
                            <div className="absolute inset-y-0 left-0 flex px-3 pl-5 items-center pointer-events-none">
                              <FaTiktok className="h-5 w-5 text-royalblue" />
                            </div>
                            <input onChange={(event) => handleTextChange('tiktok', event.target.value)} style={{ fontFamily: 'Face' }} type="text"
                              className="pl-16 pr-5 py-4 rounded-xl border-0 outline-0 text-sm self-stretch placeholder:text-gray-200 border" value={textValues.tiktok} placeholder='Tiktok link' />
                          </div>
                        </div>
                      )}


                    </div>
                  </div>
                </div>






              </Tab.Panel>
              <Tab.Panel class='flex w-4/5 flex-col'>

                <div class='p-7 w-full'>

                  <div class='flex items-center border-gainsboro border-[1px] border-solid p-3 rounded-xl w-full justify-between'>

                    <Listbox value={selectedPostId} onChange={setSelectedPostId}>
                      {({ open }) => (
                        <>
                            <Listbox.Button
                              className="relative bg-royalblue border-0 text-[#fff] flex items-center space-x-6 rounded-full shadow-sm inline-block p-3 text-left cursor-default"
                            >
                              <span className="block truncate font-['Semi']">
                                {selectedPostId ? data.me.posts.find(post => post.id === selectedPostId).title : 'Select a post'}
                              </span>
                              <span className="inset-y-0 flex items-center pointer-events-none">
                                <svg
                                  className="h-5 w-5 text-black"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="#fff"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 12a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 1.414-1.414L10 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-3 3A1 1 0 0 1 10 12z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>

                            <Listbox.Options
                              className="absolute z-10 mt-20 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                              {data.me.posts.map((post) => (
                                <Listbox.Option
                                  key={post.id}
                                  value={post.id}
                                  className={({ active }) =>
                                    `${active ? 'text-white bg-black' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-3 pr-9`
                                  }
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                        {post.title}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`${active ? 'text-white' : 'text-indigo-600'}
                                absolute inset-y-0 right-0 flex items-center pr-4`}
                                        >
                                          <svg
                                            className="h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M10 12a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 1.414-1.414L10 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-3 3A1 1 0 0 1 10 12z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                        </>
                      )}
                    </Listbox>

                    <button onClick={handleSelectFeaturedPost} class='font-["Semi"] text-sm bg-royalblue border-0 px-5 py-2.5 rounded-full text-white'>Set Featured Post</button>
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <button type='submit'>Submit</button>
                  </form>
                </div>

              </Tab.Panel>
            </div>




            <div class='pt-10 w-1/3 flex justify-center'>
              <div className={`xl:w-[335px] border-[8px] border-solid border-[#000] w-[270px] h-[510px] overflow-y-scroll xl:h-[590px] mx-5 rounded-[60px] flex flex-col`} style={{
                backgroundImage: backgroundBoolean && textValues.background ? `url(${textValues.background})` : 'none',
                backgroundColor: !backgroundBoolean && colorPickers.backgroundColor ? `${colorPickers.backgroundColor}` : ''
              }}>

                <div class='mx-5'>

                  <div class='max-w-lg  items-center flex flex-col justify-center mx-auto pt-10'>
                    <div className="w-full h-[62px] bg-white bg-opacity-50 px-5 mx-5 rounded-[72px] border flex-row-reverse flex  items-center justify-between border-slate-200 backdrop-blur-[10px]">
                      <div className="w-6 h-6 p-3 bg-black rounded-full border border-black justify-center items-center inline-flex">
                        <img src={dots} className="w-4 h-4 relative flex-col justify-start items-start flex" />
                      </div>
                      <div className="w-[96.55px] left-[134.72px] justify-center items-center inline-flex">
                        <div className="h-6 text-black text-base font-['Semi'] leading-normal">{textValues.aboutName || data.me.name}</div>

                      </div>
                      <img className="w-11 h-11 rounded-[22px]" src={textValues.headerImage || data.findSite.avatar} />
                    </div>

                    {data.me.avatar && (
                      <img
                        src={textValues.headerImage || data.me.avatar}
                        alt={`User Avatar - ${data.me.username}`}
                        className='w-24 h-24 mt-14 rounded-[48px]'
                      />
                    )}
                    {!data.me.avatar && (
                      <img
                        src={textValues.headerImage || user}
                        alt={`Default User Avatar`}
                        className='w-24 h-24 mt-14 rounded-[48px]'
                      />
                    )}
                    <div className="justify-center items-center inline-flex my-3">
                      <div className="grow shrink basis-0 h-[30px] py-[3px] justify-center items-center inline-flex">
                        <ColorEditableText

                          textColor={colorPickers.headerText}
                          initialText={textValues.aboutName || data.me.name}
                          picker={pickerVisible.heading}
                          styles={`h-6 ${font === 'Caveat' && 'font-["CBold"]'} ${font === 'Namdhinggo' && 'font-["NBold"]'}  ${font === 'Roboto' && 'font-["RBold"]'}  ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'} text-xl leading-[30px]`}
                          onTextChange={(editedText) => handleTextChange('aboutName', editedText)}
                          onColorChange={(newColor) => handleColorChange('aboutNameColor', newColor)}
                          defaultColor={colorPickers.aboutColorName}
                        />
                      </div>

                    </div>
                    <div class='px-3 flex items-center text-center justify-center'>

                      <ColorEditableText
                        textColor={colorPickers.headerText}
                        initialText={data?.me?.Website?.text2 === "" ? data?.me?.name : data.me.bio || "Bio goes here"}
                        picker={pickerVisible.heading}
                        styles={`text-center text-white ${font === 'Roboto' && 'font-["RFace"]'} ${font === 'Namdhinggo' && 'font-["NFace"]'}  ${font === 'Caveat' && 'font-["CFace"]'}  text-sm ${font === 'Normal' && 'font-["Face"]'} ${font === 'KodeMono' && 'font-["KMFace"]'} leading-[21px] mb-7`}
                        onTextChange={(editedText) => handleTextChange('text2', editedText)}
                        onColorChange={(newColor) => handleColorChange('aboutNameColor', newColor)}
                        defaultColor={colorPickers.aboutColorName}
                      />
                    </div>


                    {sections.hero === true && (
                      <div class='mt-5'>
                        {textValues.heroImage === "" ? (
                          <img src={ADD} class={`${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`} />

                        ) : (
                          <div>
                            {fileType === 'image' ? (
                              <div class='relative'>
                                <img src={textValues.heroImage} alt="Hero Image" class={`${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`} />
                                <div class='absolute bottom-3 right-2 bg-black px-3 rounded-lg py-2 text-xs text-white font-["Face"]'>{textValues.text3}</div>
                              </div>
                            ) : fileType === 'video' ? (
                              <video autoPlay loop class={`${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`}>
                                <source src={textValues.heroImage} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img src={ADD} class={`${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`} />
                            )}
                          </div>
                        )}

                      </div>
                    )}

                    {sections.newsletter === true && (
                      <div class='w-full'>
                        <div style={{ color: colorPickers.headerText }} className={`text-center my-4 ${font === 'Caveat' && 'font-["CBold"]'}  text-base ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'} leading-normal`}>Newsletter</div>
                        <div class='w-full'>
                          <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} className={`w-full py-4 mb-4 px-4 ${(style.outline || style.backdrop) === true && 'border-2'} justify-center ${borderRadius} items-center flex`}>
                            <div className="flex-grow text-center text-sm text-white font-['Face'] leading-[21px]">
                              <ColorEditableText

                                initialText={data?.me?.Website?.newsletterText === "" ? data?.me?.name : data?.me?.Website?.newsletterText || 'Newsletter CTA'}
                                picker={pickerVisible.heading}
                                textColor={colorPickers.textColor}

                                styles={`text-center text-white ${font === 'Caveat' && 'font-["CFace"]'} ${font === 'Namdhinggo' && 'font-["NFace"]'}  leading-normal ${font === 'Normal' && 'font-["Face"]'} ${font === 'KodeMono' && 'font-["KMFace"]'} leading-[21px]`}
                                onTextChange={(editedText) => handleTextChange('newsletterText', editedText)}
                                onColorChange={(newColor) => handleColorChange('aboutNameColor', newColor)}

                                defaultColor={colorPickers.aboutColorName}
                              />
                            </div>
                            <div className="ml-auto">
                              {iconColor === 'white' ? (
                                <img src={dots} class='w-4 h-4' />

                              ) : (
                                <img src={dotsBlack} class='w-4 h-4' />

                              )}                    </div>
                          </div>


                          <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} className={`w-full p-4 ${(style.backdrop || style.outline) === true && 'border-2'} relative flex items-center ${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius}`}>

                            <div className={`text-black text-base ${font === 'Caveat' && 'font-["CFace"]'}  text-base ${font === 'Normal' && 'font-["Face"]'} ${font === 'KodeMono' && 'font-["KMFace"]'} leading-normal`}>Enter Email</div>
                            <button class={`absolute ${borderRadius} ${font === 'Caveat' && 'font-["CBold"]'}  text-base ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'} py-2 right-3 text-sm px-4`} style={{ backgroundColor: colorPickers.textColor, color: colorPickers.mediaColor }}>Submit</button>


                          </div>
                        </div>
                      </div>
                    )}

                    {sections.featured === true && (
                      <div class='w-full'>
                        <div style={{ color: colorPickers.headerText }} className={`text-center my-4 ${font === 'Caveat' && 'font-["CBold"]'}  text-base ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'} leading-normal`}>Featured</div>
                        {featured.f2 === true && (
                          <div class='mb-4 w-full'>
                            <div style={{ background: colorPickers.mediaColor, borderColor: backdrop === true && colorPickers.backdropColor, boxShadow: backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} className={`w-full border border-slate-200 backdrop-blur-[10px] ${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius} ${backdrop === true && 'border-2'} overflow-hidden`}>
                              <div className="relative">
                                {data?.me?.featuredPost === null ? (
                                  <div>Select featured post</div>
                                )

                                  : (
                                    <div>
                                      <img src={data?.me?.featuredPost?.post?.thumbnail} />
                                    </div>
                                  )}
                              </div>

                            </div>
                          </div>
                        )}
                        {featured.f3 === true && (
                          <div class='mb-4 w-full'>
                            <div style={{ background: colorPickers.mediaColor, borderColor: backdrop === true && colorPickers.backdropColor, boxShadow: backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} className={`w-full border border-slate-200 backdrop-blur-[10px] ${borderRadius === 'rounded-full' ? 'rounded-3xl' : borderRadius} ${backdrop === true && 'border-2'} overflow-hidden`}>
                              <div className="relative">
                                {data?.me?.featuredPost === null ? (
                                  <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} class={`${borderRadius} ${style.backdrop === true && 'border-2'}  ${font === 'Namdhinggo' && 'font-["NFace"]'}   ${font === 'Roboto' && 'font-["RFace"]'} ${font === 'Caveat' && 'font-["CFace"]'}  ${font === 'Normal' && 'font-["Face"]'} ${font === 'KodeMono' && 'font-["KMFace"]'} w-full flex items-center text-center justify-center `}>
                                    Select featured post
                                  </div>)

                                  : (
                                    <div>
                                      <img src={data?.me?.featuredPost?.post?.thumbnail} />
                                      <div class='w-full p-3 px-5 text-sm font-["Face"]'>
                                        Add Text
                                      </div>
                                    </div>
                                  )}
                              </div>

                            </div>
                          </div>
                        )}
                        {featured.f1 === true && (
                          <div class='mb-4 w-full space-y-4'>
                            <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} className={`w-full  py-3 pl-3 pr-5 mb-4 justify-between ${style.backdrop === true && 'border-2'} ${borderRadius} flex items-center`}>
                              <div></div>
                              <div>
                                <ColorEditableText
                                  font={'Face'}
                                  initialText={'Text here'}
                                  picker={pickerVisible.heading}
                                  textColor={colorPickers.textColor}
                                  styles={'text-white text-sm leading-[21px]'}
                                  onTextChange={(editedText) => handleTextChange('featuredName', editedText)}
                                  onColorChange={(newColor) => handleColorChange('aboutNameColor', newColor)}
                                  defaultColor={colorPickers.aboutColorName}
                                />
                              </div>
                              <div>
                                <EllipsisHorizontalIcon style={{ color: colorPickers.textColor }} class='w-4 h-4' />

                              </div>
                            </div>

                            <div class='space-y-3'>
                              {data?.me?.featuredPost === null ? (
                                <div style={{ background: colorPickers.mediaColor, borderColor: style.backdrop === true && colorPickers.backdropColor, boxShadow: style.backdrop === true && `8px 8px 0px ${colorPickers.backdropColor}` }} class={`${borderRadius} ${style.backdrop === true && 'border-2'}  ${font === 'Namdhinggo' && 'font-["NFace"]'}   ${font === 'Roboto' && 'font-["RFace"]'} ${font === 'Caveat' && 'font-["CFace"]'}  ${font === 'Normal' && 'font-["Face"]'} ${font === 'KodeMono' && 'font-["KMFace"]'} w-full flex items-center text-center py-2 text-sm justify-center `}>
                                  Select featured post
                                </div>
                              ) : (
                                <Thumbnail data={data?.me?.featuredPost?.post} />
                              )}
                            </div>


                          </div>
                        )}
                      </div>
                    )}


                    {sections.links === true && (
                      <div class='w-full'>
                        <ColorEditableText

                          initialText={'Links'}
                          picker={pickerVisible.heading}
                          textColor={colorPickers.headerText}
                          styles={`text-center flex items-center justify-center ${font === 'Caveat' && 'font-["CBold"]'} ${font === 'Namdhinggo' && 'font-["NBold"]'}  leading-normal ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'}`}
                          onTextChange={(editedText) => handleTextChange('linkSectionText', editedText)}
                          onColorChange={(newColor) => handleColorChange('linkSectionText', newColor)}

                          defaultColor={colorPickers.headerText}
                        />                        {linksData.length > 0 || (data?.me?.Links && data?.me?.Links?.length > 0) ? (
                          <div className='space-y-4 w-full'>
                            {data?.me?.Links?.map(item => (
                              <div style={{ background: colorPickers.mediaColor }} className={`w-full py-5 px-5 mb-4 justify-between ${borderRadius} flex items-center`}>
                                {iconColor === 'white' ? (
                                  <img src={link} class='w-4 h-4' />

                                ) : (
                                  <img src={linkBlack} class='w-4 h-4' />

                                )}
                                <div style={{ color: colorPickers.textColor }} className="flex-grow text-center px-5 text-sm font-['Face'] leading-[21px]">
                                  {item.linkText}
                                </div>

                                {iconColor === 'white' ? (
                                  <img src={dots} class='w-4 h-4' />

                                ) : (
                                  <img src={dotsBlack} class='w-4 h-4' />

                                )}
                              </div>
                            ))}
                            {linksData.map(item => (
                              <div style={{ background: colorPickers.mediaColor }} className={`w-full ${item.linkText === '' && 'hidden'} py-5 px-5 mb-4 justify-between ${borderRadius} flex items-center`}>
                                {iconColor === 'white' ? (
                                  <img src={link} class='w-4 h-4' />

                                ) : (
                                  <img src={linkBlack} class='w-4 h-4' />

                                )}                        <div style={{ color: colorPickers.textColor }} className="flex-grow text-center px-5 text-sm font-['Face'] leading-[21px]">
                                  {item.linkText}
                                </div>

                                <EllipsisHorizontalIcon style={{ color: colorPickers.textColor }} class='w-4 h-4' />

                              </div>
                            ))}
                          </div>

                        ) : null}
                      </div>
                    )}




                    {sections.products === true && (
                      <div class='w-full'>
                        <div style={{ color: colorPickers.headerText }} className={`my-4 text-center ${font === 'Namdhinggo' && 'font-["NBold"]'}  ${font === 'Roboto' && 'font-["RBold"]'} ${font === 'Caveat' && 'font-["CBold"]'}  text-base ${font === 'Normal' && 'font-["Bold"]'} ${font === 'KodeMono' && 'font-["KMBold"]'} leading-normal`}>Products</div>
                        {productFormat.basic === true && (


                          <div class='grid grid-cols-2 gap-4'>
                            {data?.me?.posts?.map(item => (
                              <Basic item={item} />
                            )

                            )}


                          </div>

                        )}
                        {productFormat.thumbnail === true && (
                          <div class='space-y-3'>
                            {data?.me?.posts?.map(item => (
                              <Thumbnail data={item} />
                            ))}
                          </div>)}
                        {productFormat.link === true && (
                          <div class='space-y-3'>
                            {data?.me?.posts?.map(item => (
                              <Link data={item} />
                            ))}
                          </div>)}


                      </div>
                    )}


                    {sections.socials === true && (
                      <div class='mb-10'>
                        <div style={{ color: colorPickers.headerText }} className="text-center my-4 text-base font-['Bold'] leading-normal">Socials</div>
                        <div class='flex items-center justify-center gap-x-5'>
                          <BsInstagram style={{ color: colorPickers.headerText, display: instagramShown === true ? 'flex' : 'none' }} class='w-5 h-5' />
                          <BsFacebook style={{ color: colorPickers.headerText, display: facebookShown === true ? 'flex' : 'none' }} class='w-5 h-5' />
                          <BsTwitter style={{ color: colorPickers.headerText, display: twitterShown === true ? 'flex' : 'none' }} class='w-5 h-5' />
                          <FaTiktok style={{ color: colorPickers.headerText, display: tiktokShown === true ? 'flex' : 'none' }} class='w-5 h-5' />
                        </div>
                      </div>
                    )}









                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </Tab.Group >
    </div>




  )
}

export default Edit