import React from 'react'
import { Feedback } from '../../Routes/Landing'

const Verification = () => {
    return (
        <div>
            <div class='w-full flex items-center bg-royalblue h-screen'>
                <div class='fixed top-0 w-full'>
                    <a href='/' class='self-stretch flex items-center justify-between 2xl:px-10 xs:m-5 m-5 2xl:m-0 2xl:py-5'>
                        <img src='/head.svg' class='h-9' />
                    </a>
                </div>
                <Feedback blue={false} />

                <div class='sm:max-w-md m-5 p-7 xs:mx-5 sm:mx-auto bg-[#fff] shadow-lg border-[1px] justify-center flex flex-col items-center border-gainsboro rounded-xl border-solid'>
                <img src='/union5.svg' className='xl:h-14 lg:h-12 md:h-10 h-9' />

                    <div class='text-2xl font-["Bold"] text-center mt-3'>Verification(Only during beta)</div>
                    <div class='py-5 font-["Face"] text-center leading-[30px]'>
                    Welcome to CreatorzHub! Thank you for signing up. Please note that during our beta stage, all accounts are subject to verification, which typically takes under 3 days. We appreciate your patience and look forward to having you onboarded soon. If you have any questions or concerns, feel free to reach out to our support team.
                    </div>
                    <a href='/' class='p-4 px-7 no-underline rounded-full font-["Semi"] bg-royalblue text-[#fff] inline-block'>Go back home <span aria-hidden="true">&rarr;</span></a>
                </div>
            </div>
        </div>
    )
}

export default Verification