import React, { Fragment, useState } from "react";
import FrameComponent7 from "./Landing/FrameComponent7";
import GroupComponent from "./Landing/GroupComponent";
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition } from "@headlessui/react";
import emailjs from 'emailjs-com';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';

export const Feedback = ({ blue }) => {
  const [closed, setClosed] = useState(true)
  const initialValues = {
    subject: '',
    message: ''
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required')
  });
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    emailjs.send('service_bo3bte9', 'template_fp3np7m', values, 'ZwXEdVr1LYWePCurk')
      .then((response) => {
        console.log('Email sent successfully!', response);
        resetForm();
        setSubmitting(false);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitting(false);
      });
  };

 return(
  <div class={`fixed max-w-md  ${closed === false && 'bottom-0 fixed sm:right-5 sm:bottom-5 right-0 z-10 justify-end items-end flex flex-col'}  ${closed === true && 'bottom-5 right-5 fixed z-10 w-full items-end justify-end flex flex-col'} gap-7 `}>
  {closed === false && (
    <div class={`p-5 ${blue === true ? 'bg-royalblue' : 'bg-[#fff]'} shadow relative sm:max-w-sm sm:rounded-xl rounded-t-xl self-stretch`}>
      <div class='flex flex-col relative w-full'>

        <div class={`${blue === true ? 'text-[#fff]' : 'text-black'} text-2xl font-["Bold"] mb-2`}>Rate session</div>

        <div class={`${blue === true ? 'text-[#fff]' : 'text-gray-400'} font-["Face"] text-sm`}>Creatorzhub is in a beta stage, meaning there will be problems and bugs, this is here to help us fix them.</div>
        <XMarkIcon onClick={() => setClosed(true)} class={`absolute top-1 right-1 w-6 h-6 ${blue === true ? 'text-[#fff]' : 'text-black'}`} />

      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div class='flex flex-col gap-3 w-full font-["Bold"] mt-5'>

            <Field placeholder='Rating(1-10)' type="text" id="subject" name="subject" class={`p-4 ${blue === true ? 'border-0' : 'border-[1px] border-solid border-gainsboro'} border-0 outline-0 rounded-lg font-["Face"]`} />
            <Field as='textarea' id="message" name="message" placeholder='Details(ex. Faulty buttons, broken links, video not playing... etc)' class={`p-4 border-0 h-40 ${blue === true ? 'border-0' : 'border-[1px] border-solid border-gainsboro'} outline-0 rounded-lg font-["Face"]`} />
              <button type="submit" disabled={isSubmitting} class={`p-4 py-5 ${blue === true ? 'bg-[#fff] text-royalblue' : 'bg-royalblue text-[#fff]'} font-["Bold"] border-0 rounded-full`}>  {isSubmitting ? 'Sending...' : 'Send'}</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )}
  {blue === true ? (
  <div onClick={() => setClosed(false)} class={`w-16 ${closed === false && 'sm:flex hidden'} h-16 flex items-center justify-center rounded-full bg-royalblue`}>
  <img src='/Comment.svg' class='w-8 h-8' />
</div>
  ) : (
    <div onClick={() => setClosed(false)} class={`w-16 ${closed === false && 'sm:flex hidden'} h-16 flex items-center justify-center rounded-full bg-[#fff]`}>
    <img src='/Comment2.svg' class='w-8 h-8' />
  </div>
  )}

</div>
 )
}
const Landing = () => {

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }


  return (
    <div class='w-full'>
      <FrameComponent7 />
      
     <Feedback blue={true} />
      <div className="relative 2xl:py-10 py-10 md:py-20 self-stretch ">
        <div class="2xl:flex xl:flex lg:flex 2xl:flex-row lg:px-5 2xl:max-w-[1400px] xl:max-w-[1250px] lg:max-w-[1000px] md:max-w-[790px] sm:mx-10 xs:mx-10 mx-5 xl:mx-auto 2xl:mx-auto lg:mx-auto md:mx-auto xl:flex-row lg:flex-row md:flex-row md:flex sm:flex-row sm:flex items-center justify-center 2xl:gap-20 lg:gap-0 xl:gap-5 2xl:pt-28 2xl:py-0 2xl:pb-16  xl:py-20 lg:py-16 md:pt-20 md:py-0 md:pb-10 sm:py-20 py-14">
          <div class="flex flex-col gap-[10px]  items-center lg:justify-start lg:items-start justify-center text-center lg:text-start 2xl:px-0 2xl:pr-0 xl:px-10 xl:pr-0 md:px-0 px-5">
              <div class="font-['Bold'] inline-block m-0 2xl:text-[80px] xl:text-[55px] lg:text-[45px] md:text-[70px] 2xs:text-[35px] xs:text-[45px] sm:text-[60px]">Empower your creativity with Creatorzhub</div>
          
            <div class="flex flex-col items-start justify-start pt-0 xs:pb-5 2xl:pr-28 xl:pr-36 lg:pr-10 pl-0 md:pr-0  xs:gap-[50px] gap-[25px]">

              <div class="self-stretch leading-[30px] font-['Face'] md:px-5 lg:px-0 max-w-full">
                Let's boost your creativity and keep everything organized with Creatorzhub, designed to help creators and brands monetize, engage, and grow their audience effectively.              </div>
            </div>
            <div class="flex flex-row items-center py-0 pr-5 pl-0 box-border sm:gap-[14px] gap-[10px] py-4 xs:py-0 max-w-full text-gray-1700 ">
              <div class="flex flex-col items-start justify-start py-0 pr-[9px] pl-0">
                <a href='/register' class="cursor-pointer no-underline [border:none] xs:py-5 py-4 px-[20px] xs:px-[30px] bg-royalblue rounded-full text-[#fff] flex flex-row items-start justify-start whitespace-nowrap hover:bg-dodgerblue">
                  <div class="relative xs:text-sm text-sm  inline-block font-['Semi'] text-white text-left">Get started <span aria-hidden="true">&rarr;</span></div>
                </a>
              </div>
              <div onClick={openModal} class="flex flex-row items-center gap-3">
                <img class="xs:w-10 xs:h-10 w-7 h-7 relative" loading="lazy" alt="" src="/iconlylightplay.svg" />
                <div class="flex flex-col items-start justify-start px-0 pb-0">
                  <div class="relative flex items-center xs:text-sm text-sm font-['Semi'] inline-block"><div class='hidden xs:flex'>View</div> Trailer</div>
                </div>
              </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <video class='w-full rounded-2xl' autoPlay >
                          <source src="/CH2.mp4" class='self-stretch' type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <div>
              <div class='font-["Semi"] mt-3'>Products ratings on best platforms to use Creatorzhub with.</div>
              <div class='flex items-center justify-center lg:justify-start w-full flex-wrap xs:gap-5 gap-2.5 mt-5'>
                <img src='/Group1944.svg' class='xs:h-auto h-7' />
                <img src='/sza.svg' class='xs:h-auto h-7' />
                <img src='/sza2.svg' class='xs:h-auto h-7' />

              </div>
            </div>
          </div>
          <img class="2xl:h-[600px] xl:h-[500px] lg:h-[480px] lg:flex xl:flex 2xl:flex md:hidden sm:hidden xs:hidden 2xs:hidden  overflow-hidden object-contain min-w-[456px] shrink-0" loading="lazy" alt="" src="/Group-38.png" />
        </div>


        <div className="flex flex-row pt-0 pb-[50px] self-stretch items-center justify-center text-center">
          <div className="flex flex-col items-end justify-start lg:gap-[60px] md:gap-[40px] sm:gap-[40px] xs:gap-[40px] gap-[30px] w-full">
            <div className="flex flex-row w-full items-center justify-center">
              <div className="m-0 relative font-['Bold'] text-center inline-block 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-3xl sm:text-3xl xs:text-3xl text-2xl">
                For Creators everywhere
              </div>
            </div>
            <div className="flex flex-row 2xl:hidden self-stretch items-center justify-center md:gap-[20px] 2xl:gap-[70px] gap-[20px] px-5 flex-wrap lg:gap-[40px] text-[#a6a6a6] ">
              <div className="lg:h-[32px] h-[28px] flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-3">
                <img
                  className="lg:h-[32px] xs:h-[28px] h-[23px] w-[23px] xs:w-[28px] lg:w-[32px]"
                  loading="lazy"
                  alt=""
                  src="/instagram-logo.svg"
                />
                <div className="flex-1 flex flex-col items-start justify-start  px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit lg:text-xl xs:text-lg">
                    Instagram
                  </h2>
                </div>
              </div>
              <div className="flex items-center py-0 pr-[3px] gap-4">
                <div className="lg:h-[32px] h-[28px] flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-3">
                  <img
                    className="lg:h-[32px] xs:h-[28px] h-[23px] w-[23px] xs:w-[28px] lg:w-[32px]"
                    loading="lazy"
                    alt=""
                    src="/twitch-logo.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit lg:text-xl xs:text-lg">
                    Twitch
                  </h2>
                </div>
              </div>

              <div className="h-[38px]  flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-3">
                <div className="h-[34px] flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border">
                  <img
                    className="lg:w-8 lg:h-8 w-6 h-6 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/patreon-logo.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit lg:text-xl xs:text-lg">
                    Patreon
                  </h2>
                </div>
              </div>

              <div className="h-[38px]  flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-3">
                <div className="h-[35px] flex flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                  <img
                    className="lg:w-8 lg:h-8 w-6 h-6  relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/cibtiktok-logo.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit lg:text-xl xs:text-lg">
                    Tiktok
                  </h2>
                </div>
              </div>

              <div className="h-[38px]  flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-3">
                <img
                  className="lg:h-9 lg:w-[51px] xs:h-6 h-5 w-[31px] xs:w-[41px] relative overflow-hidden shrink-0 min-h-[36px]"
                  loading="lazy"
                  alt=""
                  src="/youtubeicon-logo.svg"
                />
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit lg:text-xl xs:text-lg">
                    Youtube
                  </h2>
                </div>
              </div>

            </div>
            <div className="2xl:flex md:hidden md:flex-row 2xl:flex-row items-center justify-center  hidden w-full 2xl:gap-[70px] gap-[50px] text-[#a6a6a6] ">
              <div className="2xl:h-[38px] lg:h-[32px] h-[28px] flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-4">
                <img
                  className="lg:h-[32px] xs:h-[28px] h-[23px] w-[23px] xs:w-[28px] lg:w-[32px]"
                  loading="lazy"
                  alt=""
                  src="/instagram-logo.svg"
                />
                <div className="flex-1 flex flex-col items-start justify-start  px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit 2xl:text-xl text-lg">
                    Instagram
                  </h2>
                </div>
              </div>
              <div className="flex items-center py-0 pr-[3px] gap-4">
                <div className="2xl:h-[38px] lg:h-[32px] h-[28px] flex flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                  <img
                    className="2xl:h-[38px] h-[28px] lg:h-[32px] lg:w-[32px] w-[28px] 2xl:w-[38px]"
                    loading="lazy"
                    alt=""
                    src="/twitch-logo.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit 2xl:text-xl text-lg">
                    Twitch
                  </h2>
                </div>
              </div>

              <div className="h-[38px]  flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-4">
                <div className="h-[34px]  flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border">
                  <img
                    className="2xl:w-8 2xl:h-8 lg:h-7 lg:w-7 w-6 h-6 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/patreon-logo.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit 2xl:text-xl text-lg">
                    Patreon
                  </h2>
                </div>
              </div>

              <div className="h-[38px]  flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-4">
                <div className="h-[35px] flex flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                  <img
                    className="2xl:w-8 2xl:h-8 w-6 h-6  relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/cibtiktok-logo.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit 2xl:text-xl text-lg">
                    Tiktok
                  </h2>
                </div>
              </div>

              <div className="h-[38px]  flex flex-row items-center py-0 pr-[3px] pl-0 box-border gap-4">
                <img
                  className="2xl:h-9 2xl:w-[51px] h-6 w-[41px] relative overflow-hidden shrink-0 min-h-[36px]"
                  loading="lazy"
                  alt=""
                  src="/youtubeicon-logo.svg"
                />
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0">
                  <h2 className="m-0 self-stretch relative text-inherit font-['Semi'] font-inherit 2xl:text-xl text-lg">
                    Youtube
                  </h2>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class='xl:max-w-6xl 2xl:max-w-[1400px] 2xl:mx-auto xs:mx-10 mx-5 xl:mx-auto'>
          <div className="rounded-3xl relative 2xl:flex-row 2xl:flex md:flex md:flex-row md:gap-5 md:items-center xl:flex xl:flex-row xl:items-center 2xl:items-center bg-royalblue  2xl:px-32 lg:px-20 p-10 lg:py-14 md:p-10 sm:p-10">
            <div className="self-stretch flex xl:pr-10 mb-5 sm:mb-0 md:w-3/5 flex-col 2xl:items-start lg:items-center lg:justify-center sm:gap-[20px] gap-[30px] max-w-full">
              <div className="m-0 self-stretch relative 2xl:text-5xl xs:text-3xl lg:text-5xl md:text-3xl sm:text-3xl text-3xl sm:text-start text-center text-[#fff] font-['Bold']">
                Monetize, engage, grow. <br class='hidden 2xl:flex' />All through one platform
              </div>
              <div className="self-stretch 2xl:pr-20 relative lg:max-w-4xl text-center sm:text-start  text-base text-[#fff] font-['Face']">
                SaaS become a common delivery model for many business application,
                including office software, messaging software, payroll processing
                software, DBMS software, management software
              </div>
            </div>

            <div className="flex flex-col 2xl:flex-col xl:flex xl:flex-col sm:flex-col sm:mt-7 md:mt-0 2xl:mt-0 xl:mt-0  lg:flex-col lg:max-w-4xl sm:items-start items-end justify-start gap-[30px] ">
              <GroupComponent
                messageNotificationCircle="/activity-1.svg"
                engage="Monetize"
                analyzeYourPerformanceAnd="Analyze your performance and create goegeous report"
              />
              <GroupComponent
                messageNotificationCircle="/messagenotificationcircle.svg"
                engage="Engage"
                analyzeYourPerformanceAnd="Analyze your performance and create goegeous report"
              />
              <GroupComponent
                messageNotificationCircle="/users01.svg"
                engage="Grow"
                analyzeYourPerformanceAnd="Quiuckly navigate you anda engage with your adience"
                propDebugCommit="unset"
              />
            </div>
          </div>
        </div>      </div>

    </div>

  );
};

export default Landing;
