import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { gql, useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Feedback } from '../../Routes/Landing'

const Influencer = gql`
   mutation Influencer($type: String){
            Influencer(type: $type){
                id
            }
   }`
const Setup = () => {
    const navigate = useNavigate()
    const Types = [
        {
            name: "Musician",
            desc: "",
        
        },
        {
            name: "Artist",
            desc: "",
      
        },
        {
            name: "Digital Creator",
            desc: "",
       
        },
        {
            name: "Blogger",
            desc: "",
        
        },
        {
            name: "Author",
            desc: "",
         
        },
        {
            name: 'Educator',
            desc: "",
          
        },
        {
            name: "Journalist",
            desc: "",
        
        },
        {
            name: "Podcaster",
            desc: "",
           
        },
        {
            name: "Livestreamer",
            desc: "",
            
        },
        {
            name: 'Brand',
            desc: "",
          
        },
        {
            name: "Business",
            desc: "",
         
        },
        {
            name: "Non-Profit",
            desc: "",
           
        },
        {
            name: 'Other',
            desc: "",
           
        }
    ]
    const [influence] = useMutation(Influencer)

    return (
        <div class='w-full flex items-center bg-royalblue h-screen'>              
                  <div class='fixed top-0 w-full'>
                         <a href='/' class='self-stretch flex items-center justify-between 2xl:px-10 xs:m-5 m-5 2xl:m-0 2xl:py-5'>
                                <img src='/head.svg' class='h-9'/>
                         </a>
                  </div>
               <Feedback blue={false} />
             
               <div class='sm:max-w-md w-full m-5 p-7 xs:mx-5 sm:mx-auto bg-[#fff] shadow-lg border-[1px]  flex flex-col  border-gainsboro rounded-xl border-solid'>
                    <div class='flex items-center justify-center flex-col'>
                    <img src='/union5.svg' className='xl:h-14 lg:h-12 md:h-10 h-9' />
                    <div style={{ fontFamily: 'Semi' }} class='text-2xl mt-5 text-center'>Setup</div>

                    <div style={{ fontFamily: 'Bold' }} class='text-4xl mt-2 text-center'>What Are You?</div>
     </div>
                    
                        <Formik
                            initialValues={{ type: "" }}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true)
                                await influence({
                                    variables: { ...values, },
                                })
                                navigate('/setup2')
                                setSubmitting(false)
                            }}
                        >
                            {({ setFieldValue, handleSubmit, values }) => (
                                <div class='mt-10 sm:mx-auto sm:w-full max-w-xl'>
                                    <RadioGroup class='gap-2 flex flex-row flex-wrap items-center justify-center'>
                                        {Types.map(item => (
                                            <RadioGroup.Option class='block' onClick={() => { setFieldValue("type", item.name) }} value={item.name}>
                                                {({ checked }) => (
                                                    <div onClick={() => { setFieldValue("type", item.name) }} className={checked ? 'border-[1px] border-solid border-royalblue text-sm text-royalblue bg-[#fff] px-5 py-3.5 rounded-full' : 'bg-royalblue text-[#fff] flex text-sm rounded-full px-5 py-3.5'}>
                                                        <div style={{ fontFamily: 'Semi' }} class='text-md'>{item.name}</div>
                                                    </div>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </RadioGroup>
                                    <button
                                        style={{ fontFamily: 'Bold' }}
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="bg-royalblue p-6 text-[#fff] mt-10 w-full border-0 rounded-full"
                                    >
                                        Next
                                        <span aria-hidden="true">&rarr;</span>
                                    </button>
                                </div>
                            )}
                        </Formik>

                    </div>

             


            </div>
            
    
    )
}

export default Setup