import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../Data/Me';


const OrdersChart2 = () => {

    const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

    const { loading, error, data } = useQuery(ME_QUERY);

 

  useEffect(() => {
    if (data) {
        const payoutsByProduct = data.me.Payouts.reduce((acc, payout) => {
            const product = payout.Product.title;
            acc[product] = (acc[product] || 0) + 1;
            return acc;
          }, {});
    
          const productLabels = Object.keys(payoutsByProduct);
          const payoutCounts = Object.values(payoutsByProduct);
    

      setChartOptions({
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false,
        },
        },
        grid: {
            show: false
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
            bar: {
              borderRadius: 20, // Set the radius for rounded bars
              horizontal: false,
              columnWidth: '30%', // Adjust the column width to make bars skinnier
            },
          },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: productLabels,
          labels: {
            style: {
              colors: '#000', // Set the color of the labels
              fontSize: '12px', // Set the font size of the labels
              fontFamily: 'Semi', // Set the font family of the labels
              fontWeight: 600, // Set the font weight of the labels
            },
          },
        },
        yaxis: {
       
          labels: {
            formatter: function (val) {
              return `${val}`;
            },
            style: {
                colors: '#d1d5db', // Set the color of the labels
                fontSize: '12px', // Set the font size of the labels
                fontFamily: 'Semi', // Set the font family of the labels
                fontWeight: 600, // Set the font weight of the labels
              },
          },
        },
      
        colors: ['#0b5cff'], // Set your desired color here
                    
        fill: {
          type: '',
         
        },
        tickAmount: 5,
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}`;
            },
          },
        },
      });

      setChartSeries([
        {
            name: 'Orders',
            data: payoutCounts,
        },
      ]);
    }
  }, [data]);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return <Chart options={chartOptions} series={chartSeries} type="bar" height={250} />;

  };


  export default OrdersChart2