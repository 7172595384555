import React from 'react'
import Example from '../Components/Landing/Header2'
import { Tab } from '@headlessui/react'
import { BsInstagram, BsTwitter } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import FrameComponent7 from './Landing/FrameComponent7'


const Pro = () => {

    return (
        <div>
            <FrameComponent7 />
            <div className="relative 2xl:py-10 py-14 md:py-10 w-full ">
                <div class="flex flex-col lg:px-5 2xl:max-w-[1400px] xl:max-w-[1200px] lg:max-w-[1000px] md:max-w-[700px] sm:mx-10 xs:mx-10 mx-5 xl:mx-auto 2xl:mx-auto lg:mx-auto md:mx-auto items-center justify-center 2xl:gap-10 lg:gap-5 xl:gap-5 2xl:pt-28  xl:py-24 lg:py-16 md:py-20 sm:py-20 py-14">
                    <div className="font-['Bold'] text-center mb-4 text-black 2xl:text-6xl xl:text-6xl lg:text-5xl md:text-5xl sm:text-4xl text-4xl">
                        Support & Help
                    </div>
                    <div class='flex flex-col w-full max-w-screen-sm'>
                    <input placeholder='Subject of question' class='mt-4 w-full placeholder:text-gray-400 outline-0 px-5 font-["Face"] py-5 border-[1px] rounded-xl border-gainsboro border-solid' />
                    <textarea placeholder='Question'  class='mt-4 w-full placeholder:text-gray-400 outline-0 px-5 font-["Face"] py-5 border-[1px] rounded-xl border-gainsboro border-solid' />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pro