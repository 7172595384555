import { useMemo } from "react";

const GroupComponent = ({
  messageNotificationCircle,
  engage,
  analyzeYourPerformanceAnd,
  propDebugCommit,
  propDebugCommit1,
  propWidth,
}) => {
  const frameButtonStyle = useMemo(() => {
    return {
      debugCommit: propDebugCommit,
    };
  }, [propDebugCommit]);

  const engageStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className="self-stretch flex flex-row gap-5 items-center">
      <button
        className="cursor-pointer [border:none] pt-5 pb-4 pr-[18.5px] pl-[19.1px] bg-[#fff] lg:h-[60px] md:h-[45px] md:w-[46.6px] lg:w-[61.6px] rounded-full md:justify-center md:items-center  flex flex-row items-start justify-start box-border shrink-0 "
        style={frameButtonStyle}
      >
        <img
          className="2xl:h-6 md:w-5 w-4 h-4 md:w-5 2xl:w-6 lg:h-6 lg:h-6 xl:h-6 xl:w-6 relative"
          alt=""
          src={messageNotificationCircle}
        />
      </button>
      <div className="flex-1 flex flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border 2xl:min-w-[285px] max-w-full">
        <div className="self-stretch flex flex-col items-start justify-start 2xl:gap-[10px] md:gap-1 shrink-0 [debug_commit:f6aba90]">
          <div
            className="m-0 2xl:w-[133.4px] relative text-xl font-['Bold'] md:text-lg text-[#fff] inline-block mq450:text-3xl"
            style={engageStyle}
          >
            {engage}
          </div>
          <div className="self-stretch relative text-sm md:text-xs text-[#fff] font-['Face']">
            {analyzeYourPerformanceAnd}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupComponent;
