import React from 'react'
import FrameComponent from './Preview/FrameComponent'
import Main from './Preview/Main'
import { FaEnvelope } from 'react-icons/fa'

const Preview = () => {
    return (
        <div className="w-full bg-white max-w-screen-2xl mx-auto">
            <div className="w-full grid grid-cols-2 gap-10 relative h-full">

                <div className="flex flex-col">
                    <div class='fixed bottom-10'>
                        <div class='py-2 px-3 gap-3 rounded-xl text-[#fff] text-sm font-["Semi"] flex items-center bg-black'>
                            <img className='w-4 h-4' src='cube.svg' />
                            Try Creatorzhub
                        </div>
                    </div>
                    <div class='flex flex-col fixed top-20'>
                        <img className="relative rounded-full w-48 h-48" src="bld2.png" />

                        <div className="flex-col justify-start items-start gap-3 inline-flex">
                            <div className="flex-col justify-start items-start flex mt-5">
                                <div className="text-black text-[44px] font-['Bold'] leading-[52.80px]">The Co-working Space</div>
                            </div>
                            <div className="flex-col justify-start items-start flex">
                                <div className="text-neutral-600 text-xl font-normal font-['Normal'] leading-7">
                                    We're building Arc – a better way to use the internet
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative overflow-y-scroll pt-20 flex flex-col h-full">
                    <div className="text-black text-lg font-semibold px-3 font-['Inter'] leading-7">Newsletter</div>
                    <div class='w-full my-5'>

                        <div className="self-stretch p-5 relative bg-white rounded-[28px] border-[1px] border-solid border-gainsboro shadow flex-col justify-start items-start flex">
                            <div class='flex justify-between w-full items-center'>
                                <div>
                                    <div class='text-xl font-["Bold"]'>Subscribe to newsletter</div>
                                    <div class='text-gray-400 my-1 mb-3 text-[13px] font-["Face"]'>Subscribe and get the latest details on new drops and everything!!</div>
                                </div>
                                <div>
                                    <img className="w-10 h-10 rounded-[10px]" src="bld2.png" />
                                </div>
                            </div>
                            <div class='relative w-full flex'>
                                <input placeholder='example@gmail.com' class='self-stretch w-full px-5 inline-block py-3.5 placeholder:text-gray-300 rounded-lg border-gainsboro border-[1px] border-solid' />
                            </div>
                            <div class='w-full flex h-full space-x-2 items-center'>
                                <button class='w-full flex items-center justify-center gap-3 h-full p-3 rounded-lg mt-2 text-sm border-[0px] bg-black text-[#fff] font-["Bold"]'>
                                    Subscribe now
                                </button>

                            </div>

                        </div>



                    </div>
                    <div className="text-black text-lg font-semibold px-3 font-['Inter'] leading-7">Products</div>

                    <div className="grid grid-cols-2 gap-5 mt-5">


                        <div className="py-5 px-4 justify-start border-[1px] border-solid border-gainsboro items-start flex flex-col shadow rounded-[28px]">
                            <div className="flex-col justify-start items-start gap-2">
                                <div className="flex-col justify-start items-start gap-3 flex">
                                    <img className="w-10 h-10 rounded-[10px]" src="bld2.png" />

                                    <div className="flex-col justify-start items-start gap-1 flex">
                                        <div className="flex-col justify-start items-start flex">
                                            <div className="text-black text-sm font-normal font-['Inter'] leading-none">Arc is the best web browser to come out in the last decade</div>
                                        </div>
                                        <div className="justify-start items-center inline-flex">
                                            <div className="text-black/opacity-60 text-xs font-normal font-['Inter'] leading-none">inverse.com</div>
                                        </div>
                                    </div>
                                </div>
                                <div class='w-full flex flex-col'>
                                <img className="rounded-[10px] w-full my-5" src="bld2.png" />
                                </div>
                                <div class='w-full bg-black text-[#fff] text-center rounded-lg text-[15px] font-["Semi"] py-2.5'>
                                    Buy

                                </div>
                            </div>


                        </div>


                        <div className="py-5 px-4 justify-start border-[1px] border-solid border-gainsboro items-start flex flex-col shadow rounded-[28px]">
                            <div className="flex-col justify-start items-start gap-2">
                                <div className="flex-col justify-start items-start gap-3 flex">
                                    <img className="w-10 h-10 rounded-[10px]" src="bld2.png" />

                                    <div className="flex-col justify-start items-start gap-1 flex">
                                        <div className="flex-col justify-start items-start flex">
                                            <div className="text-black text-sm font-normal font-['Inter'] leading-none">Arc is the best web browser to come out in the last decade</div>
                                        </div>
                                        <div className="justify-start items-center inline-flex">
                                            <div className="text-black/opacity-60 text-xs font-normal font-['Inter'] leading-none">inverse.com</div>
                                        </div>
                                    </div>
                                </div>
                                <div class='w-full flex flex-col'>
                                <img className="rounded-[10px] w-full my-5" src="bld2.png" />
                                </div>
                                <div class='w-full bg-black text-[#fff] text-center rounded-lg text-[15px] font-["Semi"] py-2.5'>
                                    Buy

                                </div>
                            </div>


                        </div>


                    </div>
                    <div className="text-black text-lg font-semibold my-5 px-3 font-['Inter'] leading-7">Featured</div>
                    <img class='w-full rounded-xl' src='bld2.png' />
                    <div className="text-black text-lg font-semibold my-5 px-3 font-['Inter'] leading-7">Links</div>
                    <div class='grid grid-cols-4 w-full  gap-3'>
                        <div class='self-stretch p-4 rounded-xl border-[1px] border-solid border-gainsboro shadow'>
                       
                        </div>
                        <div class='self-stretch p-4 rounded-xl border-[1px] border-solid border-gainsboro shadow'>

                        </div>
                        <div class='self-stretch p-4 rounded-xl border-[1px] border-solid border-gainsboro shadow'>

                        </div>
                        <div class='self-stretch p-4 rounded-xl border-[1px] border-solid border-gainsboro shadow'>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Preview