import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation } from '@apollo/client'
import { gql } from 'graphql-tag'
import { ME_QUERY } from "../Data/Me";
import { createClient } from '@supabase/supabase-js'
import { TrashIcon } from '@heroicons/react/20/solid'
const FULFILL = gql`
  mutation updatePost(
    $id: Int!,
   $title: String, 
   $description: String,
   $file: String,
    $price: Int) {
    updatePost(
        id: $id,
        title: $title,
        file: $file,
        description: $description,
        price: $price
        ) {
      id
    }
  }
`;

const DELETE_POST = gql`
   mutation deletePost($postId: Int!, $setDeleted: Boolean!) {
    deletePost(postId: $postId, setDeleted: $setDeleted) {
      id
     
    }
   }
`

const ProductInfo = ({ item, isOpen, onClose, }) => {

    const [confirmation, setConfirmation] = useState(false);


    const handleClose = () => {
        setConfirmation(false);
    };

    const handleDelete = async () => {
        try {
            if (confirmation) {
                const { dataDelete } = await deletePost({
                    variables: {
                        postId: item.id,
                        setDeleted: true, // Toggle the current state
                    },
                });

                // Handle the response data here if needed
                console.log(dataDelete);

                // Close the modal after deletion
                handleClose();
                onClose()
            } else {
                // Show confirmation message
                setConfirmation(true);
            }
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };
    const [fulfill] = useMutation(FULFILL, { refetchQueries: [ME_QUERY] })
    const [formData, setFormData] = useState({
        title: item?.title || '',
        description: item?.description || '',
        price: item?.price || 0,
    });
    const [deletePost] = useMutation(DELETE_POST, { refetchQueries: [ME_QUERY] })
    // Set initial formData based on item when it changes
    useEffect(() => {
        setFormData({
            title: item?.title || '',
            description: item?.description || '',
            price: item?.price || 0,
        });
    }, [item]);

    // Handle changes to form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the input name is "price," parse the value as an integer
        const parsedValue = name === 'price' ? parseInt(value, 10) || 0 : value;

        setFormData({
            ...formData,
            [name]: parsedValue,
        });
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        });
    };

    const [fileUrl, setFileUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [publicFile, setFile] = useState(null);
    const [fileSource, setFilename] = useState(null)
    async function uploadFile(event) {
        try {
            setUploading(true);

            if (!event.target.files || event.target.files.length === 0) {
                throw new Error('You must select an image to upload.');
            }

            const supabase = createClient(
                'https://rbsteedexxccoqrnyczp.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJic3RlZWRleHhjY29xcm55Y3pwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU5MzIwMTksImV4cCI6MjAwMTUwODAxOX0.FT3oCWVeAGjrMRhLTdCFBSOtIdMDeZj6ApuALkJ185A'
            );
            const file = event.target.files[0];
            const fileExt = file.name.split('.').pop();
            const fileName = `${Math.random()}.${fileExt}`;
            const filePath = `${fileName}`;
            setFilename(fileName)
            setFileUrl(filePath);
            let { error: uploadError } = await supabase.storage.from('conten').upload(filePath, file);
            let { data } = supabase.storage.from('conten').getPublicUrl(`${filePath}`);

            const publicFile = data.publicUrl;
            setFile(publicFile);
            console.log(data.publicUrl);
            if (uploadError) {
                throw uploadError;
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setUploading(false);
        }
    }


    const handleFulfillment = async (e) => {

        try {
            const { data } = await fulfill({
                variables: {
                    id: item.id,
                    title: formData.title,
                    description: formData.description,
                    price: formData.price,
                    file: publicFile
                },
            });

            // Handle the response data here if needed
            console.log(data);
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };
    return (
        <>
            <Transition appear show={isOpen} as={React.Fragment}>
                <div>
                    <Dialog
                        as="div"
                        className={`fixed inset-0 ${confirmation ? 'max-w-2xl' : 'max-w-6xl'} mx-auto`}
                        onClose={handleClose}
                    >
                        <div className="flex items-center w-full justify-center min-h-screen">
                            <Dialog.Overlay className="fixed inset-0 bg-black/25" onClick={handleClose} />

                            <div className="relative z-50 w-full bg-white p-6 rounded-xl">


                                {confirmation ? (
                                    <div>
                                        <p class='text-lg font-["Semi"] text-center my-3'>Are you sure you want to delete "{item?.title}"?</p>
                                        <div class='flex items-center space-x-2'>
                                            <button onClick={handleDelete} className="py-4 bg-royalblue font-['Semi'] text-[#fff] border-0 outline-0 w-full rounded-lg">Confirm Delete</button>
                                            <button onClick={() => setConfirmation(false)} className="py-4 border-royalblue border-[2px] bg-[#fff] font-['Semi'] text-royalblue w-full rounded-lg">Cancel</button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {isOpen && item !== null && (
                                            <div class='w-full justify-center flex flex-col items-center'>
                                                <div onClick={onClose} class='absolute cursor-pointer top-6 right-5'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>

                                                </div>
                                                <img src='/union5.svg' class='h-14' />
                                                <div class='text-4xl font-["Bold"] mb-16 mt-4'>Edit Product</div>

                                                <div class='flex space-x-5'>
                                                    <div class='w-1/3'>
                                                        <div class='p-5 border-gainsboro border-[1px] rounded-xl border-solid'>
                                                        <div class='text-lg font-["Semi"]'>Thumbnail</div>
                                                            <img class='rounded-2xl w-full mt-4' src={item.thumbnail} />
                                                            <div class='text-gray-400 text-xs mt-3 px-2 text-center'>Set the product image thumbnail. Only *.png, *.jpeg, and *.jpg image files are accepted.</div>

                                                        </div>
                                                    </div>
                                                    <div class='px-5 w-2/3'>
                                                        <div className='flex space-x-5 w-full'>
                                                            <div className='w-full flex flex-col'>
                                                                <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Product name</div>
                                                                <input
                                                                    name='title'
                                                                    value={formData.title}
                                                                    onChange={handleChange}
                                                                    style={{ fontFamily: 'Face' }}
                                                                    className='className="self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4"'
                                                                />
                                                                <div class='text-xs mt-2 text-gray-500  font-["Face"]'>A product name is required & recommended to be unique</div>

                                                            </div>
                                                            <div className='w-full flex flex-col'>
                                                                <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Description</div>
                                                                <textarea
                                                                    onChange={handleChange}
                                                                    name='description'
                                                                    value={formData.description}
                                                                    style={{ fontFamily: 'Face' }}
                                                                    className='self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4'
                                                                />
                                                                                                                                <div class='text-gray-500 text-xs font-["Face"] mt-2'>Set a brief description of product selling</div>

                                                            </div>
                                                        </div>
                                                        <div class='flex w-full gap-7'>
                                                        <div className='w-1/3 mt-3'>
                                                            <div class='text-sm mb-2' style={{ fontFamily: 'Semi' }}>Base price</div>
                                                            <input
                                                                onChange={handleChange}
                                                                name='price'
                                                                value={formData.price}
                                                                className='self-stretch focus:ring-offset-0 focus:ring-[5px] focus:ring-royalblue/[.100] focus:border-royalblue/[.75] focus:border-[1.5px] border border-gainsboro border-solid outline-0 self-stretch rounded-[13px] px-5 border sm:py-5 py-4'
                                                            />
                                                            <div class='text-gray-500 text-xs font-["Face"] mt-2'>Set a price</div>

                                                        </div>
                                                        <div class='border w-2/3 p-5 mt-5 rounded-xl border-gainsboro border-solid'>
                                                        <div class='text-md font-["Bold"]'>Details</div>
                                                        <div class='flex items-center justify-between'>
                                                                    <div>
                                                                        <label class='text-sm font-["Semi"]'>Status</label>
                                                                        <div class='text-xs px-4 py-2 rounded-full mt-2 font-["Semi"] bg-green-100 text-green-400'>
                                                                            Active
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class='text-sm font-["Semi"]'>Posted</label>
                                                                        <div class='text-sm rounded-full mt-2 font-["Face"]'>
                                                                            {formatDate(item.createdAt)}

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class='text-sm font-["Semi"]'>Product</label>
                                                                        <div class='text-sm rounded-full mt-2 font-["Face"]'>
                                                                            {item.isCourse === true && 'Course'}
                                                                            {item.isDigitalDownload === true && 'Download'}
                                                                            {item.isPhysical === true && item.isClothing === true ? 'Clothing' : 'Merchandise'}


                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class='text-sm font-["Semi"]'>Clicks</label>
                                                                        <div class='text-sm rounded-full mt-2 font-["Face"]'>
                                                                            {item.points} Clicks



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class='flex items-center space-x-4 w-full mt-10'>
                                                    <button onClick={handleDelete} class='p-4 rounded-xl bg-royalblue border-0 '>
                                                        <TrashIcon class='w-5 text-[#fff]'/>
                                                    </button>
                                                    <label
                                                                    style={{ fontFamily: 'Semi' }}
                                                                    className='w-full flex text-sm py-4 items-center justify-center text-[#fff] gap-2 rounded-full bg-royalblue'
                                                                >

                                                                    Upload File
                                                                    <input
                                                                        id='inputFile'
                                                                        style={{
                                                                            display: 'none',
                                                                        }}
                                                                        type='file'
                                                                        onChange={uploadFile}

                                                                    />
                                                                </label>
                                                    <button onClick={handleFulfillment} class='w-full py-5 rounded-xl border-0 outline-0 text-[#fff] bg-royalblue font-["Semi"]'>Edit</button>

                                                </div>
                                                    </div>
                                                </div>
                                          
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </Transition>

        </>
    )
}

export default ProductInfo