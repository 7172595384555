import { gql } from '@apollo/client';

export const All = gql`
  {
    OnlineCourses{
      id 
      title
      isAudio
      isCourse
      isDigitalDownload
      isEbook
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    courseProducts{
      id 
      title
      isEbook
      isAudio
      isCourse
      isDigitalDownload
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    allDigitalProducts{
      id 
      title
      isEbook
      isAudio
      isCourse
      isDigitalDownload
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    clothingProducts{
      id 
      title
      isEbook
      likes{
        id
      }
      isClothing
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    merchandiseProducts{
      id 
      title
      isEbook
      likes{
        id
      }
      isMerchandise
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    allPhysicalProducts{
      id 
      title
      isEbook
      likes{
        id
      }
      isPhysical
      isMerchandise
      isClothing
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    Audios{
      id 
      title
      isAudio
      isCourse
      isDigitalDownload
      thumbnail
      author{
        id
        name
        avatar
      }
    }
    featured {
      id
      title
      thumbnail
      price
      description
      isDigitalDownload
      isAudio
      imageDigitalDownload
      isWebinar
      file
      isCourse
      isEbook
      youtubeWebinar
      twitchWebinar
      zoomWebinar
      author {
        id
        name
        avatar
        type
        secondary
        bio
        posts {
          id
          description
          title
          thumbnail
          isEbook
          isAudio
          isCourse
          isDigitalDownload
        }
      }
    }
    topUsers {
      id
      name
      avatar
      secondary
      bio
      type
      Website{
        id
      }
    }
    topUser{
      id
      name
      avatar
      secondary
      bio
      type
      Website{
        id
      }
      
    }
    topProduct{
      id
      title
      price
      thumbnail
      description
      author{
        id 
        name
        avatar
        type
      }
    }
    sixUsers {
      id
      name
      secondary
      avatar
      type
      bio
    }
    someTags{
      id
      tag
    }
    justTags{
      id
      tag
      category
    }
    allTrendingProducts{
      id
      title
      price
      thumbnail
      description
      author{
        id
        name
        avatar
      }
      isEbook
      isAudio
      isClothing
      isPhysical
      isMerchandise
      isDigitalDownload
    
    }
    trendingUsers{
      id
      name
      avatar
      type
    }
    trendingProducts{
      id
      title
      price
      thumbnail
      description
      author{
        id
        name
        avatar
      }
      isEbook
      isAudio
      isClothing
      isPhysical
      isMerchandise
      isDigitalDownload
    }
    trendingUserTag{
      id
      tag
    }
    latestProducts{
      id
      thumbnail
      author{
        id
        name
        avatar
      }
      title
      isEbook
      isAudio
      isClothing
      isPhysical
      isMerchandise
      isDigitalDownload
    }
    featuredUsers{
      id
      name
      avatar
    }
    featuredProducts{
      id
      thumbnail
      author{
        id
        name
        avatar
      }
      title
      isEbook
      isAudio
      isClothing
      isPhysical
      isMerchandise
      isDigitalDownload
    }
    topUserTag{
      id
      tag
    }
    latestUsers{
      id
      avatar 
      name
    }
    generalStores {
      id
      name
      secondary
      type
      bio
      avatar
      Website{
        id
      }
    }
    ebookProducts{
      id
      thumbnail 
      author{
        id
        avatar
        name
      }
      title
      isEbook
      isAudio
      isClothing
      isPhysical
      isMerchandise
      isDigitalDownload
    }
    featuredStores {
      id
      name
      secondary
      type
      avatar
      bio
    }
  }
`;

