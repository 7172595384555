import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../Data/Me';

const OrdersChart = () => {
  const [chartSeries, setChartSeries] = useState([]);
  const { loading, error, data } = useQuery(ME_QUERY);

  useEffect(() => {
    if (data) {
      const payoutsByDate = data.me.Payouts.reduce((acc, payout) => {
        const date = new Date(payout.createdAt);
        const month = date.toLocaleString('default', { month: 'short', year: 'numeric' });
        acc[month] = (acc[month] || 0) + 1;
        return acc;
      }, {});

      const dates = Object.keys(payoutsByDate);
      const payoutCounts = Object.values(payoutsByDate);

      setChartSeries([
        {
          name: 'Payouts',
          data: payoutCounts,
        },
      ]);

      setChartOptions({
        chart: {
          id: 'simple-chart',
          toolbar: {
            show: false,
          },
          type: 'area',
        },
        colors: ['#0b5cff'], // Set your desired color here
        grid: {
          show: false,
        },
    
        yaxis: {
          labels: {
            formatter: function (val) {
              return `${val}`;
            },
            style: {
              colors: '#d1d5db', // Set the color of the labels
              fontSize: '12px', // Set the font size of the labels
              fontFamily: 'Semi', // Set the font family of the labels
              fontWeight: 600, // Set the font weight of the labels
            },
          },
          tickAmount: 5,
        },
        xaxis: {
          categories: dates,
          labels: {
            style: {
              colors: '#000', // Set the color of the labels
              fontSize: '12px', // Set the font size of the labels
              fontFamily: 'Semi', // Set the font family of the labels
              fontWeight: 600, // Set the font weight of the labels
            },
          },
        },
        dataLabels: {
          enabled: false, // Set to false to hide data labels
        },
      });
    }
  }, [data]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'simple-chart',
      toolbar: {
        show: false,
      },
    },

    colors: ['#0b5cff'], // Set your desired color here
                    
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.2,
        opacityTo: 0.2,
        stops: [0, 100],
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${val}`;
        },
        style: {
          colors: '#d1d5db', // Set the color of the labels
          fontSize: '12px', // Set the font size of the labels
          fontFamily: 'Semi', // Set the font family of the labels
          fontWeight: 600, // Set the font weight of the labels
        },
      },
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: '#000', // Set the color of the labels
          fontSize: '12px', // Set the font size of the labels
          fontFamily: 'Semi', // Set the font family of the labels
          fontWeight: 600, // Set the font weight of the labels
        },
      },
    },
    dataLabels: {
      enabled: false, // Set to false to hide data labels
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <Chart options={chartOptions} series={chartSeries} type="area" height={250} />;
};

export default OrdersChart;
