import { LinkIcon, TagIcon, EnvelopeIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline'
import { BanknotesIcon, BuildingStorefrontIcon, ChartBarIcon, CurrencyDollarIcon, SparklesIcon, SquaresPlusIcon } from '@heroicons/react/20/solid'
import React from 'react'
import Example from '../Components/Landing/Header2'
import FrameComponent7 from './Landing/FrameComponent7'
import { Feedback } from './Landing'

const Blog = () => {
  const handleButtonClick = () => {
    // Navigate to Gmail with a pre-filled email address
    const emailAddress = 'creatorzhubofficial@gmail.com'; // Change this to your desired email address
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(emailAddress)}`;
    window.location.href = gmailUrl;
  };

  return (
    <div>
      <FrameComponent7 />
      <div className="py-10 2xl:py-0 2xl:pb-10 md:py-10">
       <Feedback />
        <div class="2xl:flex xl:flex lg:flex 2xl:flex-row lg:px-5 2xl:max-w-[1370px] xl:max-w-[1200px] lg:max-w-[1000px] md:max-w-[700px] sm:mx-10 xs:mx-10 mx-5 xl:mx-auto 2xl:mx-auto lg:mx-auto md:mx-auto xl:flex-row lg:flex-row md:flex-row md:flex sm:flex-row sm:flex items-center justify-center r 2xl:gap-20 lg:gap-5 xl:gap-5 2xl:pt-36  xl:py-24 lg:py-16 md:py-20 sm:py-20 py-14">
          <div class="flex flex-col 2xl:px-0 2xl:pr-0 xl:px-10 xl:pr-0 md:px-0 px-5 sm:pr-40 xs:pr-20">
            <div class="m-0 2xl:text-7xl text-royalblue xl:max-w-xl xl:text-[55px] lg:text-[45px] xs:max-w-sm md:max-w-2xl md:text-[60px] text-[45px] mb-3 sm:text-[50px] 2xl:leading-[95px] font-['Bold'] inline-block">
              <p class="m-0">About us</p>
            </div>
            <div class='2xl:text-[50px] xl:text-[50px] lg:text-[40px] md:text-[30px] text-[30px] font-["Bold"] mb-5'>Empowering creators through tailored digital services.</div>
            <div class="flex flex-col items-start justify-start pt-0 xs:pb-5 pb-5 pl-0 md:pr-0 xs:gap-[50px] gap-[25px]">

              <div class="w-full leading-[30px] font-['Face'] max-w-full">

                Introducing Creatorzhub, your all-in-one platform for monetizing and engaging your audience effortlessly. With seamless tools for ad integration, subscription services, and merchandise sales, monetization becomes a breeze. Plus, interactive features like live chats and polls foster deeper connections, while powerful analytics empower you to refine your strategies and fuel your growth. Join Creatorzhub today and unleash your creative potential!              </div>
            </div>
            <div class="flex flex-col items-start justify-start py-0 pr-[9px] pl-0">
              <a href='/register' class="cursor-pointer no-underline [border:none] xs:py-5 py-5 px-6 xs:px-[30px] bg-royalblue rounded-full text-[#fff] flex flex-row items-start justify-start whitespace-nowrap hover:bg-dodgerblue">
                <b class="relative xs:text-sm text-xs  inline-block font-['Semi'] text-white text-left">Get started <span aria-hidden="true">&rarr;</span></b>
              </a>
            </div>

          </div>
          <img class="2xl:h-[530px] xl:h-[459px] lg:h-[400px] lg:flex xl:flex 2xl:flex md:hidden sm:hidden xs:hidden 2xs:hidden  overflow-hidden object-contain min-w-[456px] shrink-0" loading="lazy" alt="" src="/Group-38.png" />
        </div>
        <div class='grid 2xl:max-w-[1400px] xl:max-w-[1200px] lg:max-w-[1000px] md:max-w-[700px] sm:mx-10 xs:mx-10 mx-7 md:mx-auto lg:grid-cols-3 gap-5'>
          <div class='p-10 bg-royalblue rounded-xl'>
            <div class='text-3xl font-["Bold"] text-[#fff]'>Stay up to date?</div>
            <div class='font-["Face"] mb-8 text-[#fff] mt-2'>View our socials stay up to date with everything.</div>
            <a href='/socials' class='px-5 py-4 text-sm no-underline rounded-full bg-[#fff] text-royalblue font-["Semi"]'>View socials</a>
          </div>
          <div class='p-10 bg-royalblue rounded-xl'>
            <div class='text-3xl font-["Bold"] text-[#fff]'>Need help?</div>
            <div class='font-["Face"] mb-8 text-[#fff] mt-2'>Email us or contact us. We respond in under 48 hours.</div>
            <button onClick={handleButtonClick} class='px-5 py-4 border-0 text-sm no-underline rounded-full bg-[#fff] text-royalblue font-["Semi"]'>Get help</button>
          </div>
          <div class='p-10 bg-royalblue rounded-xl'>
            <div class='text-3xl font-["Bold"] text-[#fff]'>Have a question?</div>
            <div class='font-["Face"] mb-8 text-[#fff] mt-2'>Ask questions on the faq page. Those are also answered under 48 hours.</div>
            <a href='/faq' class='px-5 py-4 text-sm no-underline rounded-full bg-[#fff] text-royalblue font-["Semi"]'>Ask questions</a>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Blog