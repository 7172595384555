import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../Data/Me';


const ProductRevenueChart = () => {

  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  const { loading, error, data } = useQuery(ME_QUERY);



  useEffect(() => {
    if (data) {
      const productRevenue = data.me.Payouts.reduce((acc, payout) => {
        const product = payout.Product.title;
        const amount = payout.amount;
        acc[product] = (acc[product] || 0) + amount;
        return acc;
      }, {});


      const productLabels = Object.keys(productRevenue);
      const revenueData = Object.values(productRevenue);
      const maxRevenue = Math.max(...revenueData);

      const backgroundData = revenueData.map(() => maxRevenue);

      setChartOptions({
        chart: {
          type: 'area',
          height: 250,
          toolbar: {
            show: false,
          },
        },

        grid: {
          show: false
        },
        dataLabels: {
          enabled: false,
        },

        stroke: {
          stroke: 2
        },
        xaxis: {
          categories: productLabels,
          labels: {
            style: {
              colors: '#000', // Set the color of the labels
              fontSize: '12px', // Set the font size of the labels
              fontFamily: 'Semi', // Set the font family of the labels
              fontWeight: 600, // Set the font weight of the labels
            },
          },
        },
        yaxis: {

          labels: {
            formatter: function (val) {
              return `${val}`;
            },
            style: {
              colors: '#d1d5db', // Set the color of the labels
              fontSize: '12px', // Set the font size of the labels
              fontFamily: 'Semi', // Set the font family of the labels
              fontWeight: 600, // Set the font weight of the labels
            },
            show: false
          },
        },

        colors: ['#0560FD'], // Set colors for background and main bars

        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.2,
            opacityTo: 0.2,
            stops: [0, 100],
          },

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `$${val}`;
            },
          },
        },
      });


      setChartSeries([

        {
          name: 'Revenue',
          data: revenueData,
        },
      ]);
    }
  }, [data]);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return <Chart options={chartOptions} series={chartSeries} type="area" height={250} />;

};

export default ProductRevenueChart