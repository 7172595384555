import React from 'react'
import kyro from '../Assets/C.png'
const Loading = () => {
    return (
        <div class='flex items-center justify-center h-screen'>
        
            <img src='/union5.svg' alt=''/>

        </div>
    )
}

export default Loading