import React from 'react'
import { Bottom, FrameComponent, Head, Logout, User } from './DataProcessor'
import { Feedback } from './Landing'
import { ME_QUERY } from '../Data/Me'
import { useQuery } from '@apollo/client'
import { Items } from './DataProcessor'
import Template from '../Templates/Storefront/Template'
import EditTemplate from '../Templates/Storefront/EditTemplate'

const Storefront = ({ allData }) => {
    const { data, error, loading } = useQuery(ME_QUERY)
    const screenshotUrlPreview = `https://api.screenshotmachine.com?key=428848&url=https://creatorzhub.com/preview&dimension=1024x768`;
    if (error) return <div>{error.message}</div>
    if (loading) return <div>Loading</div>
    return (
        <div>
            <div class={`w-full relative hidden md:flex h-10 flex items-center justify-center text-[#fff] font-["Semi"] text-sm bg-slate-800`}>
                Welcome to Creatorzhub's beta product! ✨🎊


            </div>
            <Feedback blue={true} />

            <div className="w-full fixed top-10 rounded-xl bg-main-color-white flex flex-row items-start justify-start leading-[normal] tracking-[normal] mq1050:pl-5 mq1050:pr-5 mq1050:box-border">

                <div className="relative w-[280px] border-r-[1px] border-0 border-solid border-gainsboro bg-main-color-white box-border flex flex-col items-start justify-between overflow-y-auto min-h-screen">
                    <Head />
                    <div className="self-stretch flex flex-col pt-6 items-start justify-start px-[23px] gap-[15px] shrink-0 [debug_commit:f6aba90] border-t-[1px] border-solid border-whitesmoke-200 mq750:pt-5 mq750:pb-[183px] mq1050:pt-[21px] mq1050:pb-[281px] border-0 mq450:gap-[18px] overflow-y-auto flex-grow">
                        <User data={data.me} />
                        <Items isStoredront={true} data={data.me} />
                    </div>
                    <div className="self-stretch flex-shrink-0">
                        <Bottom allData={data.me} />
                    </div>
                </div>
                <main className="flex-1 flex flex-col w-full h-full items-start justify-start gap-[27px] max-w-[calc(100%_-_280px)] mq1050:max-w-full">
                    <FrameComponent data={data.me} />
                    <section className="self-stretch flex flex-row items-start justify-start py-0 px-8  overflow-y-scroll  h-full box-border g-full max-w-full text-left text-11xl text-gray1-200 font-body-medium-medium">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[24px] max-w-full">
                            <div className="self-stretch flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                                        <div className="flex flex-row items-center justify-start gap-[24px]">
                                            <h1 className="m-0 relative text-inherit leading-[48px] font-['Bold'] mq1050:text-5xl mq1050:leading-[38px] mq450:text-lg mq450:leading-[29px]">
                                                Mobile storefront
                                            </h1>

                                        </div>

                                    </div>
                                </div>
                                <div className="relative text-sm tracking-[-0.01em] leading-[20px] font-medium text-darkgray-300">
                                    Welcome back, {data.me.name}
                                </div>
                            </div>
                            <div class='w-full flex flex-col'>
                                {!data.me.Storefront ? (
                                    <Template />
                                ) : (
                                    <EditTemplate />
                                )}




                            </div>
                        </div>
                    </section>

                </main>
            </div>


        </div>
    )
}

export default Storefront