import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../Data/Me';


const ProductRevenueChart2 = () => {

    const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

    const { loading, error, data } = useQuery(ME_QUERY);

 

  useEffect(() => {
    if (data) {
      const productRevenue = data.me.posts.reduce((acc, payout) => {
        const product = payout.title;
        const points = payout.points;
        acc[product] = (acc[product] || 0) + points;
        return acc;
      }, {});

      const productLabels = Object.keys(productRevenue);
      const revenueData = Object.values(productRevenue);

      setChartOptions({
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false,
        },
        },
        grid: {
            show: false
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 10, // Set the radius for rounded bars
            horizontal: false,
            columnWidth: '30%', // Adjust the column width to make bars skinnier
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: productLabels,
          labels: {
            style: {
              colors: '#000', // Set the color of the labels
              fontSize: '12px', // Set the font size of the labels
              fontFamily: 'Semi', // Set the font family of the labels
              fontWeight: 600, // Set the font weight of the labels
            },
          },
        },
        yaxis: {
       
          labels: {
            formatter: function (val) {
              return `${val}`;
            },
            style: {
                colors: '#d1d5db', // Set the color of the labels
                fontSize: '12px', // Set the font size of the labels
                fontFamily: 'Semi', // Set the font family of the labels
                fontWeight: 600, // Set the font weight of the labels
              },
          },
        },
      
        colors: ['#0560FD'], // Set your desired color here
                    
        fill: {
          type: '',
         
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}`;
            },
          },
        },
      });

      setChartSeries([
        {
          name: 'Clicks',
          data: revenueData,
        },
      ]);
    }
  }, [data]);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return <Chart options={chartOptions} series={chartSeries} type="bar" height={250} />;

  };

  export default ProductRevenueChart2