import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from './Routes/Landing'
import Authenticated from './Components/Authenicated'
import './App.css';
import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup';
import Setup from './Components/Setup/Setup';
import Setup2 from './Components/Setup/Setup2';
import Setup3 from './Components/Setup/Setup3';
import Store from './Routes/Store';
import Pro from './Routes/Pro';
import Basic from './Templates/Template1'
import Edit from './Templates/EditTemplate'
import { Faq } from './Routes/Faq'
import Blog from './Routes/Blog'
import Onlinemarketplace from './Routes/Onlinemarketplace'
import { HomeDash } from './Routes/HomeDash'
import Productpage from './Routes/Productpage';
import Analytics from './Routes/Analytics';
import Orders from './Routes/Orders';
import Linkinbio from './Routes/Linkinbio';
import Affiliate from './Routes/Affiliate';
import Verification from './Components/Auth/Verification';
import Storefront from './Routes/Storefront';
import Story from './Templates/Storefront';
import { getRequestHost } from './Utils/getRequestHost';
import Preview from './Templates/Storefront/Preview';
import Template from './Templates/Store1';
import Free from './Routes/Free';
import Elite from './Routes/Elite';
import Blue from './Routes/Blue';
export const AppRouter = () => {

  const { type } = getRequestHost();



  return (
    <Routes>

      <Route path='/setup' element={<Authenticated><Setup /></Authenticated>} />
      <Route path='/setup2' element={<Authenticated><Setup2 /></Authenticated>} />
      <Route path='/setup3' element={<Authenticated><Setup3 /></Authenticated>} />

      <Route path='/dashboard' element={<Authenticated ><HomeDash /></Authenticated>}
      />

      <Route path='/subscribe/toPlus' element={<Authenticated ><Free /></Authenticated>}
      />
      <Route path='/subscribe/toElite' element={<Authenticated ><Elite /></Authenticated>}
      />
        <Route path='/subscribe/toBlue' element={<Authenticated ><Blue /></Authenticated>}
      />
      <Route path='/products' element={
        <Authenticated>
          <Productpage />
        </Authenticated>} />
      <Route path='/analytics' element={
        <Authenticated>
          <Analytics />
        </Authenticated>} />
      <Route path='/preview' element={

        <Preview />
      } />
      <Route path='/builder/template' element={
        <Authenticated>
          <Template />
        </Authenticated>} />
      <Route path='/orders' element={
        <Authenticated>
          <Orders />
        </Authenticated>} />
      <Route path="/store" element={<Story />} />

      <Route path="/" element={<Landing />} />

      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Signup />} />
      <Route path='/affiliate' element={<Affiliate />} />
      <Route path='/verification' element={<Authenticated><Verification /></Authenticated>} />

      <>
        <Route path='/builder/editTemplate' element={<Authenticated><Edit /></Authenticated>} />

        <Route path='/linkinbio' element={
          <Authenticated>
            <Linkinbio />
          </Authenticated>} />
        <Route path='/template' element={
          <Authenticated>
            <Basic />
          </Authenticated>
        } />

        <Route path='/storefront' element={
          <Authenticated>
            <Storefront />
          </Authenticated>
        } />






        <Route path='/:name' element={

          <Template />

        } />
        <Route path='/about' element={

          <Blog />

        } />
        <Route path='/faq' element={

          <Faq />

        } />
        <Route path='/pricing' element={

          <Onlinemarketplace />

        } />
        <Route path='/help' element={
          <Pro />
        } />



      </>








    </Routes>
  )
}
