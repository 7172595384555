// EditableText.js
import React, { useState } from 'react';

function EditableText({ initialText, onTextChange, color, font, fontSize, styles }) {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(initialText);

    const handleTextClick = () => {
        setIsEditing(true);
    };



    const handleTextChange = (event) => {
        setText(event.target.value);
    };
    const handleBlur = () => {
        setIsEditing(false);
        if (onTextChange) {
            onTextChange(text); // Pass the edited text to the parent component
        }
    };

    return (
        <div>
            {isEditing ? (
                <div style={{ overflow: 'hidden' }} class={`flex text-center border p-3 rounded-md w-full flex-col ${fontSize} ${styles}`}>
                    <input
                        style={{ color: color, fontFamily: font }}
                        type="text"
                        class='outline-0 bg-transparent'
                        value={text}
                        onChange={handleTextChange}
                        onBlur={handleBlur}
                        autoFocus
                    />

                </div>
            ) : (
                <span class={`${fontSize} text-center ${styles}`} style={{ color: color, fontFamily: font }} onClick={handleTextClick}>
                    {text}
                </span>
            )}


        </div>
    );
}

export default EditableText;
