import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "./Loading";
import UserProvider from "../Components/Auth/UserProvider";

export const IS_LOGGED_IN = gql`
  {
    me {
      id
      avatar
      name
    }
  }
`;

function Authenticated({ children }) {
  const { loading, error, data } = useQuery(IS_LOGGED_IN);

  if (loading) return <Loading />;

  if (error) {
    // Custom error handling, redirect to an error page, etc.
    return <p>Error: {error.message}</p>;
  }

  if (!data.me) {
    return <Navigate to="/landing" replace />;
  }

  return (
    <UserProvider userData={data.me}>
    {children}
  </UserProvider>
  );
}

export default Authenticated;
