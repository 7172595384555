import React from 'react'
import { UserCircleIcon } from '@heroicons/react/20/solid'
import { gql, useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import { ME_QUERY } from '../../Data/Me'
import { useNavigate } from 'react-router-dom'
import { Feedback } from '../../Routes/Landing'
const Profile = gql`
 mutation updateProfile($bio: String, $avatar: String, $secondary: String){
    updateProfile(bio: $bio, avatar: $avatar, secondary: $secondary){
        id
    }
 }`
const Setup2 = () => {
    const [profile] = useMutation(Profile, {
        refetchQueries: [{ query: ME_QUERY }],
    })
    const navigate = useNavigate()
    const avatarFile = React.useRef(null)


    const [avatar, setAvatar] = React.useState()
    const [avatarLoading, setAvatarLoading] = React.useState(false)


    const [bio, setBio] = React.useState("");

    const handleBio = (event) => {
        setBio(event.target.value);
    };
    const [warning, setWarning] = React.useState(null)

    const handleSubmit = async () => {
        // Check if both secondary and avatar are null
        if (!avatar) {
            setWarning("Must select an avatar to continue.")
            return;
        }

        // Mutation logic here
        try {
            await profile({
                variables: {
                    bio: bio,
                    avatar: avatar,
                },
            })

            // Navigate or perform other actions upon successful submission
            navigate('/verification') // Update with the desired navigation
        } catch (error) {
            console.error("Error submitting profile:", error)
            // Handle error as needed
        }
    }

    const uploadAvatar = async (e) => {
        const files = e.target.files
        const data = new FormData()
        data.append("file", files[0])
        data.append("upload_preset", "kyroapp")
        setAvatarLoading(true)
        const res = await fetch("https://api.cloudinary.com/v1_1/dapcizjsz/image/upload", {
            method: "POST",
            body: data,
        })
        const file = await res.json()

        setAvatar(file.secure_url)
        setAvatarLoading(false)
    }

    return (

        <div class='w-full flex items-center bg-royalblue h-screen'>
            <div class='fixed top-0 w-full'>
                <a href='/' class='self-stretch flex items-center justify-between 2xl:px-10 xs:m-5 m-5 2xl:m-0 2xl:py-5'>
                    <img src='/head.svg' class='h-9' />
                </a>
            </div>
            <Feedback blue={false} />


            <div class='sm:max-w-md w-full m-5 p-7 xs:mx-5 sm:mx-auto bg-[#fff] shadow-lg border-[1px]  flex flex-col  border-gainsboro rounded-xl border-solid'>


                <div class='flex items-center justify-center flex-col'>
                    <img src='/union5.svg' className='xl:h-14 lg:h-12 md:h-10 h-9' />
                    <div style={{ fontFamily: 'Semi' }} class='text-2xl mt-5 text-center'>Setup</div>

                    <div style={{ fontFamily: 'Bold' }} class='text-4xl mt-2 text-center'>Profile</div>
                </div>


                <label htmlFor="photo" style={{ fontFamily: 'Semi' }} className="block text-sm leading-6 mb-3 text-black">
                    Photo
                </label>
                <div className="flex flex-col border-[1px] border-solid border-gainsboro p-5 rounded-3xl items-center gap-y-2">
                    <UserCircleIcon className={`${avatar ? "hidden" : "h-12 w-12 text-gray-300"}`} aria-hidden="true" />
                    {avatarLoading ? (
                        <h3>Loading...</h3>
                    ) : (
                        <>
                            {avatar ? (
                                <span onClick={() => avatarFile.current.click()}>
                                    <img
                                        src={avatar}
                                        class='h-12 w-12 rounded-full '
                                        alt="avatar"
                                        onClick={() => avatarFile.current.click()}

                                    />

                                </span>
                            ) : (
                                <span onClick={() => avatarFile.current.click()}>
                                    <i
                                        className="fa fa-user fa-5x"
                                        aria-hidden="true"
                                        onClick={() => avatarFile.current.click()}
                                    ></i>
                                </span>
                            )}
                        </>
                    )}

                    <label
                        style={{ fontFamily: 'Semi' }}
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-full px-8 text-white text-sm bg-royalblue py-3 text-black focus-within:outline-none"
                    >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file"
                            placeholder="Upload an image"
                            onChange={uploadAvatar}
                            ref={avatarFile}
                            style={{ display: "none" }} className="sr-only" />

                    </label>
                </div>

                <Formik
                    initialValues={{ bio: "" }}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="w-full flex flex-col mt-4">
                            <label htmlFor="description" style={{ fontFamily: 'Semi' }} className="block text-sm leading-6 mb-3 text-black">
                                Description
                            </label>
                                <input
                                    id="bio"

                                    name="bio"
                                    value={bio}
                                    onChange={handleBio}
                                    placeholder="Tell us about yourself..."
                                    className="block self-stretch px-5 rounded-2xl border-[1px] border-solid border-gainsboro font-['Face']  py-3 text-gray-900  placeholder:text-gray-400 "

                                />
                       
                        </div>
                        {warning && (
                            <div style={{ fontFamily: 'Face' }} className="bg-red-500 text-white p-3 mt-5 rounded-xl">
                                {warning}
                            </div>
                        )}
                        <button
                            style={{ fontFamily: 'Bold' }}
                            type="submit"

                            className="gap-4 mt-5 w-full border-0 justify-center rounded-full px-8 py-5 leading-6 bg-royalblue text-[#fff]"
                        >
                            Next
                            <span aria-hidden="true">&rarr;</span>
                        </button>
                    </Form>
                </Formik>



            </div>
        </div>










    )
}

export default Setup2